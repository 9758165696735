import { useRepository } from './auth'
import { UserContext } from './contexts'
import { observer } from 'mobx-react-lite'
import { FullPageSpinner } from 'components/Spinner'
import type { BreakoutUser } from '@breakoutlearning/firebase-repository/models/BreakoutUser'
import { useUserEffects } from './useUserEffects'

// passedProfile can be used for testing purposes
export const UserContextProvider = observer(function UserContextProvider({
  children,
  passedUser,
}: {
  children: React.ReactNode
  passedUser?: BreakoutUser
}) {
  const repo = useRepository()
  const { breakoutUser } = repo

  const user = passedUser || breakoutUser
  const loadedUser = user && user.isLoaded && user.hasData ? user : null

  useUserEffects({ user: loadedUser })

  if (!loadedUser) return <FullPageSpinner className="!border-t-surface-dim" />

  return (
    <UserContext.Provider value={{ user: loadedUser }}>
      {children}
    </UserContext.Provider>
  )
})
