import type { CalculateControllerFunction } from 'pages/layout/TwoColumn'
import { TwoColumnAnimatedPane } from 'pages/layout/TwoColumn'
import { StudentAssignmentPage } from '../assignment/StudentAssignmentPage'
import { StudentLibraryPage } from './StudentLibraryPage'

const calculateAnimationController: CalculateControllerFunction = (
  params,
  route
) => {
  const routeTitle = route?.title
  let key: string | null = null
  let component: JSX.Element | null = null
  let mode = 'leave'

  const roomStateId =
    params?.roomStateId && typeof params.roomStateId === 'string'
      ? params.roomStateId
      : undefined
  const assignmentId =
    params?.assignmentId && typeof params.assignmentId === 'string'
      ? params.assignmentId
      : null
  const sectionId =
    params?.sectionId && typeof params.sectionId === 'string'
      ? params.sectionId
      : null

  if (
    (routeTitle === 'assignment' || routeTitle === 'assignmentWithRoom') &&
    assignmentId &&
    sectionId
  ) {
    // It is now possible to go from one assignment to another
    // So we need the component to re-mount when the assignment changes
    key = `assignment-${assignmentId}-${sectionId}-${roomStateId}`
    component = (
      <StudentAssignmentPage
        assignmentId={assignmentId}
        sectionId={sectionId}
        roomStateId={roomStateId}
      />
    )
    mode = 'slideIn'
  } else {
    key = 'home'
    component = <StudentLibraryPage />
    mode = 'slideOut'
  }

  return {
    key,
    component,
    mode,
  }
}

export const StudentLibrary = () => {
  return (
    <TwoColumnAnimatedPane calculateController={calculateAnimationController} />
  )
}
