import classNames from 'classnames'
import type { HTMLAttributes } from 'react'

export function AdminOnlyRing({
  children,
  onlyShowIf,
  label,
  ringClassName,
  shiftUpwards,
  ...divProps
}: {
  children: React.ReactNode
  onlyShowIf: boolean | undefined
  label: string
  /** Applies mt-[-11px]. This brings the child element in line with the container. */
  shiftUpwards?: boolean
  ringClassName?: string
} & Omit<
  HTMLAttributes<HTMLDivElement>,
  'className' /** We exclude 'className' to be explicit about exactly what any classNames apply to. */
>) {
  // return { children } // Uncomment this line to view without the ring.

  if (!onlyShowIf) return null

  return (
    <div
      {...divProps}
      className={classNames(
        'relative rounded-md border border-fuchsia-500 p-[10px]',
        shiftUpwards && 'mt-[-11px]',
        ringClassName
      )}
    >
      <div className="absolute -top-2.5 left-1.5 shrink-0 whitespace-nowrap bg-white px-0.5 text-label-medium">
        {label}
      </div>
      {children}
    </div>
  )
}
