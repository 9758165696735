import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { DateTime } from 'luxon'
import { AssignmentType } from '@breakoutlearning/firebase-repository/types'
import type { useTranslationTyped } from 'i18n/i18n'
import { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { z } from 'zod'
import { dateSchema } from 'util/schema-date'

export const getAssignmentSchema = ({
  tt,
  minDateOverride,
}: {
  tt: ReturnType<typeof useTranslationTyped>['tt']
  minDateOverride?: DateTime
}) => {
  const minDate = (minDateOverride ? minDateOverride : DateTime.now()).minus({
    minutes: 1,
  })

  return z
    .object({
      assignmentType: z.preprocess((val) => {
        return Number(val)
      }, z.nativeEnum(AssignmentType)),
      sectionId: z.string({ message: tt.instructor_library.section_missing() }),
      catalogId: z.string().optional(),
      assignedAt: dateSchema({
        required: tt.instructor_assignment.start_date_required(),
      }),
      expiresAt: dateSchema({
        required: tt.instructor_library.deadline_required(),
        min: {
          date: minDate,
          message: minDateOverride
            ? tt.instructor_assignment.can_only_be_moved_forward()
            : tt.instructor_library.deadline_must_be_future(),
        },
      }),
      groupingType: z.preprocess((val) => {
        return Number(val)
      }, z.nativeEnum(AssignmentGroupingType)),
      groupingSize: z.coerce
        .number()
        .min(2, { message: tt.instructor_library.grouping_size_invalid() })
        .max(RoomState.maxAllowedUsers, {
          message: tt.instructor_library.grouping_size_invalid(),
        })
        .optional(),
      groupingSizeMaximum: z.coerce
        .number()
        .min(2, { message: tt.instructor_library.grouping_size_invalid() })
        .max(RoomState.maxAllowedUsers, {
          message: tt.instructor_library.grouping_size_invalid(),
        })
        .optional(),
      configureGrading: z.boolean(),
    })
    .refine(
      (data) =>
        !data.assignedAt || !data.expiresAt || data.expiresAt > data.assignedAt,
      {
        message: tt.instructor_assignment.due_date_must_be_later(),
        path: ['expiresAt'],
      }
    )
}

export const getDraftAssignmentSchema = ({
  tt,
  minDateOverride,
}: {
  tt: ReturnType<typeof useTranslationTyped>['tt']
  minDateOverride?: DateTime
}) => {
  const minDate = (minDateOverride ? minDateOverride : DateTime.now()).minus({
    minutes: 1,
  })

  return z
    .object({
      assignmentType: z.preprocess((val) => {
        return Number(val)
      }, z.nativeEnum(AssignmentType)),
      sectionId: z.string({ message: tt.instructor_library.section_missing() }),
      catalogId: z.string().optional(),
      assignedAt: dateSchema({
        required: tt.instructor_assignment.start_date_required(),
      }).optional(),
      expiresAt: dateSchema({
        required: tt.instructor_library.deadline_required(),
        min: {
          date: minDate,
          message: minDateOverride
            ? tt.instructor_assignment.can_only_be_moved_forward()
            : tt.instructor_library.deadline_must_be_future(),
        },
      }).optional(),
      groupingType: z.preprocess((val) => {
        return Number(val)
      }, z.nativeEnum(AssignmentGroupingType)),
      groupingSize: z.coerce
        .number()
        .min(2, { message: tt.instructor_library.grouping_size_invalid() })
        .max(RoomState.maxAllowedUsers, {
          message: tt.instructor_library.grouping_size_invalid(),
        })
        .optional(),
      groupingSizeMaximum: z.coerce
        .number()
        .min(2, { message: tt.instructor_library.grouping_size_invalid() })
        .max(RoomState.maxAllowedUsers, {
          message: tt.instructor_library.grouping_size_invalid(),
        })
        .optional(),
      configureGrading: z.boolean(),
    })
    .refine(
      (data) =>
        !(
          data.assignedAt &&
          data.expiresAt &&
          data.expiresAt < data.assignedAt
        ),
      {
        message: tt.instructor_assignment.due_date_must_be_later(),
        path: ['expiresAt'],
      }
    )
}

export const getLtiAssignmentSchema = ({
  tt,
}: {
  tt: ReturnType<typeof useTranslationTyped>['tt']
}) => {
  return z.object({
    catalogId: z.string().optional(),
    groupingType: z.preprocess((val) => {
      return Number(val)
    }, z.nativeEnum(AssignmentGroupingType)),
    groupingSize: z.coerce
      .number()
      .min(2, { message: tt.instructor_library.grouping_size_invalid() })
      .max(RoomState.maxAllowedUsers, {
        message: tt.instructor_library.grouping_size_invalid(),
      })
      .optional(),
    groupingSizeMaximum: z.coerce
      .number()
      .min(2, { message: tt.instructor_library.grouping_size_invalid() })
      .max(RoomState.maxAllowedUsers, {
        message: tt.instructor_library.grouping_size_invalid(),
      })
      .optional(),
  })
}

export type AssignmentFormValues = z.infer<
  ReturnType<typeof getAssignmentSchema>
>
export type DraftAssignmentFormValues = z.infer<
  ReturnType<typeof getDraftAssignmentSchema>
>
export type LtiAssignmentFormValues = z.infer<
  ReturnType<typeof getLtiAssignmentSchema>
>
