import { useContext } from 'react'
import { UserContext } from './contexts'

function useUserContext() {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within an UserContextProvider')
  }
  return context
}

export function useBreakoutUser() {
  const context = useUserContext()

  return context.user
}

declare global {
  interface Window {
    _breakoutUserId: string
    _breakoutDebug: Record<string, string>
  }
}
