import { BreakoutExperienceWidget } from 'components/BreakoutExperience'
import { Dialog } from 'components/dialogs/Dialog'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { CircleAlert } from 'components/icons/CircleAlert'
import { LightBulbIcon } from 'components/icons/LightBulb'
import classNames from 'classnames'

export function WalkthroughDialog({
  joinSession,
}: {
  joinSession: VoidFunction
}) {
  return (
    <Dialog
      testId="walkthrough-dialog"
      size="custom"
      ignoreBackdropClick={true}
      className="h-[80%] max-h-[80%] min-w-[80%] max-w-[80rem] flex-col overflow-hidden rounded-3xl bg-core-tertiary xl:min-w-[80rem]"
    >
      <BreakoutExperienceWidget
        components={[
          Page1,
          Page2Videos,
          Page3Polls,
          Page4Exhibits,
          Page5Discussions,
        ]}
        onDone={joinSession}
        onSkip={joinSession}
        noBottomPadding
      />
    </Dialog>
  )
}

const FlexPageWithImage = ({
  children,
  imgClassName,
  imgSrc,
  imgAlt,
}: {
  children: React.ReactNode
  imgClassName?: string
  imgSrc: string
  imgAlt?: string
}) => {
  return (
    <div className="flex h-full max-h-full w-full max-w-full flex-shrink flex-col gap-3 overflow-auto md:flex-row">
      {/* left text */}
      <div
        className="mb-16 flex flex-none flex-col justify-center overflow-auto p-5 pl-0 md:flex-1"
        tabIndex={0}
      >
        {children}
      </div>
      {/* right image - shadow and contain in div */}
      <div className="mb-16 flex flex-none items-center p-16 md:flex-1">
        <img
          className={classNames(
            'mx-auto max-h-full max-w-full justify-center rounded-3xl shadow-3xl',
            imgClassName
          )}
          alt={imgAlt}
          src={imgSrc}
        />
      </div>
    </div>
  )
}

const Page1 = observer(function Page1() {
  const { t } = useTranslation()
  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center text-center"
      tabIndex={0}
    >
      <h1 className="mb-3 text-body-large text-grey-text">
        {t('walkthrough.title').toLocaleUpperCase()}
      </h1>
      <h2 className="max-w-[47rem] text-headline-large">
        {t('walkthrough.description')}
      </h2>
    </div>
  )
})

const Page2Videos = observer(function Page2Videos() {
  const { t } = useTranslation()
  const imgSrc = '/assets/images/walkthrough/video.png'
  return (
    <FlexPageWithImage imgSrc={imgSrc} imgAlt={t('walkthrough.video_title')}>
      <h2 className="mb-3 text-body-large">{t('walkthrough.video_title')}</h2>
      <h3 className="text-headline-large">
        {t('walkthrough.video_description')}
      </h3>
    </FlexPageWithImage>
  )
})

const Page3Polls = observer(function Page3Polls() {
  const { t } = useTranslation()
  const imgSrc = '/assets/images/walkthrough/poll.png'
  return (
    <FlexPageWithImage imgSrc={imgSrc} imgAlt={t('walkthrough.poll_title')}>
      <h2 className="mb-3 text-body-large">{t('walkthrough.poll_title')}</h2>
      <h3 className="mb-3 text-headline-large">
        {t('walkthrough.poll_description')}
      </h3>
      <div className="flex flex-row rounded-3xl bg-light-grey p-5">
        <CircleAlert size={20} color="#fbbc05" className="mr-2" />
        <p className="text-label-large">{t('walkthrough.poll_warning')}</p>
      </div>
    </FlexPageWithImage>
  )
})

const Page4Exhibits = observer(function Page5Exhibits() {
  const { t } = useTranslation()
  const imgSrc = '/assets/images/walkthrough/exhibits.png'
  return (
    <FlexPageWithImage
      imgSrc={imgSrc}
      imgClassName="-mt-10"
      imgAlt={t('walkthrough.exhibits')}
    >
      <h2 className="mb-3 text-body-large">{t('walkthrough.exhibits')}</h2>
      <h3 className="mb-3 text-headline-large">
        {t('walkthrough.exhibits_description')}
      </h3>
      <div className="flex flex-row rounded-3xl bg-light-grey p-5">
        <LightBulbIcon size={20} color="#fbbc05" className="mr-2" />
        <p className="text-label-large">{t('walkthrough.exhibits_warning')}</p>
      </div>
    </FlexPageWithImage>
  )
})

const Page5Discussions = observer(function Page4Discussions() {
  const { t } = useTranslation()
  const imgSrc = '/assets/images/walkthrough/discussion.png'
  return (
    <FlexPageWithImage
      imgSrc={imgSrc}
      imgAlt={t('walkthrough.discussion_title')}
    >
      <h2 className="mb-3 text-body-large">
        {t('walkthrough.discussion_title')}
      </h2>
      <h3 className="mb-3 text-headline-large">
        {t('walkthrough.discussion_description')}
      </h3>
      <div className="flex flex-row rounded-3xl bg-light-grey p-5">
        <LightBulbIcon size={20} color="#fbbc05" className="mr-2" />
        <p className="text-label-large">
          {t('walkthrough.discussion_warning')}
        </p>
      </div>
    </FlexPageWithImage>
  )
})
