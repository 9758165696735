import { observer } from 'mobx-react-lite'
import { useRepository } from 'hooks/auth'
import { InfoIcon } from 'components/icons/Info'
import { useTranslationTyped } from 'i18n/i18n'

export const ProfileViewFeatureFlags = observer(
  function ProfileViewFeatureFlags() {
    const { tt } = useTranslationTyped()
    const repository = useRepository()
    const featureFlags = repository.featureFlags.flags

    return (
      <div className="flex h-full flex-col">
        <h1 className="text-headline-large">
          {tt.profile.view_feature_flags()}
        </h1>
        <div className="mb-3 text-body-large text-core-on-tertiary">
          {tt.profile.view_feature_flags_description()}
        </div>
        <div className="mt-6 flex-1 rounded-3xl bg-surface p-6">
          {!repository.featureFlags.isLoaded && (
            <div className="flex h-full flex-col items-center justify-center">
              <InfoIcon className="fill-fixed-accent-color" size={32} />
              <div className="mt-1 text-headline-small text-core-on-tertiary">
                {tt.profile.loading_feature_flags()}
              </div>
            </div>
          )}

          {repository.featureFlags.isLoaded && (
            <div className="space-y-4">
              {Object.entries(featureFlags).map(([key, value]) => (
                <div
                  key={key}
                  className="flex items-center justify-between rounded-2xl bg-core-tertiary p-4"
                >
                  <div className="text-body-medium">{key}</div>
                  <div className="text-body-medium text-on-surface-var">
                    {typeof value === 'boolean'
                      ? value
                        ? 'true'
                        : 'false'
                      : typeof value === 'number'
                        ? value.toString()
                        : value}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
)
