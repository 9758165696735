import { observer } from 'mobx-react-lite'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { useDialogs } from 'hooks/dialogs'
import { RoomsDialog } from './dialogs/RoomsDialog'
import { useTranslation } from 'react-i18next'
import { CircleAlert } from 'components/icons/CircleAlert'
import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import { DateTime } from 'luxon'
import { RoomStateForm } from './dialogs/RoomStateFormDialog'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import {
  BreakoutUserAvatar,
  BreakoutUserAvatarStack,
} from 'components/breakout/BreakoutUserAvatar'
import React, { useCallback } from 'react'
import { useBreakoutUser } from 'hooks/profile'
import { useRepository } from 'hooks/auth'
import { ScheduleRoomStateButton } from './dialogs/ScheduleRoomStateButton'
import { TriangleAlertIcon } from 'components/icons/TriangleAlert'
import { ChatBubbleIcon } from 'components/icons/ChatBubble'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { Shield } from 'components/icons/Shield'
import classNames from 'classnames'
import { RoomStateFormDialogV2 } from './dialogs/RoomStateFormDialogV2'
import { LeaveRoomDialog } from './dialogs/LeaveRoomDialog'
import { StudentAssignmentChatDialog } from './dialogs/StudentAssignmentChatDialog'
import {
  ScheduledDayOfMonthOnYear,
  ScheduledDayOfWeekAtTime,
} from './ScheduledTimeParts'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { useTranslationTyped } from 'i18n/i18n'

const ExistingRoom = observer(function StudentAssignmentStep1() {
  const cubit = useStudentAssignmentCubit()
  const { showDialog, popDialog } = useDialogs()
  const { t, tt } = useTranslationTyped()
  const currentUser = useBreakoutUser()

  // Admins can always see the room dialog
  const canChangeRoom =
    currentUser.isCorre ||
    cubit.currentUserIsInSection ||
    cubit.currentUserIsInstructor ||
    currentUser.isTaToInstructor(cubit.section.data.instructorUserId)

  const openRoomDialog = useCallback(() => {
    // never show the dialog if the user is not in the room
    if (!canChangeRoom) return
    showDialog(() => <RoomsDialog cubit={cubit} />)
  }, [cubit, showDialog, canChangeRoom])

  return (
    <div className="mb-2 w-full py-5 text-center">
      <AutomaticRandomGroupingHeader />
      <div className="flex w-full flex-grow-0 flex-row justify-center justify-items-center">
        <div
          data-testid="student-assignment-schedule-area"
          className="flex w-1/2 flex-grow cursor-pointer justify-items-end"
        >
          <div className="flex-grow"></div>
          <div
            className="flex-grow-0 cursor-pointer border-r border-border-grey px-2 py-5 text-center sm:px-9"
            onClick={openRoomDialog}
          >
            <h3 className="mb-3 flex-grow text-body-small text-grey-text">
              {t('student_assignment.group_details')}
            </h3>
            <p className="mb-2 text-title-large">
              {cubit.roomState.roomStateName}
            </p>

            <div className="flex flex-row justify-center -space-x-3 text-xs">
              <BreakoutUserAvatarStack
                radius={14}
                spacing={-3}
                users={cubit.roomState.users}
              />
            </div>
          </div>
        </div>
        <div className="flex w-1/2 flex-grow justify-items-start">
          <div className="flex-grow-0  px-2 py-5 text-center sm:px-9">
            <h3 className="mb-3 text-body-small text-grey-text">
              {tt.student_assignment.meeting_time()}
            </h3>
            {cubit.roomState.scheduledAtDate && (
              <div
                className="cursor-pointer"
                tabIndex={0}
                role="button"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    openRoomDialog()
                  }
                }}
                onClick={openRoomDialog}
              >
                <p
                  className="mb-2 text-title-large"
                  data-testid="student-assignment-scheduled"
                >
                  {cubit.roomState.scheduledAtDate.toFormat('LLL d, yyyy')}
                </p>
                <p className="text-xs">
                  {cubit.roomState.scheduledAtDate.toLocaleString(
                    DateTime.TIME_SIMPLE
                  )}
                </p>
              </div>
            )}
            {!cubit.roomState.scheduledAtDate && canChangeRoom && (
              <BreakoutButton
                data-testid="schedule-now-button"
                size="medium"
                kind="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  showDialog(() => (
                    <RoomStateForm
                      onSave={() => {
                        popDialog()
                      }}
                      cubit={cubit}
                    />
                  ))
                }}
              >
                {t('student_assignment.schedule_now')}
              </BreakoutButton>
            )}
          </div>
          <div className="flex-grow"></div>
        </div>
      </div>
    </div>
  )
})

const ExistingRoomV2 = observer(function StudentAssignmentStep1() {
  const cubit = useStudentAssignmentCubit()
  const { showDialog, popDialog } = useDialogs()
  const { t, tt } = useTranslationTyped()
  const currentUser = useBreakoutUser()

  const isStudentInRoom = cubit.roomState.users.some(
    (u) => u.id === currentUser.uid
  )

  return (
    <div className="mb-2 w-full py-10 text-center">
      <AutomaticRandomGroupingHeader />
      <div className="flex w-full flex-grow-0 flex-col items-center justify-center gap-3">
        <div className="flex flex-row gap-5">
          <div
            data-testid="student-assignment-schedule-area"
            className="flex justify-items-end"
          >
            <div className="text-center">
              <p className="mb-1 text-title-large">
                {cubit.roomState.roomStateName}
              </p>

              <div className="flex flex-row justify-center">
                <BreakoutUserAvatarStack
                  radius={12}
                  spacing={-2}
                  users={cubit.roomState.users}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col text-center">
            {cubit.roomState.scheduledAtDate && (
              <>
                <p
                  className="text-title-large"
                  data-testid="student-assignment-scheduled"
                >
                  <ScheduledDayOfWeekAtTime
                    scheduledAt={cubit.roomState.data.scheduledAt}
                    t={t}
                  />
                </p>
                <div className="flex flex-grow items-center justify-center text-center">
                  <p className="text-body-medium text-on-surface-var">
                    <ScheduledDayOfMonthOnYear
                      scheduledAt={cubit.roomState.data.scheduledAt}
                    />
                  </p>
                </div>
              </>
            )}
            {/* what if room is not scheduled but there is a group leader? */}
            {!cubit.roomState.scheduledAtDate &&
              cubit.currentUserCanScheduleRoom && (
                <ScheduleRoomStateButton
                  useInlineDialog={false}
                  kind="accent"
                  size="small"
                  cubit={cubit}
                  className="my-auto"
                  loadingText={t('student_assignment.set_meeting_time')}
                  icon={<TriangleAlertIcon size={15} />}
                >
                  {tt.student_assignment.set_meeting_time()}
                </ScheduleRoomStateButton>
              )}
            {!cubit.roomState.scheduledAtDate &&
              !cubit.currentUserCanScheduleRoom && (
                <div>
                  <h2 className="mb-auto mt-auto text-title-large">
                    {tt.student_assignment.join_dialog_v2.not_scheduled()}
                  </h2>
                </div>
              )}
          </div>
        </div>
        <div>
          <ChatButton cubit={cubit} />
          <div className="mt-1 flex flex-row gap-1 text-body-small text-on-surface-var">
            <BreakoutTooltip
              enabled={!cubit.currentUserCanScheduleRoom}
              content={
                <div className="flex flex-col items-center justify-center gap-1.5 rounded-lg bg-on-surface-var px-4 py-2 text-body-medium text-core-on-primary">
                  <span>{tt.student_assignment.group_owner_schedule()}</span>
                  {cubit.roomState.groupLeaderUserIds.length > 0 &&
                    cubit.roomState.groupLeader.isNotEmpty && (
                      <div className="flex flex-row items-center justify-center gap-1">
                        <BreakoutUserAvatar
                          radius={10}
                          user={cubit.roomState.groupLeader}
                        />
                        <span>{cubit.roomState.groupLeader.fullName}</span>
                        <Shield
                          size={15}
                          className="stroke-fixed-accent-color"
                        />
                      </div>
                    )}
                </div>
              }
            >
              <button
                className={classNames('underline', {
                  'text-on-surface-disabled': !cubit.currentUserCanScheduleRoom,
                })}
                disabled={!cubit.currentUserCanScheduleRoom}
                onClick={() => {
                  showDialog(() => (
                    <RoomStateFormDialogV2
                      cubit={cubit}
                      onSave={() => popDialog()}
                    />
                  ))
                }}
              >
                {tt.student_assignment.join_dialog_v2.edit_group()}
              </button>
            </BreakoutTooltip>
            <strong className="font-semibold">&#8226;</strong>
            <button
              disabled={!isStudentInRoom}
              className="underline"
              onClick={() => {
                showDialog(() => (
                  <LeaveRoomDialog
                    leaveRoom={async () => {
                      currentUser.notifications.markAssignmentAsRead(
                        cubit.sectionId,
                        cubit.assignmentId
                      )

                      await cubit.leaveRoom()
                    }}
                  />
                ))
              }}
            >
              {tt.student_assignment.leave_group()}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

const ChatButton = observer(function ChatButton({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const user = useBreakoutUser()
  const { showDialog } = useDialogs()
  const { t } = useTranslation()

  const notificationsCount =
    user.notifications.getChatNotificationsForAssignmentCount(
      cubit.sectionId,
      cubit.assignmentId
    )

  return (
    <BreakoutButton
      kind="secondary"
      size="small"
      badgeCount={notificationsCount || undefined}
      disabled={!(cubit.userIsInCurrentRoom || cubit.user.isCorre)}
      onClick={() => {
        showDialog(() => <StudentAssignmentChatDialog cubit={cubit} />)
      }}
      icon={<ChatBubbleIcon size={15} />}
    >
      {t('student_assignment.chat')}
    </BreakoutButton>
  )
})

const AutomaticRandomGroupingHeader = observer(
  function StudentAssignmentStep1() {
    const cubit = useStudentAssignmentCubit()
    const { t } = useTranslation()
    if (
      cubit.assignment.groupingType !== AssignmentGroupingType.automaticRandom
    ) {
      return null
    }
    return (
      <div className="mb-2 flex justify-center">
        <div className="flex items-center rounded-full bg-core-secondary px-4 py-2 text-body-medium text-core-on-secondary">
          <span className="mr-1">
            <CircleAlert size={15} />
          </span>
          {t('student_assignment.uses_automatic_randomized_grouping')}
        </div>
      </div>
    )
  }
)

const NeedsARoom = observer(function StudentAssignmentStep1() {
  const cubit = useStudentAssignmentCubit()
  const { showDialog } = useDialogs()
  const { tt } = useTranslationTyped()

  const currentUser = useBreakoutUser()

  // Admins can always see the room dialog
  const canChangeRoom =
    currentUser.isCorre ||
    cubit.currentUserIsInSection ||
    cubit.currentUserIsInstructor

  if (
    cubit.assignment.groupingType === AssignmentGroupingType.automaticRandom
  ) {
    return (
      <div className="mb-2 w-full py-5 text-center">
        <AutomaticRandomGroupingHeader />
        <div className="text-body-medium text-grey-text">
          {tt.student_assignment.step_1()}
        </div>
        <h3 className="mt-3 text-title-large">
          {tt.student_assignment.group_tbd()}
        </h3>

        <div className="min-h-[80px] overflow-hidden">
          <p className="mx-auto mt-1 max-w-[23rem] text-body-medium">
            {tt.student_assignment.group_tbd_description()}
          </p>
          <BreakoutButton
            data-testid="join-group-button"
            onClick={() => showDialog(() => <RoomsDialog cubit={cubit} />)}
            className="w-50 mt-4 inline-block rounded-2xl bg-core-secondary px-8 py-2 text-label-large text-core-on-secondary outline-none"
          >
            {tt.student_assignment.manually_join_group()}
          </BreakoutButton>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div className="mt-4 text-body-medium text-grey-text">
          {tt.student_assignment.step_1()}
        </div>
        <h3 className="mt-3 text-title-large">
          {tt.student_assignment.join_or_create_group()}
        </h3>

        <div className="min-h-[80px] overflow-hidden ">
          <p className="mx-auto mt-1 max-w-[23rem] text-body-medium">
            {tt.student_assignment.join_or_create_group_description()}
          </p>
          {canChangeRoom && (
            <BreakoutButton
              kind="primary"
              size="large"
              data-testid="join-group-button"
              onClick={() => showDialog(() => <RoomsDialog cubit={cubit} />)}
              className="mb-1 mt-4 inline-block w-40 rounded-2xl bg-core-primary px-8 py-2 text-label-large text-core-on-primary"
            >
              {tt.student_assignment.join_group()}
            </BreakoutButton>
          )}
        </div>
      </div>
    )
  }
})

export const StudentAssignmentStep1 = observer(function StudentAssignmentStep1(
  props: React.HTMLAttributes<HTMLDivElement>
) {
  const cubit = useStudentAssignmentCubit()
  const { showNewScheduling } = useRepository().featureFlags.data
  return (
    <div {...props}>
      {cubit.roomStateId &&
        (!showNewScheduling ? <ExistingRoom /> : <ExistingRoomV2 />)}
      {!cubit.roomStateId && <NeedsARoom />}
    </div>
  )
})
