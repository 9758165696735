import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import type { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { type RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { CircleAlert } from 'components/icons/CircleAlert'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useDialogs } from 'hooks/dialogs'
import { getRoomStateScheduledAtSchema } from '../roomStateScheduledAtSchema'
import { when } from 'mobx'
import { useTranslationTyped } from 'i18n/i18n'
import { roundMinutesUpTo15 } from 'util/roundMinutesUpTo15'

export const RoomStateFormV2DialogBody = observer(function RoomStateForm({
  room,
  cubit,
  onSave,
  virtualName,
  closeSelf,
}: {
  room: RoomState
  cubit: StudentAssignmentCubit
  onSave?: () => void
  virtualName?: string
  closeSelf: () => void
}) {
  const now = DateTime.now().startOf('minute') // that allows you to use "now" when starting a meeting
  const nowFormatted = now
  const maxFormatted = cubit.assignment.expiresAtDate

  const { t, tt } = useTranslationTyped()

  useEffect(() => {
    if (room.id) cubit.logEvent('show_create_group')
  }, [cubit, room.id])

  const getSchema = useCallback(
    (
      t: ReturnType<typeof useTranslation>['t'],
      room: RoomState,
      rooms: RoomState[],
      expiresAt: DateTime | undefined
    ) =>
      z.object({
        groupName: z
          .preprocess(
            (s) => {
              if (typeof s === 'string') {
                return s.trim()
              }
              return s
            },
            z.string().min(1, t('student_assignment.group_name_required'))
          )
          .refine((value) => {
            // Check if the group name is unique
            return !rooms.some(
              (r) => {
                if (r.id === room.id) return false
                if (r.data.roomStateName === value) return true
              },
              {
                message: t('student_assignment.group_name_exists'),
              }
            )
          }),
        scheduledAt: getRoomStateScheduledAtSchema({ t, expiresAt }),
      }),
    []
  )

  const schema = useMemo(
    () =>
      getSchema(
        t,
        room,
        cubit.roomStates.models,
        cubit.assignment.expiresAtDate
      ),
    [getSchema, t, room, cubit.roomStates, cubit.assignment.expiresAtDate]
  )
  type FormValues = z.infer<ReturnType<typeof getSchema>>

  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    trigger,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      groupName: room.data.roomStateName ?? virtualName ?? '',
      scheduledAt: room.scheduledAtDate || roundMinutesUpTo15(now),
    },
    reValidateMode: 'onChange',
  })
  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleSubmit(async (data) => {
        const { groupName, scheduledAt } = data

        // if we are in process of editing a room data but are not part of the room or on the room's page as instructor
        // then let's join the room as group leader (we can not edit unless we are group leader)
        if (!cubit.roomStateId && room.id) {
          await cubit.joinRoom(room.id, { isGroupLeader: true })
          await when(() => !!cubit.roomStateId) //wait for roomStateId update (this should always happen after join)
        }

        try {
          await cubit.updateRoom(groupName, scheduledAt)
          onSave?.()
        } catch (e) {
          console.error(e)
        }
      })(e)
    },
    [cubit, handleSubmit, onSave, room.id]
  )

  const isEditing = useMemo(() => {
    // trigger one validation on mount if we are editing
    if (room.id) trigger()
    return !!room.id
  }, [room.id, trigger])

  return (
    <div className="flex h-full w-full justify-center">
      <form
        className="flex h-full w-full max-w-[450px] flex-col justify-center gap-8"
        onSubmit={onSubmit}
      >
        <div className="flex flex-col gap-1 text-center">
          <h1 className="text-headline-large">
            {!isEditing
              ? t('student_assignment.join_dialog_v2.first_one_here')
              : t('student_assignment.join_dialog_v2.edit_group')}
          </h1>
          <h2 className="text-body-large">
            {t('student_assignment.join_dialog_v2.name_your_group')}
          </h2>
        </div>
        <div className="flex flex-col gap-2">
          <Controller
            control={control}
            name="groupName"
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                {...field}
                onChange={(v) => {
                  field.onChange(v)
                  trigger('groupName')
                }}
                autoFocus
                kind="secondary"
                disabled={isSubmitting}
                error={fieldState.error}
                testId="room-state-form-name"
                label={t('student_assignment.group_name')}
                name="groupName"
                placeholder={t('student_assignment.group_name_placeholder')}
              />
            )}
          />
          <Controller
            control={control}
            name="scheduledAt"
            render={({ field, fieldState }) => (
              <BreakoutDateTimeInput
                {...field}
                error={fieldState.error}
                value={field.value}
                kind="secondary"
                disabled={isSubmitting}
                label={tt.student_assignment.meeting_time()}
                min={nowFormatted}
                max={maxFormatted}
                id="meeting-time"
                name="scheduledAt"
                onChange={(v) => {
                  field.onChange(v)
                  trigger()
                }}
                resolution="15min"
              />
            )}
          />
          <div className="flex flex-row gap-1 pl-4">
            <CircleAlert size={10} className="stroke-on-surface-var" />
            <strong className="text-body-small text-on-surface-var">
              {t('student_assignment.join_dialog_v2.meeting_date_info')}
            </strong>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <BreakoutButton
            data-testid="room-state-form-confirm"
            size="large"
            kind="secondary"
            type="submit"
            loadingText="Confirm"
            onClick={closeSelf}
          >
            {t('student_assignment.cancel')}
          </BreakoutButton>
          <BreakoutButton
            data-testid="room-state-form-confirm"
            size="large"
            kind={!isValid ? 'secondary' : 'accent'}
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            loadingText="Confirm"
          >
            {'Confirm'}
          </BreakoutButton>
        </div>
      </form>
    </div>
  )
})

export const RoomStateFormDialogV2 = ({
  cubit,
  onSave,
}: {
  cubit: StudentAssignmentCubit
  onSave?: () => void
}) => {
  const { popDialog } = useDialogs()

  return (
    <Dialog
      testId="rooms-dialog"
      size="xl"
      className="h-[700px] w-[1200px]"
      innerClassName="flex h-full max-w-[1000px] mx-auto"
    >
      <DialogCloseButton />
      <RoomStateFormV2DialogBody
        closeSelf={() => popDialog()}
        room={cubit.roomState}
        onSave={onSave}
        cubit={cubit}
      />
    </Dialog>
  )
}
