import { StudentLibraryCubit } from '@breakoutlearning/firebase-repository/cubits/StudentLibraryCubit'
import { PageTitle } from 'components/PageTitle'
import { ShowCompletedButton } from 'components/ShowCompletedButton'
import { Spinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { useBreakoutUser } from 'hooks/profile'
import { useCanvasMode } from 'hooks/use-canvas-mode'
import { observer } from 'mobx-react-lite'
import {
  Actions,
  Contents,
  Header,
  Headline,
  MainPane,
} from 'pages/layout/TwoColumn'
import { Trans, useTranslation } from 'react-i18next'
import { AssignmentTable, PriorityActionHeader } from './components'
import { LibraryObjectList } from './LibraryObjectList'
import { useTranslationTyped } from 'i18n/i18n'
import { CircleAlert } from 'components/icons/CircleAlert'
import { createPortal } from 'react-dom'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useRootStore } from 'hooks/rootStore'

export const StudentLibraryPage = observer(function StudentLibraryPage() {
  const repository = useRepository()
  const flags = repository.featureFlags
  if (!flags.isLoaded) return null
  const useNewVersion = flags.data.studentLibraryUseNew

  if (useNewVersion) {
    return <StudentLibraryPageNew />
  } else {
    return <StudentLibraryPageOld />
  }
})

const StudentLibraryPageNew = observer(function StudentLibraryPage() {
  const { t } = useTranslation()
  const repository = useRepository()
  const user = useBreakoutUser()
  const { canvasOptions, canvasMode } = useCanvasMode()
  const cubit = useCubitBuilder(() => {
    const sectionId = canvasOptions.sectionId

    return new StudentLibraryCubit(repository, {
      sectionId: sectionId?.toString(),
    })
  }, [repository, canvasOptions])

  const isReady =
    cubit.isLoaded && user.purchasesLoaded && repository.featureFlags.isLoaded

  // if user is student and in no sections
  // show error overlay (needs invitation)
  const showNoSectionErrorOverlay =
    user.isStudent &&
    !canvasMode &&
    isReady &&
    repository.featureFlags.data.verifyInvitation &&
    user.profile.data.requiresInvitation

  if (showNoSectionErrorOverlay)
    return (
      <MainPane testId="student-library">
        <PageTitle title={t('library.page_title')} />
        <StudentWithNoSectionsErrorOverlay />
      </MainPane>
    )

  return (
    <MainPane testId="student-library">
      <PageTitle title={t('library.page_title')} />
      <Header>
        <Headline>{t('library.headline')}</Headline>
      </Header>
      <Contents className="flex h-full flex-col overflow-y-hidden">
        {isReady && cubit.libraryObjectForHeader && (
          <PriorityActionHeader
            libraryObject={cubit.libraryObjectForHeader.libraryObject}
            actionState={cubit.libraryObjectForHeader.actionState}
          />
        )}
        {isReady && <AssignmentTable cubit={cubit} />}
        {!isReady && (
          <div className="flex h-full flex-row items-center justify-center">
            <Spinner />
          </div>
        )}
      </Contents>
    </MainPane>
  )
})

const LibraryActions = observer(function LibraryActions({
  cubit,
}: {
  cubit: StudentLibraryCubit
}) {
  return (
    <Actions>
      <ShowCompletedButton
        showCompleted={cubit.showCompleted}
        onClick={() => cubit.toggleShowCompleted()}
      />
    </Actions>
  )
})

function StudentWithNoSectionsErrorOverlay() {
  const { tt } = useTranslationTyped()
  const store = useRootStore()

  return createPortal(
    <div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-surface-dim p-8">
      <div className="flex h-full w-full items-center justify-center rounded-3xl bg-core-on-primary">
        <div className="flex max-w-[650px] flex-col items-center justify-center gap-4">
          <CircleAlert className="stroke-core-error" size={36} />
          <h1 className="text-headline-large">
            {tt.student_library.verify_invitation.no_account_found()}
          </h1>
          <h2 className="text-body-large">
            {tt.student_library.verify_invitation.no_account_found_sub()}
          </h2>
          <div className="flex max-w-[530px] flex-row gap-5">
            <div className="flex flex-1 flex-col items-center justify-center gap-3 rounded-2xl border border-outline-variant px-5 py-12">
              <strong className="text-title-medium">
                {tt.student_library.verify_invitation.for_students()}
              </strong>
              <p className="flex-1 text-body-medium">
                {tt.student_library.verify_invitation.for_students_sub()}
              </p>
            </div>
            <div className="flex flex-1 flex-col items-center justify-center gap-3 rounded-2xl border border-outline-variant px-5 py-12">
              <strong className="text-title-medium">
                {tt.student_library.verify_invitation.for_instructors()}
              </strong>
              <p className="flex-1 text-body-medium">
                <Trans
                  i18nKey="student_library.verify_invitation.for_instructors_sub"
                  components={{
                    RequestButton: (
                      <a
                        rel="noreferrer"
                        className="font-bold underline"
                        href="https://support.breakoutlearning.com/help-center/requesting-instructor-access"
                        target="_blank"
                      />
                    ),
                    SupportButton: (
                      <a
                        rel="noreferrer"
                        className="font-bold underline"
                        href="mailto:support@breakoutlearning.com"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <BreakoutButton
            kind="accent"
            onClick={() => store.navigateTo('logout')}
          >
            {tt.student_library.verify_invitation.back_to_login()}
          </BreakoutButton>
        </div>
      </div>
    </div>,
    document.body
  )
}

function StudentLibraryPageOld() {
  const { t } = useTranslation()
  const repository = useRepository()
  const cubit = useCubitBuilder(() => {
    return new StudentLibraryCubit(repository)
  }, [repository])

  return (
    <MainPane testId="student-library">
      <PageTitle title={t('library.page_title')} />
      <Header>
        <Headline>{t('library.headline')}</Headline>
        <LibraryActions cubit={cubit} />
      </Header>
      <Contents className="h-full md:overflow-auto">
        <LibraryObjectList cubit={cubit} />
      </Contents>
    </MainPane>
  )
}
