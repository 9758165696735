import { noTry } from '@breakoutlearning/firebase-repository/util'
import { useCallback, useMemo, useState } from 'react'
import { z } from 'zod'

const baseKey = 'canvasMode'
const optionsKey = 'canvasModeOptions'

const canvasOptionsSchema = z.object({
  sectionId: z.string().optional(),
  organizationId: z.string().optional(),
})

type CanvasOptions = z.infer<typeof canvasOptionsSchema>

export const useCanvasMode = () => {
  const [canvasMode, _setCanvasMode] = useState(
    sessionStorage.getItem(baseKey) === 'true'
  )
  const extractedOptions: CanvasOptions = useMemo(() => {
    const rawOptions = sessionStorage.getItem(optionsKey)
    if (!rawOptions) return {}

    const [parsed] = noTry<CanvasOptions>(() => {
      const json = JSON.parse(rawOptions)

      return canvasOptionsSchema.parse(json)
    })

    if (!parsed) return {}

    return parsed
  }, [])

  const [canvasOptions, setCanvasOptions] =
    useState<Record<string, string>>(extractedOptions)

  const setCanvasMode = useCallback(
    (mode: boolean, options?: Record<string, string>) => {
      if (mode) {
        if (options && Object.keys(options).length > 0) {
          setCanvasOptions(options)
          sessionStorage.setItem(optionsKey, JSON.stringify(options))
        }

        sessionStorage.setItem(baseKey, 'true')
        _setCanvasMode(mode)
      }
    },
    []
  )

  return {
    canvasOptions,
    canvasMode,
    setCanvasMode,
  }
}
