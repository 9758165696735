import type { NotificationGroup } from '@breakoutlearning/firebase-repository/types'
import { UserProfileNotificationType } from '@breakoutlearning/firebase-repository/types'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import type { HTMLAttributes } from 'react'
import { GroupChatNotification } from './components/GroupChatNotification'
import classNames from 'classnames'
import { CheckBoxIcon } from 'components/icons/CheckBox'
import { useTranslationTyped } from 'i18n/i18n'
import { BellIcon } from 'components/icons/Bell'
import { NotificationsActionButton } from './NotificationsActionsButton'

export const NotificationsView = observer(function NotificationsView({
  passedRef,
  onDismiss,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  onDismiss: () => void
  passedRef: (el: HTMLDivElement) => void
}) {
  const user = useBreakoutUser()
  const notificationGroups = user.notifications.grouped
  const { tt } = useTranslationTyped()

  return (
    <>
      <div
        {...props}
        ref={passedRef}
        className={classNames(
          'h-[100vh] min-w-[400px] max-w-[400px] py-2',
          props.className
        )}
      >
        <div
          className="flex h-full flex-col overflow-hidden rounded-2xl border-1 border-outline-variant bg-core-tertiary shadow-2xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex-0 flex items-center justify-between border-b-2 border-b-outline-variant px-[28px] pb-4 pr-3 pt-5">
            <h2 className="text-title-large">Notifications</h2>
            <NotificationsActionButton>
              <div className="w-[180px] rounded-2xl bg-transparent shadow-xl">
                <BreakoutButton
                  icon={<CheckBoxIcon size={20} />}
                  className="!justify-start shadow-2xl"
                  fullWidth
                  size="medium"
                  kind="secondary"
                  onClick={() => user.notifications.markAllAsRead()}
                >
                  {tt.notifications.mark_all_as_read()}
                </BreakoutButton>
              </div>
            </NotificationsActionButton>
          </div>
          {notificationGroups.length === 0 && <EmptyState />}
          {notificationGroups.length > 0 && (
            <div className="flex h-full flex-col overflow-auto">
              {notificationGroups.map((notification, index) => (
                <SingleNotificationGroup
                  key={notification.groupingKey}
                  notificationGroup={notification}
                  onDismiss={onDismiss}
                  isLast={index === notificationGroups.length - 1}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
})

function SingleNotificationGroup({
  notificationGroup,
  onDismiss,
  isLast,
}: {
  notificationGroup: NotificationGroup
  onDismiss: () => void
  isLast: boolean
}) {
  const type = notificationGroup.type
  if (type === UserProfileNotificationType.GroupChat) {
    return (
      <GroupChatNotification
        notificationGroup={notificationGroup}
        onDismiss={onDismiss}
        isLast={isLast}
      />
    )
  }

  return <div className="px-5">{notificationGroup.subject}</div>
}

function EmptyState() {
  const { tt } = useTranslationTyped()
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <BellIcon size={35} className="mb-2 !stroke-fixed-accent-color" />
      <div className="mb-2 text-title-medium text-on-surface">
        {tt.notifications.all_seen()}
      </div>
      <div className="px-20 text-center text-body-medium text-on-surface-var">
        {tt.notifications.all_seen_description()}
      </div>
    </div>
  )
}
