import classNames from 'classnames'
import { Dialog, InlineDialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useTranslationTyped } from 'i18n/i18n'
import { DateTime } from 'luxon'
import { forwardRef, useEffect, useRef, useState } from 'react'
import type { FieldError } from 'react-hook-form'
import { BreakoutDateTimePicker } from './BreakoutDateTimePicker'
import { FormError } from './form/FormError'
import { FormLabel } from './form/FormLabel'
import type { FormFieldDefaults } from './types'
import { CalendarIcon } from 'components/icons/Calendar'

/** OneMinute resolution has a huge dropdown so is intended for internal use only. */
export type DateTimeResolution = '1min' | '15min' | '1day'

export interface BreakoutDateTimeProps
  extends Omit<
    FormFieldDefaults<'input'>,
    'value' | 'onChange' | 'max' | 'min' | 'defaultValue'
  > {
  // kind secondary is the default and represents the "primary" style in figma
  // tertiary is the secondary style in figma
  kind?: 'secondary' | 'tertiary'
  LeadingIcon?: React.ComponentType<
    { size: number } & React.SVGProps<SVGSVGElement>
  >
  iconClassName?: string
  inputClassName?: string
  min?: DateTime
  max?: DateTime
  emptyText?: string
  value?: DateTime | null
  defaultValue?: DateTime
  hideNowButton?: boolean
  initialView?: DateTime
  force12HourMode?: boolean
  onChange?: (value: DateTime | null) => void
  resolution: DateTimeResolution
}

export const BreakoutDateTimeInput = forwardRef<
  HTMLInputElement,
  BreakoutDateTimeProps
>(function BreakoutDateTimeInput(
  {
    value: _value,
    defaultValue,
    onChange,
    min,
    max,
    error,
    errorClass,
    className,
    disabled,
    hideNowButton,
    placeholder = 'Select Date',
    label,
    kind = 'tertiary',
    name,
    labelClass,
    required,
    initialView,
    force12HourMode,
    resolution,
  }: BreakoutDateTimeProps,
  fwdRef
) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [innerValue, setInnerValue] = useState<DateTime | null | undefined>(
    defaultValue ? defaultValue : null
  )

  // Update innerValue when controlled value changes
  useEffect(() => {
    if (_value === undefined) return
    setInnerValue(_value)
  }, [_value])

  // Use controlled value if provided, otherwise use internal state
  const value = _value !== undefined ? _value : innerValue

  const innerRef = useRef<HTMLInputElement | null>(null)

  const { tt } = useTranslationTyped()

  return (
    <div className="flex flex-col items-start gap-0.5">
      {label && (
        <div>
          <FormLabel
            required={required}
            label={label}
            labelClass={labelClass}
          />
        </div>
      )}
      <div className="relative h-0 w-0 overflow-hidden">
        <input
          ref={(e) => {
            innerRef.current = e
            if (!fwdRef) {
              return
            }

            if (typeof fwdRef === 'function') {
              fwdRef(e)
              return
            } else {
              fwdRef.current = e
            }
          }}
          name={name}
          disabled={disabled}
          data-testid={`datetime-input-${name}`}
          type="datetime-local"
          className="absolute hidden opacity-0"
          onChange={(e) => {
            const datetime = e.target.value
              ? DateTime.fromISO(e.target.value)
              : null
            setInnerValue(datetime)
            onChange?.(datetime)
          }}
          value={
            value
              ? value.toFormat('yyyy-LL-dd') + 'T' + value.toFormat('HH:mm')
              : ''
          }
        />
      </div>
      <div
        tabIndex={0}
        aria-label={[
          label,
          value ? getFormattedDateTime(value, resolution) : placeholder,
        ].join(': ')}
        role="button"
        aria-haspopup="dialog"
        data-testid={`datetime-field-${name}`}
        onKeyDown={(e) => {
          if (disabled) return
          if (e.key === 'Enter' || e.key === ' ') {
            setDialogOpen(true)
          }
        }}
        onClick={() => {
          if (disabled) return
          setDialogOpen(true)
        }}
        className={classNames(
          'group flex h-[3.25rem] w-full cursor-pointer select-none flex-row items-center justify-between rounded-2xl px-4 py-1 text-label-medium outline-on-surface-disabled focus:ring-2 focus:ring-on-surface-disabled md:focus:text-xs',
          className,
          {
            'bg-core-secondary': kind === 'secondary' && !disabled,
            'bg-core-tertiary': kind === 'tertiary' && !disabled,
            'bg-surface-dim': kind === 'secondary' && disabled,
            'bg-surface': kind === 'tertiary' && disabled,
            'text-on-surface-disabled': disabled,
          }
        )}
      >
        <div
          className={classNames('inline-flex items-center gap-1', {
            'text-on-surface-disabled': !value,
          })}
        >
          <CalendarIcon size={12} className="mb-[1px]" />
          {value ? getFormattedDateTime(value, resolution) : placeholder}
        </div>
        {!disabled && (
          <div
            className="-mr-2 p-2"
            data-testid={`datetime-clear-${name}`}
            onClick={(e) => {
              if (disabled) return

              e.preventDefault()
              e.stopPropagation()
              setInnerValue(null)
              onChange?.(null)
            }}
          >
            {!value ? null : (
              <strong className="clear-date text-body-medium lowercase text-on-surface-disabled hover:text-core-error">
                {tt.inputs.clear()}
              </strong>
            )}
          </div>
        )}
      </div>
      <DatePickerDialog
        force12HourMode={force12HourMode}
        header={label}
        min={min}
        error={error}
        max={max}
        content="Are you sure you want to change the date?"
        show={dialogOpen}
        value={value ?? null}
        initialView={initialView}
        hideNowButton={hideNowButton}
        onChange={(datetime) => {
          onChange?.(datetime)
          setInnerValue(datetime)
          setDialogOpen(false)
        }}
        onCancel={() => {
          setDialogOpen(false)
        }}
        resolution={resolution}
      />

      <FormError error={error} errorClass={errorClass} />
    </div>
  )
})

function getFormattedDateTime(
  dateTime: DateTime,
  resolution: DateTimeResolution
) {
  if (resolution === '1day') {
    return dateTime.toLocaleString(DateTime.DATE_FULL)
  }
  return dateTime.toLocaleString(DateTime.DATETIME_FULL)
}

export function DatePickerDialog({
  value,
  show,
  hideNowButton,
  onChange,
  onCancel,
  min,
  error,
  max,
  header,
  initialView,
  force12HourMode,
  resolution = '15min',
  transitionDuration,
  useInlineDialog = true,
}: {
  value: DateTime | null
  content: string
  show: boolean
  hideNowButton?: boolean
  error: FieldError | undefined
  onChange: (dateTime: DateTime | null) => void
  onCancel: () => void
  header?: string
  min?: DateTime
  max?: DateTime
  initialView?: DateTime
  force12HourMode?: boolean
  resolution?: DateTimeResolution
  transitionDuration?: number
  useInlineDialog?: boolean
}) {
  const DialogToUse = useInlineDialog ? InlineDialog : Dialog

  return (
    <DialogToUse
      open={show}
      onDismiss={() => onCancel()}
      size="custom"
      className="relative max-w-[400px] rounded-3xl bg-surface-bright p-13"
      transitionDuration={transitionDuration}
    >
      <DialogCloseButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onCancel()
          return false
        }}
      />
      {header !== undefined && (
        <div className="mb-3 text-left text-headline-medium">{header}</div>
      )}
      <div className="text-center text-body-large">
        <BreakoutDateTimePicker
          error={error}
          initialView={initialView}
          hideNowButton={hideNowButton}
          min={min}
          max={max}
          onChange={onChange}
          value={value}
          force12HourMode={force12HourMode}
          resolution={resolution}
        />
      </div>
    </DialogToUse>
  )
}
