import { observer } from 'mobx-react-lite'
import { ExistingRoomState } from './ExistingRoomState'
import { NewRoomState } from './NewRoomState'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { useRepository } from 'hooks/auth'
import { NewRoomStateV2 } from './NewRoomStateV2'

export const RoomsDialog = observer(function RoomsModal({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const { showNewScheduling } = useRepository().featureFlags.data

  if (cubit.roomStateId) {
    return (
      <Dialog testId="rooms-dialog" size="sm">
        <DialogCloseButton />
        <ExistingRoomState cubit={cubit} />
      </Dialog>
    )
  }

  if (showNewScheduling)
    return (
      <Dialog
        testId="rooms-dialog"
        size="xl"
        className="h-[700px] w-[1200px]"
        innerClassName="flex h-full max-w-[1000px] mx-auto"
      >
        <DialogCloseButton />
        <NewRoomStateV2 cubit={cubit} />
      </Dialog>
    )

  return (
    <Dialog
      testId="rooms-dialog"
      size="xl"
      innerClassName="flex  h-full items-stretch"
      className="min-h-[50vh] w-[1200px]"
    >
      <DialogCloseButton />
      <NewRoomState cubit={cubit} />
    </Dialog>
  )
})
