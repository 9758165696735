/**
 *  scan for URLs to convert to anchor tags
 */
export function HyperlinkedText({
  message,
  className,
  target,
  rel,
  ...rest
}: Omit<React.HtmlHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  message: string
  target?: string
}) {
  const regex = /(https?:\/\/[^\s]+)/g
  const messageParts = message.split(regex)
  return messageParts.map((part, index) => {
    if (regex.test(part)) {
      return (
        <a
          {...rest}
          className={className ?? 'underline'}
          key={index}
          href={part}
          target={target ?? '_blank'}
          rel={rel ?? 'noopener noreferrer'}
        >
          {part}
        </a>
      )
    }
    // need to wrap span to suppress react unique key warning
    return <span key={index}>{part}</span>
  })
}
