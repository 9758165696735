import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  linkWithPopup,
} from 'firebase/auth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthContext, useRepository } from '../../hooks/auth'
import { DevLoginButton } from './DevLoginButton'
import type { SlideDeck } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import type { SlideDeckMaterial } from '@breakoutlearning/firebase-repository/models/SlideDeckMaterial'
import { LoginCubit } from '@breakoutlearning/firebase-repository/cubits/LoginCubit'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { AnimatePresence, m } from 'framer-motion'
import { Trans } from 'react-i18next'
import { MultiLineText } from 'components/breakout/MultiLineText'
import { useCubitBuilder } from 'hooks/cubits'
import { useThrottledCallback } from 'hooks/functions'
import { FirebaseError } from 'firebase/app'
import { noTryAsync } from '@breakoutlearning/firebase-repository/util'
import { useDialogs } from 'hooks/dialogs'
import { getDialogConfirmation } from 'util/getDialogConfirmation'
import { useTranslationTyped } from 'i18n/i18n'
import { type FirebaseRepository } from '@breakoutlearning/firebase-repository/models/FirebaseRepository'
import { useRootStore } from 'hooks/rootStore'
import { captureException } from '@sentry/react'
import { FullPageSpinner } from 'components/Spinner'
import { CircleAlert } from 'components/icons/CircleAlert'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { when } from 'mobx'

export const LoginPage = observer(function Login() {
  const repository = useRepository()
  const authContext = useAuthContext()
  const [error, setError] = useState<string | null>()
  const [loginInProgress, setLoginInProgress] = useState(false)
  const { t, tt } = useTranslationTyped()
  const { showDialog, popDialog } = useDialogs()
  const store = useRootStore()
  const { router } = store

  // no invitation, invalid invitation, valid section invite, or
  const [checkInvitationResult, setCheckInvitationResult] = useState<
    | { loading: true }
    | {
        loading: false
        hasInvitationId: false
      }
    | ({
        loading: false
        hasInvitationId: true
      } & Awaited<ReturnType<FirebaseRepository['checkIsValidInvitation']>>)
  >({ loading: true })

  const loginCubit = useCubitBuilder(
    () => new LoginCubit(repository),
    [repository]
  )
  const [showSlideDecks, setShowSlideDecks] = useState(false)

  useEffect(() => {
    if (!repository.featureFlags.isLoaded) return
    if (
      !repository.featureFlags.data.verifyInvitation ||
      !router.currentPath.startsWith('/invitation/')
    )
      return setCheckInvitationResult({
        loading: false,
        hasInvitationId: false,
      })

    const token = router.currentPath.split('/invitation/').pop()

    if (!token)
      return setCheckInvitationResult({
        loading: false,
        hasInvitationId: false,
      })

    // check the token then set the result
    repository
      .checkIsValidInvitation(token)
      .then((result) => {
        setCheckInvitationResult({
          loading: false,
          hasInvitationId: true,
          ...result,
        })
      })
      .catch((e) => {
        captureException(e)
        setCheckInvitationResult({
          loading: false,
          hasInvitationId: false,
        })
      })
  }, [repository, router.currentPath, repository.featureFlags.isLoaded])

  const { title, subtitle, dataForValidInvitationType, viewToShow } =
    useMemo(() => {
      if (repository.featureFlags.isLoading)
        return {
          viewToShow: 'loading' as const,
          title: '',
          subtitle: '',
          dataForValidInvitationType: null,
        }

      // if we are not checking for an invitation, show the login buttons with old copy
      if (!repository.featureFlags.data.verifyInvitation)
        return {
          viewToShow: 'login' as const,
          title: tt.login.title(),
          subtitle: tt.login.title_sub(),
          dataForValidInvitationType: null,
        }

      // if loading or invalid we don't show title/sub
      // instead we show the invalid invitation view
      if (checkInvitationResult.loading)
        return {
          viewToShow: 'loading' as const,
          title: '',
          subtitle: '',
          dataForValidInvitationType: null,
        }

      if (checkInvitationResult.hasInvitationId === false)
        return {
          viewToShow: 'login' as const,
          title: tt.login.verify_invitation.title_no_invite(),
          subtitle: tt.login.verify_invitation.subtitle_no_invite(),
          dataForValidInvitationType: null,
        }

      // title and sub aren't displayed for invalid invitations, so use empty strings
      if (!checkInvitationResult.isValid)
        return {
          viewToShow: 'invalid' as const,
          title: '',
          subtitle: '',
          dataForValidInvitationType: null,
        }

      const subtitle: string = (() => {
        switch (checkInvitationResult.invitationType) {
          // if invite type unknown, show generic invite
          case null:
          case 'section':
            return tt.login.verify_invitation.subtitle_class_invite()
          case 'instructor':
            return tt.login.verify_invitation.subtitle_instructor_invite()
          case 'ta':
            return tt.login.verify_invitation.subtitle_ta_invite()
        }
      })()

      return {
        viewToShow: 'login' as const,
        title: tt.login.verify_invitation.title_invite(),
        subtitle,
        dataForValidInvitationType: {
          invitationType: checkInvitationResult.invitationType,
          instructor:
            checkInvitationResult.invitationType === 'section' &&
            checkInvitationResult.instructor,
          section:
            checkInvitationResult.invitationType === 'section' &&
            checkInvitationResult.section,
        } as DataForValidInvitationType,
      }
    }, [
      checkInvitationResult,
      repository.featureFlags.data.verifyInvitation,
      repository.featureFlags.isLoading,
      tt,
    ])

  const onLoginClick = useCallback(
    async (useProvider: 'google' | 'microsoft') => {
      const provider =
        useProvider === 'google'
          ? getProviderFromId('google.com')
          : getProviderFromId('microsoft.com')
      setError(null)
      const setErrorMessage = (error: unknown) =>
        setError(
          // @ts-expect-error can't cast as any, but its an error
          'message' in error && typeof error.message === 'string'
            ? error.message
            : 'Unknown error'
        )

      await when(() => repository.featureFlags.isLoaded)

      // always prompt the user to select account
      if (repository.featureFlags.data.forceLoginAccountSelect) {
        provider.setCustomParameters({
          prompt: 'select_account',
        })
      }

      try {
        setLoginInProgress(true)
        await signInWithPopup(repository.auth, provider)
      } catch (error: unknown) {
        if (
          !(error instanceof FirebaseError) ||
          error.code !== 'auth/account-exists-with-different-credential' ||
          typeof error.customData?.email !== 'string'
        )
          return setErrorMessage(error)

        // When we fail login because account exists we get valid oauth data back in error.customData._tokenResponse
        // we should be able to use this to link the account sans the second popup but it doesn't work for unknown reasons
        // so I'm leaving this here for future reference

        // const { providerId, oauthIdToken, oauthAccessToken }  = error.customData._tokenResponse as Record<string, unknown>
        // if (typeof providerId !== 'string' || typeof oauthIdToken !== 'string' || typeof oauthAccessToken !== 'string' || !providerId || !oauthIdToken || !oauthAccessToken)
        //   return setErrorMessage(error)
        // const credential = new OAuthProvider(providerId).credential({idToken: oauthIdToken, accessToken: oauthAccessToken})

        // existing account with different provider, try to reconcile
        const { email } = error.customData

        // right now we only have google and microsoft so we can just switch between them
        // if we add more providers we'll probably need to add a button for each provider in the first confirmation dialog
        const otherSupportedLinkedProvider = getProviderFromId(
          useProvider === 'google' ? 'microsoft.com' : 'google.com'
        )
        otherSupportedLinkedProvider.setCustomParameters({ login_hint: email })

        // show confirmation to login with other provider
        const [signInResult, signInErr] = await noTryAsync(() =>
          getDialogConfirmation({
            showDialog,
            buttonKind: 'primary',
            text: t('login.auth_link.existing_provider_dialog_title'),
            subtitle: (
              <p className="text-body-medium">
                <Trans
                  i18nKey="login.auth_link.existing_provider_dialog_sub"
                  values={{ provider: otherSupportedLinkedProvider.providerId }}
                  components={{
                    bold: <strong className="font-bold" />,
                  }}
                />
              </p>
            ),
            onConfirm: async () => {
              repository.lockEntrypoint()
              return await signInWithPopup(
                repository.auth,
                otherSupportedLinkedProvider
              )
            },
            dismiss: () => {
              popDialog()
            },
          })
        )

        if (signInErr || !signInResult?.confirmed) {
          if (signInErr) setErrorMessage(signInErr)
          return
        }

        const { confirmed: didConfirmSignIn } = signInResult

        if (!didConfirmSignIn) return

        const { value: userCredential } = signInResult

        const [, linkErr] = await noTryAsync(() =>
          getDialogConfirmation({
            showDialog,
            text: t('login.auth_link.link_provider_dialog_title'),
            buttonKind: 'primary',
            subtitle: (
              <p className="text-body-medium">
                <Trans
                  i18nKey="login.auth_link.link_provider_dialog_sub"
                  values={{ provider: provider.providerId }}
                  components={{
                    bold: <strong className="font-bold" />,
                  }}
                />
              </p>
            ),
            dismiss: () => {
              popDialog()
            },
            onConfirm: async () =>
              await linkWithPopup(userCredential.user, provider),
          })
        )

        if (linkErr) {
          setErrorMessage(linkErr)

          // sign out the user if they confirmed linking but it failed
          await repository.auth.signOut()
        }
      } finally {
        repository.unlockEntrypoint()

        // pass query param if we verified invitation pre-login
        // this will prevent the confirm on the invitation page
        if (
          repository.featureFlags.data.verifyInvitation &&
          'isValid' in checkInvitationResult
        ) {
          store.updateQueryParams({
            invitationVerified: checkInvitationResult.isValid,
          })
        }

        setLoginInProgress(false)
      }
    },
    [repository, showDialog, t, popDialog, checkInvitationResult, store]
  )

  useEffect(() => {
    const onResize = () => {
      const sufficientSizeForCarousel = window.innerWidth > 1300
      if (sufficientSizeForCarousel) {
        loginCubit.initSlideDecks()
      }
      setShowSlideDecks(sufficientSizeForCarousel)
    }
    window.addEventListener('resize', onResize)
    onResize()
    return () => window.removeEventListener('resize', onResize)
  }, [loginCubit])

  // must load flags to determine if we must verify invite
  if (viewToShow === 'loading') return <FullPageSpinner />

  return (
    <main id="main" className="flex h-full w-full flex-row gap-8">
      <div
        className={classNames(
          'box-border flex h-full flex-row rounded-3xl bg-surface-bright px-10 py-11 md:w-full',
          { 'max-w-[600px]': showSlideDecks }
        )}
      >
        <div className="items-left flex h-full flex-col justify-between">
          <div>
            <img
              // disabled until we have a dark mode version
              // className="dark:invert"
              src="/assets/images/logo_large_trimmed.png"
              alt="Breakout Learning Logo"
              width={161}
            />
          </div>
          {viewToShow === 'login' && (
            <>
              <div className="max-w-[600px] text-on-surface">
                <InvitationTypePill
                  dataForValidInvitationType={dataForValidInvitationType}
                />
                <h3 className="mb-3 text-display-medium font-bold leading-10 tracking-tighter">
                  <MultiLineText string={title} />
                </h3>
                <p className="text-body-large">{subtitle}</p>
                <InvitationTypeDetails
                  dataForValidInvitationType={dataForValidInvitationType}
                />
                <div>
                  <div className="mt-7 flex flex-wrap gap-4">
                    <button
                      disabled={loginInProgress}
                      className="flex w-[250px] flex-row items-center justify-center rounded-[4px] bg-core-secondary px-1 py-[0.625rem] text-label-large disabled:opacity-30"
                      onClick={() => onLoginClick('google')}
                    >
                      <img
                        alt="Google Logo"
                        aria-hidden
                        className="mr-3 inline"
                        src="/assets/images/google.png"
                        width={30}
                        style={{ imageRendering: 'crisp-edges' }}
                      />
                      {t('login.google_login')}
                    </button>
                    <button
                      disabled={loginInProgress}
                      className="flex w-[250px] flex-row items-center justify-center rounded-[4px] bg-core-secondary px-1 py-[0.625rem] text-label-large disabled:opacity-30"
                      onClick={() => onLoginClick('microsoft')}
                    >
                      <img
                        alt="Microsoft Logo"
                        aria-hidden
                        className="mr-3 inline disabled:opacity-30"
                        src="/assets/images/microsoft.png"
                        width={30}
                        style={{ imageRendering: 'crisp-edges' }}
                      />
                      {t('login.microsoft_login')}
                    </button>
                  </div>
                  {error && <p className="mt-4 text-breakout-red">{error}</p>}
                </div>

                {authContext.usingLocalEmulator && (
                  <div className="mt-6 flex flex-row flex-wrap items-center justify-start gap-2">
                    <div>Development login as</div>
                    <DevLoginButton role="admin" />
                    <DevLoginButton role="instructor" />
                    <DevLoginButton role="student" />
                    <DevLoginButton role="ta" />
                    <DevLoginButton role="corre" />
                    <DevLoginButton role="editor" />
                    <DevLoginButton role="author" />
                  </div>
                )}
              </div>
              <div>
                <strong className="text-label-medium">
                  {t('login.login_terms')}{' '}
                  <a
                    className="text-title-small"
                    target="_blank"
                    href="https://breakoutlearning.com/legal/terms-of-use"
                    rel="noreferrer"
                  >
                    {t('login.terms_and_conditions')}
                  </a>
                </strong>
              </div>
            </>
          )}
          {viewToShow === 'invalid' && (
            <InvitationInvalid
              invitationType={
                'invitationType' in checkInvitationResult
                  ? checkInvitationResult.invitationType
                  : null
              }
            />
          )}
        </div>
      </div>
      {showSlideDecks && loginCubit.slideDecksWithMaterials.length > 0 && (
        <SlideDeckCarousel cubit={loginCubit} />
      )}
    </main>
  )
})

const SlideDeckCarousel = observer(function SlideDeckCarousel({
  cubit,
}: {
  cubit: LoginCubit
}) {
  const [currentSlideDeckIndex, setCurrentSlideDeckIndex] = useState(0)
  const slideDeckWithMaterials =
    cubit.slideDecksWithMaterials[currentSlideDeckIndex]
  const animationDuration = 2

  const nextSlide = useThrottledCallback(
    () => {
      setCurrentSlideDeckIndex((index) => {
        const nextIndex = index + 1
        const nextIndexOrStart =
          nextIndex >= cubit.slideDecksWithMaterials.length ? 0 : nextIndex

        return nextIndexOrStart
      })
    },
    animationDuration * 1000,
    [cubit.slideDecksWithMaterials]
  )

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000)
    return () => clearInterval(interval)
  }, [nextSlide, currentSlideDeckIndex])

  return (
    <div className="relative h-full w-full">
      <AnimatePresence key="foo" initial={true} mode="sync">
        <m.div
          key={currentSlideDeckIndex.toString()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: animationDuration }}
          className="absolute inset-0 left-0 top-0 h-full w-full "
        >
          <SlideDeckCarouselInner
            slideDeckWithMaterial={slideDeckWithMaterials}
            onClick={nextSlide}
            currentSlideDeckIndex={currentSlideDeckIndex}
            slideCount={cubit.slideDecksWithMaterials.length}
          />
        </m.div>
      </AnimatePresence>
    </div>
  )
})

const InvitationTypeDetails = ({
  dataForValidInvitationType,
}: {
  dataForValidInvitationType: DataForValidInvitationType | null
}) => {
  const { tt } = useTranslationTyped()

  if (!dataForValidInvitationType) return null

  return (
    <div className="mt-7 flex flex-col gap-7">
      {dataForValidInvitationType.invitationType === 'section' && (
        <div className="flex flex-col gap-1">
          <div className="mb-2 flex flex-row">
            <div className="w-[124px] text-body-large">
              {tt.login.verify_invitation.class_name()}
            </div>
            <strong className="text-body-large font-bold">
              {dataForValidInvitationType.section.data.className}
            </strong>
          </div>
          <div className="mb-2 flex flex-row">
            <div className="w-[124px] text-body-large">
              {tt.login.verify_invitation.section_name()}
            </div>
            <strong className="text-body-large font-bold">
              {dataForValidInvitationType.section.data.sectionName}
            </strong>
          </div>
          <div className="flex flex-row">
            <div className="w-[124px] text-body-large">
              {tt.login.verify_invitation.instructor()}
            </div>
            <div className="flex flex-row gap-1 text-body-large font-bold">
              <BreakoutUserAvatar
                user={dataForValidInvitationType.instructor}
                radius={10}
                className="px-1"
              />
              <span>{dataForValidInvitationType.instructor.fullName}</span>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row items-center gap-1 rounded-xl border border-outline-variant p-2 text-body-large">
        <div className="p-2">
          <CircleAlert className="stroke-fixed-accent-color" size={24} />
        </div>
        <span>{tt.login.verify_invitation.institutional_email_warning()}</span>
      </div>
    </div>
  )
}

const InvitationTypePill = ({
  dataForValidInvitationType,
}: {
  dataForValidInvitationType: DataForValidInvitationType | null
}) => {
  const { tt } = useTranslationTyped()

  const label = useMemo(() => {
    if (!dataForValidInvitationType) return null
    switch (dataForValidInvitationType.invitationType) {
      case null:
        return null
      case 'section':
        return tt.login.verify_invitation.class_invitation()
      case 'instructor':
        return tt.login.verify_invitation.instructor_invitation()
      case 'ta':
        return tt.login.verify_invitation.ta_invitation()
    }
  }, [dataForValidInvitationType, tt.login.verify_invitation])

  if (!label) return null

  return (
    <BreakoutPill
      dot
      label={label}
      kind="secondary"
      dotClassName="bg-fixed-orange"
      className="mb-3 text-label-medium"
    />
  )
}

const SlideDeckCarouselInner = ({
  slideDeckWithMaterial,
  onClick,
  currentSlideDeckIndex,
  slideCount,
}: {
  slideDeckWithMaterial: {
    slideDeck: SlideDeck
    material: SlideDeckMaterial
  }
  currentSlideDeckIndex: number
  slideCount: number
  onClick?: () => void
}) => {
  const { slideDeck, material } = slideDeckWithMaterial

  const materialLink = material.data.materialLink

  return (
    <div
      id="main"
      style={{ backgroundImage: `url(${materialLink})` }}
      className={classNames(
        'flex h-full w-full place-items-end overflow-hidden rounded-3xl bg-cover bg-center bg-no-repeat p-10',
        { 'cursor-pointer': !!onClick && slideCount > 1 }
      )}
      onClick={onClick}
    >
      <div
        className={classNames('flex w-full flex-row place-items-end', {
          'justify-between': slideCount > 1,
          'justify-end': slideCount === 1,
        })}
      >
        <CarouselTabs
          activeTab={currentSlideDeckIndex}
          totalTabs={slideCount}
        />
        <div className="flex flex-col place-items-end justify-end">
          <div className="mb-[9px] flex flex-row">
            <img
              src={slideDeck.data.slideDeckImageURL}
              className="mr-1 h-5 object-contain"
              alt={slideDeck.data.slideDeckName}
            />
            <h2 className="text-title-large text-core-tertiary">
              {slideDeck.data.slideDeckName}
            </h2>
          </div>
          <h3 className="text-headline-medium text-core-tertiary">
            {slideDeck.data.slideDeckTeaser}
          </h3>
        </div>
      </div>
    </div>
  )
}

const CarouselTabs = ({
  activeTab,
  totalTabs,
}: {
  activeTab: number
  totalTabs: number
}) => {
  if (totalTabs < 2) return null
  return (
    <div className="flex flex-row justify-center gap-1">
      {Array.from({ length: totalTabs }).map((_, i) => (
        <div
          key={i}
          className={classNames('h-0.5 w-[22px] rounded-[100px]', {
            'bg-fixed-grey': activeTab !== i,
            'bg-core-secondary': activeTab === i,
          })}
        />
      ))}
    </div>
  )
}

function getProviderFromId(providerId: 'google.com' | 'microsoft.com') {
  switch (providerId) {
    case 'google.com':
      return new GoogleAuthProvider()
    case 'microsoft.com':
      return new OAuthProvider('microsoft.com')
  }
}

/**
 * provide null if invitation type is not known
 * else provide boolean to *isSectionInvite*
 */
function InvitationInvalid({
  invitationType,
}: {
  invitationType: null | 'instructor' | 'ta' | 'section'
}) {
  const { tt } = useTranslationTyped()

  return (
    <div className="flex flex-grow flex-col justify-center gap-3">
      {invitationType !== null && (
        <div className="flex max-w-min flex-row items-center gap-1 rounded-full bg-core-secondary px-4 py-1">
          <CircleAlert className="stroke-core-error" size={12.5} />
          <p className="text-nowrap text-label-medium text-core-on-secondary">
            {invitationType === 'section' &&
              tt.login.verify_invitation.class_invitation()}
            {invitationType === 'instructor' &&
              tt.login.verify_invitation.instructor_invitation()}
            {invitationType === 'ta' &&
              tt.login.verify_invitation.ta_invitation()}
          </p>
        </div>
      )}
      <h1 className="text-display-medium">
        {tt.login.verify_invitation.invitation_invalid()}
      </h1>
      <h2 className="text-body-large">
        {invitationType === 'section'
          ? tt.login.verify_invitation.class_invitation_invalid_sub()
          : tt.login.verify_invitation.invitation_invalid_sub()}
      </h2>
      {invitationType !== 'section' && (
        <BreakoutButton
          size="medium"
          kind="accent"
          onClick={() => {
            // open contact url in new tab
            window.open(
              'https://support.breakoutlearning.com/help-center/kb-tickets/new',
              '_blank'
            )
          }}
        >
          {tt.login.verify_invitation.get_in_touch()}
        </BreakoutButton>
      )}
    </div>
  )
}

type DataForValidInvitationType =
  | {
      invitationType: null | 'ta' | 'instructor'
      instructor: false
      section: false
    }
  | {
      invitationType: 'section'
      instructor: PublicUser
      section: Section
    }
