import { CircleXFillIcon } from 'components/icons/CircleXFill'
import { Trans } from 'react-i18next'
import { useState } from 'react'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { InlineDialog } from 'components/dialogs/Dialog'
import { BreakoutAsyncButton } from 'components/design-system/BreakoutButton'
import { useTranslationTyped } from 'i18n/i18n'

export const CancelExperienceConfirmationDialog = ({
  onConfirm,
  close,
  open,
}: {
  onConfirm: () => Promise<void>
  close?: () => void
  open: boolean
}) => {
  const { tt } = useTranslationTyped()
  const [inputValue, setInputValue] = useState('')
  const textToMatch =
    tt.instructor_assignment.cancel_experience_dialog.cancel_experience()
  const disableButton = inputValue.toLowerCase() !== textToMatch.toLowerCase()

  return (
    <InlineDialog
      size="lg"
      innerClassName="flex items-center justify-center w-full lg:min-w-[800px]"
      open={open}
    >
      <DialogCloseButton
        onClick={
          close
            ? () => {
                close()
                return false
              }
            : undefined
        }
      />
      <div className="flex max-w-[400px] flex-col items-center justify-center gap-3 text-center">
        <CircleXFillIcon size={50} className="fill-core-error stroke-none" />
        <h2 className="text-headline-large">
          {tt.instructor_assignment.cancel_experience_dialog.title()}
        </h2>
        <p className="text-body-large">
          {tt.instructor_assignment.cancel_experience_dialog.subtitle()}
        </p>
        <div className="text-body-large">
          <Trans
            i18nKey="instructor_assignment.cancel_experience_dialog.proceed_prompt" // optional -> fallbacks to defaults if not provided
            values={{ string: textToMatch }}
            components={{ bold: <strong className="font-bold" /> }}
          />
        </div>
        <BreakoutTextInput
          kind="secondary"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="text-none w-full"
          placeholder={tt.instructor_assignment.cancel_experience_dialog.placeholder()}
        />
        {/* disable if input text does not match textToMatch*/}
        <BreakoutAsyncButton
          size="large"
          kind="error"
          onClick={onConfirm}
          disabled={disableButton}
          fullWidth
        >
          {tt.instructor_assignment.cancel_experience_dialog.cancel_assignment()}
        </BreakoutAsyncButton>
      </div>
    </InlineDialog>
  )
}
