import type { DateTime } from 'luxon'

/**
 * When used with a BreakoutDateTimeInput with 15-minute resolution, this helper lets you create default values for controlled state.
 * Note that this function leaves seconds and milliseconds unchanged.
 * */
export function roundMinutesUpTo15(dateTime: DateTime) {
  const minute = dateTime.minute
  const roundedMinute = Math.ceil(minute / 15) * 15

  if (minute === roundedMinute) {
    return dateTime
  }

  if (roundedMinute === 60) {
    return dateTime.plus({ hours: 1 }).set({ minute: 0 })
  }

  return dateTime.set({ minute: roundedMinute })
}

export function roundMinutesDownTo15(dateTime: DateTime) {
  const minute = dateTime.minute
  const roundedMinute = Math.floor(minute / 15) * 15

  if (minute === roundedMinute) {
    return dateTime
  }

  return dateTime.set({ minute: roundedMinute })
}
