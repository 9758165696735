import { InstructorAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import {
  AssignmentState,
  OrganizationInvoiceStatus,
  SectionState,
} from '@breakoutlearning/firebase-repository/types'
import { FullPageSpinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import {
  InstructorAssignmentCubitProvider,
  useInstructorAssignmentCubit,
} from 'hooks/cubits/instructorAssignment'
import {
  InstructorSectionCubitProvider,
  useInstructorSectionCubit,
} from 'hooks/cubits/instructorSection'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { AssignmentConfiguration } from './AssignmentConfiguration'
import { ConfigurationComplete } from './ConfigurationComplete'
import { InvoicingConfiguration } from './InvoicingConfiguration'
import {
  SectionActivationScreen,
  SectionPromotionConfiguration,
} from './SectionConfiguration'
import { InvalidExpirationPage } from './InvalidExpirationPage'

export const LtiConfigurationPage = observer(function HomePage() {
  const store = useRootStore()
  const params = store.router.params
  const sectionId = params?.sectionId as string | undefined
  const assignmentId = params?.assignmentId as string | undefined

  useEffect(() => {
    window.parent.postMessage(
      {
        subject: 'lti.frameResize',
        height: 800,
      },
      '*'
    )
  }, [])
  if (!sectionId || !assignmentId) {
    return <div>Invalid URL</div>
  }

  return (
    <InstructorConfigurationPage
      sectionId={sectionId}
      assignmentId={assignmentId}
    />
  )
})

function InstructorConfigurationPage({
  sectionId,
  assignmentId,
}: {
  sectionId: string
  assignmentId: string
}) {
  const repository = useRepository()
  const user = useBreakoutUser()

  const sectionCubit = useCubitBuilder(() => {
    return new InstructorSectionCubit(repository, sectionId)
  }, [repository, sectionId])

  const assignmentCubit = useCubitBuilder(() => {
    return new InstructorAssignmentCubit(repository, {
      sectionId,
      assignmentId,
    })
  }, [repository, sectionId, assignmentId, user])

  return (
    <InstructorSectionCubitProvider value={sectionCubit}>
      <InstructorAssignmentCubitProvider value={assignmentCubit}>
        <div className="lti-configuration min-h-screen w-screen">
          <PageBody />
        </div>
      </InstructorAssignmentCubitProvider>
    </InstructorSectionCubitProvider>
  )
}

const PageBody = observer(function PageBody() {
  const user = useBreakoutUser()
  const cubit = useInstructorAssignmentCubit()
  const sectionCubit = useInstructorSectionCubit()
  const sectionState = cubit.section.data?.sectionState
  const assignmentState = cubit.assignment.data?.assignmentState
  // this controls whether the user wants to proceed to the invoicing step
  const [wantsInvoicing, setWantsInvoicing] = useState(false)

  // if we have an approval, we skip invoicing altogether
  const skipInvoicing =
    sectionCubit.approvedSectionRequest ||
    sectionCubit.sectionOrganization?.data.organizationInvoiceStatus ===
      OrganizationInvoiceStatus.alwaysInvoice
  const pendingSectionRequest = sectionCubit.pendingSectionRequest
  const showInvoicing =
    !skipInvoicing && (wantsInvoicing || pendingSectionRequest)

  useEffect(() => {
    if (!sectionCubit.organizationLoaded) return

    if (sectionCubit.sectionOrganization) {
      const orgHasInvoices =
        sectionCubit.sectionOrganization.data.organizationInvoiceStatus ===
        OrganizationInvoiceStatus.adminApproved
      setWantsInvoicing(orgHasInvoices)
    }
  }, [sectionCubit.sectionOrganization, sectionCubit.organizationLoaded])

  const sectionHasPromotions = sectionCubit.promotions.length > 0

  if (
    user.userPromotions.isLoading ||
    cubit.section.isLoading ||
    cubit.assignment.isLoading ||
    sectionCubit.sectionInvoiceRequests.isLoading ||
    sectionCubit.organizationLoading
  ) {
    return <FullPageSpinner />
  }

  if (
    sectionState === SectionState.unconfigured &&
    user.userPromotions.length > 0
  ) {
    return (
      <SectionPromotionConfiguration setWantsInvoicing={setWantsInvoicing} />
    )
  }

  if (sectionState === SectionState.unconfigured) {
    return <SectionActivationScreen />
  }

  if (
    sectionState === SectionState.notStarted &&
    showInvoicing &&
    !sectionHasPromotions
  ) {
    return (
      <InvoicingConfiguration
        setWantsInvoicing={setWantsInvoicing}
        sectionRequest={pendingSectionRequest}
      />
    )
  }

  if (!cubit.assignment.data?.expiresAt) {
    return <InvalidExpirationPage />
  }

  if (assignmentState === AssignmentState.unconfigured) {
    return <AssignmentConfiguration />
  }

  return <ConfigurationComplete />
})
