import { DateTime } from 'luxon'
import { dateSchema } from 'util/schema-date'
import type { useTranslation } from 'react-i18next'

export const getRoomStateScheduledAtSchema = ({
  t,
  expiresAt,
}: {
  t: ReturnType<typeof useTranslation>['t']
  expiresAt: DateTime | undefined
}) => {
  const now = DateTime.now().startOf('minute') // that allows you to use "now" when starting a meeting

  return dateSchema({
    required: t('student_assignment.scheduled_date_required'),
    invalid: t('student_assignment.scheduled_date_invalid'),
    min: {
      date: now,
      message: t('student_assignment.scheduled_date_in_past'),
    },
    max: expiresAt
      ? {
          date: expiresAt,
          message: t(
            'student_assignment.scheduled_date_after_deadline'
          ).replace(
            '${deadline}',
            expiresAt.toLocaleString(DateTime.DATETIME_SHORT)
          ),
        }
      : undefined,
  })
}
