import { useTranslationTyped } from 'i18n/i18n'
import { observer } from 'mobx-react-lite'

export const AlreadyImportedPage = observer(function InvalidExpirationPage() {
  const { t, tt } = useTranslationTyped()

  return (
    <div className="lti-configuration min-h-screen w-screen">
      <div className="">
        <img
          src="/assets/images/logo.png"
          width={64}
          className="absolute right-8 top-8"
          alt={t('breakout_learning')}
        />
      </div>
      <div className="lti-padlock flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[550px]">
          <div className="mb-5 flex flex-row">
            <div className="max-w-[400px] pr-5">
              <h1 className="mb-3 text-headline-large">
                {tt.lti.configuration.already_imported()}
              </h1>
              <div className="text-body-large">
                {tt.lti.configuration.already_imported_description()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
