import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useTransitionStyles,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useClick,
} from '@floating-ui/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { MoreHorizontalFill } from 'components/icons/MoreHorizontalFill'

export const NotificationsActionButton = observer(function NotificationsButton({
  children,
}: {
  children: React.ReactNode
}) {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  })

  const { isMounted, styles } = useTransitionStyles(context, {
    // Configure both open and close durations:
    duration: 300,
  })

  // Event listeners to change the open state
  const click = useClick(context)
  const dismiss = useDismiss(context)

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ])

  const component = isMounted ? (
    <div
      style={{ ...floatingStyles, ...styles }}
      ref={refs.setFloating}
      {...getFloatingProps()}
    >
      {children}
    </div>
  ) : undefined

  return (
    <div className="relative" {...getReferenceProps()} ref={refs.setReference}>
      <BreakoutButton
        kind="secondary"
        size="small"
        icon={<MoreHorizontalFill size={16} />}
        onClick={() => setIsOpen(!isOpen)}
      />

      <FloatingPortal>{component}</FloatingPortal>
    </div>
  )
})
