import { Spinner } from 'components/Spinner'
import { Suspense, lazy } from 'react'

const OrganizationsEntrypoint = lazy(() => import('./OrganizationsEntrypoint'))

// This file is the boundary which allows vite to code split the organization pages
export function OrganizationsLoader() {
  return (
    <Suspense
      fallback={
        <div className="flex h-full w-full items-center justify-center">
          <Spinner className="m-8" />
        </div>
      }
    >
      <OrganizationsEntrypoint />
    </Suspense>
  )
}
