import { observer } from 'mobx-react-lite'
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'components/Spinner'
import { useDialogs } from 'hooks/dialogs'
import { Trans, useTranslation } from 'react-i18next'
import {
  BreakoutAsyncButton,
  BreakoutButton,
} from 'components/design-system/BreakoutButton'
import { type StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { NewRoomStateCubit } from '@breakoutlearning/firebase-repository/cubits/NewRoomStateCubit'
import { type RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { LockOn } from 'components/icons/LockOn'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Search } from 'components/icons/Search'
import { useCubitBuilder } from 'hooks/cubits'
import classNames from 'classnames'
import type { TupleUnion } from 'types/TupleUnion'
import { type PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import { Shield } from 'components/icons/Shield'
import { RoomStateFormV2DialogBody } from './RoomStateFormDialogV2'
import {
  ScheduledDayOfMonthOnYear,
  ScheduledDayOfWeekAtTime,
} from '../ScheduledTimeParts'
import { useTranslationTyped } from 'i18n/i18n'

export const NewRoomStateV2 = observer(function NewRoomState({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const { t } = useTranslation()
  const { popDialog } = useDialogs()

  const tScoped = useCallback(
    (key: string) => t(`student_assignment.join_dialog_v2.${key}`),
    [t]
  )

  const dialogCubit = useCubitBuilder(
    () => new NewRoomStateCubit(cubit.repository),
    [cubit.repository]
  )

  const {
    roomStates: { isLoading, models },
  } = cubit

  const { unlockedRooms, lockedRooms } = useMemo(() => {
    return dialogCubit.filterAndSortRooms({
      rooms: models,
      t,
      groupingSizeMaximum: cubit.assignment.data.groupingSizeMaximum,
      roomStateStatus: cubit.libraryObject.roomStateStatus,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dialogCubit,
    dialogCubit.dayFilter,
    dialogCubit.timeFilter,
    dialogCubit.searchString,
    cubit,
    cubit.assignment.data.assignmentState,
    cubit.assignment.expiresAt,
    cubit.section.data.sectionState,
    cubit.slideDeck.data.slideDeckSlideCount,
    models,
  ])

  const { roomToJoin, isPhantomRoom, virtualName } = useMemo(() => {
    const roomWithName =
      dialogCubit.roomIdToJoin === null
        ? undefined
        : unlockedRooms.find(({ room }) => {
            if (room.id !== dialogCubit.roomIdToJoin) return false
            return true
          })

    return {
      roomToJoin: roomWithName?.room,
      isPhantomRoom: roomWithName?.room.isEmpty ?? true,
      virtualName: roomWithName?.virtualName ?? '',
    }
  }, [dialogCubit.roomIdToJoin, unlockedRooms])

  const joinRoom = useCallback(
    async (room: RoomState) => {
      const roomIsEmpty = room.userCount === 0
      await cubit.joinRoom(room.id, { isGroupLeader: roomIsEmpty })
      popDialog?.()
    },
    [cubit, popDialog]
  )

  /**
   * don't display the phantom room if we are filtering for real groups
   * or we've reached the target group number
   * (subtract 1 as the phantom room is included in the unlockedRooms array)
   */
  const showPhantomRoom =
    !dialogCubit.anyActiveFilters &&
    unlockedRooms.length + lockedRooms.length - 1 < cubit.targetGroupNumber

  // if hiding the phantom room, subtract from filteredLength
  const filteredLength =
    unlockedRooms.length + lockedRooms.length - (showPhantomRoom ? 0 : 1)

  const showNoResults = !isLoading && !filteredLength

  if (roomToJoin) {
    // if its empty room or phantom (createRoom) we need to show schedule dialog
    if (isPhantomRoom || !roomToJoin.userCount)
      return (
        <RoomStateFormV2DialogBody
          room={roomToJoin}
          cubit={cubit}
          onSave={popDialog}
          closeSelf={() => dialogCubit.setRoomIdToJoin(null)}
          virtualName={virtualName}
        />
      )

    return (
      <ConfirmJoinDialogBody
        room={roomToJoin}
        virtualName={virtualName}
        joinRoom={(r) => joinRoom(r)}
        cancel={() => dialogCubit.setRoomIdToJoin(null)}
      />
    )
  }

  return (
    <div className="flex h-full w-full flex-col gap-3 text-core-on-tertiary">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <h1 className="text-headline-large">{tScoped('join_a_group')}</h1>
          <h2 className="text-body-large">
            <Trans
              i18nKey="student_assignment.join_dialog_v2.join_a_group_description"
              components={{
                button: (
                  <button
                    data-testid="room-dialog-new-room"
                    className="font-bold underline"
                    disabled={!unlockedRooms.length}
                    onClick={() => dialogCubit.setRoomIdToJoin('')}
                  />
                ),
              }}
            />
          </h2>
        </div>
      </div>

      {!isLoading && (
        <div className="flex flex-row gap-5">
          <BreakoutTextInput
            autoFocus
            name="rooms-filter"
            onChange={(value) =>
              dialogCubit.setSearchString(value.target.value)
            }
            className="flex-grow"
            LeadingIcon={Search}
            inputClassName="text-body-medium border font-medium placeholder-surface-on-surface-disabled"
            kind="tertiary"
            placeholder={tScoped('search_groups_and_users')}
            iconClassName="text-on-surface-disabled"
          />
          <DayFilterSelector
            currentDay={dialogCubit.dayFilter}
            setDay={(d) => dialogCubit.setDayFilter(d)}
          />
          <TimeOfDaySelector
            currentTimeOfDay={dialogCubit.timeFilter}
            setTimeOfDay={(t) => dialogCubit.setTimeFilter(t)}
          />
        </div>
      )}
      <div className="h-full flex-grow overflow-y-auto">
        {unlockedRooms.length - (showPhantomRoom ? 0 : 1) > 0 && (
          <div
            className="pb-2"
            style={{
              display: 'grid',
              gap: '0.5rem',
              gridTemplateColumns: `repeat(auto-fill, minmax(209px, 1fr))`,
            }}
          >
            {unlockedRooms.map(({ room, virtualName }) => {
              const isPhantomRoom = room.isEmpty

              if (isPhantomRoom && !showPhantomRoom) return null

              return (
                <RoomCard
                  key={room.id}
                  room={room}
                  virtualName={virtualName}
                  isLocked={false}
                  setRoomToJoin={(id) => dialogCubit.setRoomIdToJoin(id)}
                />
              )
            })}
          </div>
        )}

        {lockedRooms.length > 0 && (
          <>
            <h2 className="my-1 text-title-large">
              {t('student_assignment.join_dialog_v2.locked_groups')}
            </h2>
            <h3 className="mb-2 text-body-medium">
              {t('student_assignment.join_dialog_v2.locked_groups_description')}
            </h3>
            <div
              style={{
                display: 'grid',
                gap: '0.5rem',
                gridTemplateColumns: `repeat(auto-fill, minmax(209px, 1fr))`,
              }}
            >
              {lockedRooms.map(({ room, virtualName }) => (
                <RoomCard
                  key={room.id}
                  room={room}
                  virtualName={virtualName}
                  isLocked={true}
                  setRoomToJoin={(id) => dialogCubit.setRoomIdToJoin(id)}
                />
              ))}
            </div>
          </>
        )}

        {isLoading && (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner />
          </div>
        )}
        {showNoResults && <NoResults />}
      </div>
    </div>
  )
})

// const NoRooms = observer(function NoRooms() {
//   const { t } = useTranslation()
//   return (
//     <div className="flex h-full w-full flex-col items-center justify-center gap-1">
//       <PeopleGroup size={30} className="stroke-fixed-accent-color" />
//       <h2 className="text-title-large text-on-surface">
//         {t('student_assignment.join_or_create_group_first_one_here')}
//       </h2>
//       <p className="text-center text-on-surface-var">
//         <MultiLineText
//           string={t(
//             'student_assignment.join_or_create_group_first_one_here_description'
//           )}
//         />
//       </p>
//     </div>
//   )
// })

const NoResults = observer(function NoResults() {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-1">
      <Search size={50} className="stroke-fixed-accent-color" />
      <h2 className="text-title-large text-on-surface">
        {t('student_assignment.join_dialog_v2.no_groups_found')}
      </h2>
      <p className="text-center text-body-large text-on-surface-var">
        {t('student_assignment.join_dialog_v2.no_groups_found_description')}
      </p>
    </div>
  )
})

const DayFilterSelector = ({
  currentDay,
  setDay,
}: {
  currentDay: NewRoomStateCubit['dayFilter']
  setDay: NewRoomStateCubit['setDayFilter']
}) => {
  const { t } = useTranslation()
  const days: Record<string, NewRoomStateCubit['dayFilter']> = {
    all: 'all',
    sunday: 'su',
    monday: 'm',
    tuesday: 't',
    wednesday: 'w',
    thursday: 'th',
    friday: 'f',
    saturday: 's',
  } as const

  return (
    <div className="ml-3 flex flex-col gap-1">
      <strong className="text-body-medium">
        {t('student_assignment.join_dialog_v2.day_of_the_week')}
      </strong>
      <div className="flex flex-row gap-1">
        {Object.entries(days).map(([day, value]) => (
          <button
            className={classNames('rounded-lg px-2 py-1', {
              'text-body-medium': currentDay !== value,
              'bg-fixed-accent-color text-label-medium': currentDay === value,
            })}
            key={day}
            onClick={() => {
              if (day === currentDay) return
              setDay(value)
            }}
          >
            {t(`student_assignment.join_dialog_v2.day_abbreviations.${day}`)}
          </button>
        ))}
      </div>
    </div>
  )
}

const TimeOfDaySelector = ({
  currentTimeOfDay,
  setTimeOfDay,
}: {
  currentTimeOfDay: NewRoomStateCubit['timeFilter']
  setTimeOfDay: NewRoomStateCubit['setTimeFilter']
}) => {
  const { t } = useTranslation()
  const timesOfDay: TupleUnion<NewRoomStateCubit['timeFilter']> = [
    'all',
    'morning',
    'afternoon',
    'evening',
  ]

  return (
    <div className="mr-5 flex flex-col gap-1">
      <strong className="text-body-medium">
        {t('student_assignment.join_dialog_v2.time_of_day')}
      </strong>
      <div className="flex flex-row gap-1">
        {timesOfDay.map((timeOfDay) => (
          <button
            className={classNames('rounded-lg px-2 py-1', {
              'text-body-medium': currentTimeOfDay !== timeOfDay,
              'bg-fixed-accent-color text-label-medium':
                currentTimeOfDay === timeOfDay,
            })}
            key={timeOfDay}
            onClick={() => {
              if (currentTimeOfDay === timeOfDay) return
              setTimeOfDay(timeOfDay)
            }}
          >
            {t(`student_assignment.join_dialog_v2.times_of_day.${timeOfDay}`)}
          </button>
        ))}
      </div>
    </div>
  )
}

const RoomCard = observer(function RoomCard({
  room,
  virtualName,
  isLocked,
  setRoomToJoin,
}: {
  room: RoomState
  virtualName: string
  isLocked: boolean
  setRoomToJoin: (roomId: string) => void
}) {
  const { t, tt } = useTranslationTyped()

  const roomNoUsers = !room.userCount
  const isPhantomRoom = room.isEmpty

  // if the room is empty and locked, don't show it
  if (room.userIds.length === 0 && isLocked) return null

  return (
    <div
      className={classNames(
        'flex flex-1 flex-col gap-2 rounded-[20px] bg-surface p-5'
      )}
    >
      {/* schedule time and group name*/}
      {!isPhantomRoom && (
        <div className="flex flex-col">
          <h3 className="h-[18px] self-stretch text-nowrap text-title-small">
            <ScheduledDayOfWeekAtTime
              scheduledAt={room.data.scheduledAt}
              t={t}
              placeholder={t('student_assignment.join_dialog_v2.not_scheduled')}
            />
          </h3>
          <strong className="h-[15px] text-body-small text-on-surface-var">
            <ScheduledDayOfMonthOnYear
              scheduledAt={room.data.scheduledAt}
              placeholder="-"
            />
          </strong>

          <strong className="h-[15px] text-label-small">
            {room.data.roomStateName ?? virtualName}
          </strong>
        </div>
      )}
      {/* room users */}
      {/* fixed space for 5 rows at 33px = 165px */}
      <div
        className={classNames({
          'min-h-[165px]': !isPhantomRoom,
          'min-h-[200px]': isPhantomRoom,
        })}
      >
        {!roomNoUsers && (
          <div className="flex flex-wrap gap-x-1">
            {room.usersSortedByName.map((user, i) => (
              <div
                key={i}
                style={{
                  // compute 50% width minus 2px for gap
                  width: `calc(50% - 2px)`,
                }}
                className="flex h-[33px] flex-row items-center gap-1 py-0.5 text-body-medium"
              >
                <BreakoutUserAvatar
                  className="min-h-6 min-w-6"
                  key={i}
                  user={user}
                  radius={12}
                />
                <div
                  // compute 100% width minus 28px for gap + avatar width
                  // this sucks but every other way i tried the text does not truncate
                  style={{
                    width: `calc(100% - 28px)`,
                  }}
                  className="flex flex-col gap-[-4px]"
                >
                  <strong className="truncate text-body-medium">
                    {user.data.firstName}
                  </strong>
                  <strong className="truncate text-body-small text-on-surface-var">
                    {user.data.lastName}
                  </strong>
                </div>
              </div>
            ))}
          </div>
        )}
        {roomNoUsers && (
          <div className="flex h-full items-center justify-center text-center text-body-medium text-on-surface-var">
            <span>{t('student_assignment.join_dialog_v2.empty_group')}</span>
          </div>
        )}
      </div>

      {/* join button */}
      {isLocked && (
        <BreakoutButton
          size="small"
          kind="tertiary"
          className="mt-auto cursor-not-allowed !text-core-error"
          fullWidth
          icon={<LockOn size={12} />}
          tooltip={t('student_assignment.join_dialog_v2.group_locked_tooltip')}
        >
          {t('student_assignment.locked')}
        </BreakoutButton>
      )}
      {!isLocked && (
        <BreakoutButton
          size="small"
          kind="accent"
          className="mt-auto"
          fullWidth
          onClick={() => setRoomToJoin(room.id)}
        >
          {roomNoUsers
            ? tt.student_assignment.start_group()
            : tt.student_assignment.join_group()}
        </BreakoutButton>
      )}
    </div>
  )
})

const ConfirmJoinDialogBody = observer(function ConfirmJoinDialog({
  room,
  virtualName,
  joinRoom,
  cancel,
}: {
  room: RoomState
  virtualName: string
  joinRoom: (roomId: RoomState) => Promise<void>
  cancel: () => void
}) {
  const { t, tt } = useTranslationTyped()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-6">
      <h1 className="text-headline-large">
        {room.roomStateName || virtualName}
      </h1>
      <ConfirmJoinDialogUsers users={room.users} room={room} />
      <div className="flex w-full max-w-[450px] flex-col gap-3 rounded-2xl border-2 border-fixed-accent-color px-7 py-4">
        <strong className="text-body-medium text-on-surface-var">
          {tt.student_assignment.join_dialog_v2.meeting_time()}
        </strong>
        <div className="flex flex-row justify-between gap-2">
          <strong className="text-title-large">
            <ScheduledDayOfWeekAtTime
              scheduledAt={room.data.scheduledAt}
              t={t}
              placeholder={tt.student_assignment.join_dialog_v2.not_scheduled()}
            />
          </strong>
          <strong className="text-title-large">
            <ScheduledDayOfMonthOnYear
              scheduledAt={room.data.scheduledAt}
              placeholder="-"
            />
          </strong>
        </div>
      </div>
      <div className="flex flex-row justify-center gap-3">
        <BreakoutButton
          kind="secondary"
          onClick={cancel}
          className="!w-[150px]"
        >
          {tt.student_assignment.cancel()}
        </BreakoutButton>
        <BreakoutAsyncButton
          kind="accent"
          className="!w-[150px]"
          onClick={() => joinRoom(room)}
          loadingText={tt.student_assignment.join_group()}
        >
          {tt.student_assignment.join_group()}
        </BreakoutAsyncButton>
      </div>
    </div>
  )
})

const ConfirmJoinDialogUsers = observer(function ConfirmJoinDialogUsers({
  users,
  room,
}: {
  users: RoomState['users']
  room: RoomState
}) {
  const ref = useRef<HTMLDivElement>(null)

  const [renderAsPureWrap, setRenderAsPureWrap] = useState(false)

  useLayoutEffect(() => {
    if (!ref.current) return
    const { current } = ref
    const observer = new ResizeObserver(() => {
      const { width } = current.getBoundingClientRect()
      if (width < 512) setRenderAsPureWrap(true)
    })
    observer.observe(ref.current)
    return () => observer.unobserve(current)
  }, [])

  // cap at 4 users per row
  // if greater than 4 distribute evenly
  const usersAsRows = useMemo(() => {
    const rowCount = Math.ceil(users.length / 4)
    const rows: RoomState['users'][] = Array.from({ length: rowCount }).map(
      () => []
    )
    users.forEach((user, i) => {
      const row = i % rowCount
      rows[row].push(user)
    })
    return rows
  }, [users])

  return (
    <div
      className={classNames('flex w-full max-w-[600px]', {
        'flex-wrap justify-center': renderAsPureWrap,
        'flex-col': !renderAsPureWrap,
      })}
      ref={ref}
    >
      {renderAsPureWrap &&
        users.map((user) => (
          <ConfirmJoinUser
            key={user.id}
            user={user}
            isGroupLeader={room.groupLeaderUserIds.includes(user.id)}
          />
        ))}
      {!renderAsPureWrap &&
        usersAsRows.map((userRow, i) => (
          <div key={i} className="flex flex-row justify-center">
            {userRow.map((user) => (
              <ConfirmJoinUser
                key={user.id}
                user={user}
                isGroupLeader={room.groupLeaderUserIds.includes(user.id)}
              />
            ))}
          </div>
        ))}
    </div>
  )
})

const ConfirmJoinUser = ({
  user,
  isGroupLeader,
}: {
  user: PublicUser
  isGroupLeader: boolean
}) => {
  return (
    <div className="flex w-[128px] max-w-[128px] flex-col items-center justify-center gap-1.5 px-2 py-1">
      <BreakoutUserAvatar user={user} radius={22.5} />
      <div className="flex w-full max-w-full flex-row items-center justify-center gap-1">
        <strong className="truncate text-body-large">{user.fullName}</strong>
        {isGroupLeader && (
          <Shield
            size={15}
            className="min-w-[15px] stroke-fixed-accent-color"
          />
        )}
      </div>
    </div>
  )
}
