import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { PreWorkModal } from './PreWorkModal'
import { QuizModal } from './QuizModal'
import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { useDialogs } from 'hooks/dialogs'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronRight } from 'components/icons/ChevronRight'
import { QuizResultsDialog } from './dialogs/QuizResultsDialog'
import { Dialog } from 'components/dialogs/Dialog'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { displayedPercentage } from '@breakoutlearning/firebase-repository/sources-of-truth/scoring'

const PreWorkDoneView = observer(function PreWorkDoneView(
  props: React.HTMLAttributes<HTMLDivElement>
) {
  const cubit = useStudentAssignmentCubit()
  const { t } = useTranslation()
  const { showDialog } = useDialogs()
  return (
    <div {...props}>
      <div className="text-body-medium text-grey-text">
        {t('student_assignment.quiz_results')}
      </div>
      <h3 className="mt-3 text-display-medium">
        {displayedPercentage(cubit.preWorkQuizPercentage)}%
      </h3>
      <button
        className="mt-2 flex text-label-medium outline-offset-2"
        onClick={() => {
          showDialog(({ remove }) => (
            <QuizResultsDialog cubit={cubit} removeSelf={remove} />
          ))
        }}
      >
        {t('student_assignment.view_details')}
        <ChevronRight size={16} />
      </button>
    </div>
  )
})

export const StudentAssignmentStep2 = observer(function StudentAssignmentStep2({
  hasPreWorkMaterials,
  hasPreMeetingQuiz,
  className,
  ...props
}: {
  hasPreWorkMaterials: boolean
  hasPreMeetingQuiz: boolean
} & React.HTMLAttributes<HTMLDivElement>) {
  const cubit = useStudentAssignmentCubit()
  const { showDialog } = useDialogs()
  const libraryObject = cubit.libraryObject
  const ready = cubit.isReady
  const { t } = useTranslation()

  const openQuizDialog = useCallback(() => {
    showDialog(({ remove: removeStartQuizModal }) => {
      return (
        <StartQuizModal
          onClick={() => {
            showDialog(({ remove: removeQuizModal }) => {
              return (
                <QuizModal
                  cubit={cubit}
                  closeModal={() => {
                    removeQuizModal()
                  }}
                />
              )
            })
            removeStartQuizModal()
          }}
        />
      )
    })
  }, [cubit, showDialog])

  const [titleKey, subtitleKey] = useMemo(() => {
    if (hasPreWorkMaterials && hasPreMeetingQuiz)
      return [
        'student_assignment.step_two.complete_pre_work_take_quiz',
        'student_assignment.step_two.complete_pre_work_take_quiz_sub',
      ]
    if (hasPreWorkMaterials)
      return [
        'student_assignment.step_two.complete_pre_work',
        'student_assignment.step_two.complete_pre_work_sub',
      ]
    return [
      'student_assignment.step_two.take_quiz',
      'student_assignment.step_two.take_quiz_sub',
    ]
  }, [hasPreMeetingQuiz, hasPreWorkMaterials])

  // disable step 2 for users viewing a room they are not part of
  if (!ready || !cubit.userIsInCurrentRoom) {
    return (
      <div className={classNames(`opacity-50`, className)} {...props}>
        <div className=" text-body-medium text-grey-text">
          {t('student_assignment.step_2')}
        </div>
        <h3 className="mt-3 text-title-large">{t(titleKey)}</h3>
        <div className="h-[80px]"></div>
      </div>
    )
  }

  const preWorkDone =
    cubit.hasQuizData &&
    cubit.hasPreWorkQuiz &&
    cubit.allPreWorkQuestionsAnswered

  const isCompleted =
    libraryObject.libraryObjectState === LibraryObjectState.completed

  if (!hasPreMeetingQuiz && !hasPreWorkMaterials) {
    // NOTE(danielmartin0): This is a redundant check — the parent of StudentAssignmentStep2 also checks this. I could bake this into the typed argument, but that would lead to difficult-to-maintain code.
    return null
  }

  if (hasPreMeetingQuiz && (isCompleted || preWorkDone)) {
    return <PreWorkDoneView className={className} {...props} />
  }

  return (
    <div
      className={classNames(
        `w-full`,
        !cubit.roomState.isScheduled ? 'min-h-[100px] opacity-30' : 'h-full',
        className
      )}
      {...props}
    >
      <div className="text-body-medium text-grey-text">
        {t('student_assignment.step_2')}
      </div>
      <h3 className="mt-3 text-title-large">{t(titleKey)}</h3>

      {cubit.roomState.isScheduled && (
        <div className="overflow-hidden">
          {!preWorkDone && (
            <p className="mt-1 text-body-medium">{t(subtitleKey)}</p>
          )}
          {preWorkDone && (
            <>
              <p className="mt-5 text-body-medium">{t('dart.completed')}</p>
              <p className="mt-5 text-body-medium">
                {displayedPercentage(cubit.preWorkQuizPercentage)}%
              </p>
            </>
          )}
          <div className="mt-3">
            {hasPreWorkMaterials && (
              <BreakoutButton
                data-testid="show-pre-work-button"
                onClick={() => {
                  showDialog(() => <PreWorkModal cubit={cubit} />)
                }}
                disabled={!cubit.roomState.isScheduled}
                className="mb-2 mr-2 inline-block w-40 rounded-2xl bg-core-secondary px-6 py-2 text-label-large text-core-on-secondary outline-offset-2"
              >
                {t('student_assignment.view_prework')}
              </BreakoutButton>
            )}
            {cubit.hasQuizData && hasPreMeetingQuiz && (
              <BreakoutButton
                data-testid="start-quiz-button"
                onClick={openQuizDialog}
                disabled={!cubit.roomState.isScheduled}
                className="inline-block w-40 rounded-2xl bg-core-primary px-8 py-2 text-label-large text-core-on-primary outline-offset-2"
              >
                {t('student_assignment.start_quiz')}
              </BreakoutButton>
            )}
          </div>
        </div>
      )}
    </div>
  )
})

export function StartQuizModal({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation()
  return (
    <Dialog testId="start-quiz-modal" size="sm">
      <DialogCloseButton />
      <div className="flex flex-col items-center justify-center text-center">
        <h1 className="mb-3 text-center text-headline-large">
          {t('student_assignment.start_quiz')}
        </h1>
        <p className="text-body-medium">
          {t('student_assignment.start_quiz_warning')}
        </p>
        <p className="mt-2 text-label-medium">
          {t('student_assignment.quiz_duration')}
        </p>

        <BreakoutButton
          data-testid="confirm-quiz-button"
          onClick={onClick}
          size="large"
          className="mt-10 w-full"
        >
          {t('student_assignment.confirm')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
}
