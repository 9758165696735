import { InvitationConsumeResult } from '@breakoutlearning/firebase-repository/types'
import { Spinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { InvitationAcceptedDialog } from './InvitationAcceptedDialog'
import { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'
import { Trans, useTranslation } from 'react-i18next'
import { useCubitBuilder } from 'hooks/cubits'
import { InvitationTokenCubit } from '@breakoutlearning/firebase-repository/cubits/InvitationTokenCubit'
import { type FirebaseRepository } from '@breakoutlearning/firebase-repository/models/FirebaseRepository'
import { Dialog } from 'components/dialogs/Dialog'
import { CircleAlert } from 'components/icons/CircleAlert'
import { useTranslationTyped } from 'i18n/i18n'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { when } from 'mobx'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'

export function InvitationTokenPage() {
  const repository = useRepository()
  const rootStore = useRootStore()
  const router = rootStore.router
  const user = useBreakoutUser()
  const { showDialog, popDialog } = useDialogs()
  const { t } = useTranslation()

  /**
   * as of now all this does is act as a proxy to call the isSectionInvitationFunction
   * but we need it to maintain our pattern of not calling firestore functions directly
   * from view. tldr: we need cubit as the C in MVC
   */
  const cubit = useCubitBuilder(
    () => new InvitationTokenCubit(repository),
    [repository]
  )

  useEffect(() => {
    const token = router.params?.token

    const didVerifyInvitation =
      'invitationVerified' in (router.queryParams ?? {}) &&
      router.queryParams?.invitationVerified === true

    async function acceptInvitation() {
      // we shouldn't ever hit this, but belt and suspenders
      if (user.isAnonymous) return
      if (!token || typeof token !== 'string') {
        rootStore.navigateTo('home')
        return
      }

      // if the user is corre, we need to check if this is a section invitation
      if (user.isCorre) {
        const isSectionInvitation = await cubit.isSectionInvitation(token)
        if (!isSectionInvitation) {
          toast.error(t('invitation.admin_denied'))
          return rootStore.navigateTo('home')
        }
      }

      await when(() => repository.featureFlags.isLoaded, { timeout: 5000 })

      if (
        !didVerifyInvitation &&
        repository.featureFlags.data.verifyInvitation
      ) {
        const invitationResult = await repository.checkIsValidInvitation(token)
        const dialogResult = await new Promise<
          | { acceptInvitation: true }
          | { acceptInvitation: false; navigateTo?: string }
        >((res) => {
          // auto accept invite if it's a valid non-section invitation
          if (
            invitationResult.isValid &&
            invitationResult.invitationType !== 'section'
          )
            return res({ acceptInvitation: true })

          showDialog(() => (
            <VerifyInvitationDialog
              checkInviteResult={invitationResult}
              accept={() => {
                popDialog()
                res({ acceptInvitation: true })
              }}
              reject={() => {
                popDialog()
                res({ acceptInvitation: false })
              }}
              logout={() => {
                popDialog()
                res({ acceptInvitation: false, navigateTo: 'logout' })
              }}
            />
          ))
        })
        if (!dialogResult.acceptInvitation)
          return rootStore.navigateTo(dialogResult.navigateTo ?? 'home')
      }

      const response = await user.consumeInvitation(token)

      switch (response.result) {
        case InvitationConsumeResult.success:
          toast.success(t('invitation.accepted'), {
            position: 'bottom-center',
          })
          break
        case InvitationConsumeResult.errorAlreadyConsumed:
          toast.error(t('invitation.already_accepted'))
          break
        case InvitationConsumeResult.errorCreatedByYou:
          toast.error(t('invitation.created_by_you'))
          break
        case InvitationConsumeResult.errorInvitationExpired:
          toast.error(t('invitation.expired'))
          break
        case InvitationConsumeResult.errorInvitationNotFound:
          toast.error(t('invitation.not_found'))
          break
        case InvitationConsumeResult.errorPromotionAlreadyConsumed:
          toast.error(t('invitation.already_accepted_promotion'))
          break
      }

      rootStore.navigateToWithHook('home', {
        hook: async () => {
          if (
            response.result !== InvitationConsumeResult.success ||
            !response.invitation?.sectionId
          ) {
            return
          }
          const cubit = new InstructorSectionCubit(
            repository,
            response.invitation.sectionId
          )
          const section = cubit.section

          // wait for the doc to prevent spinners
          await cubit.section.ready()

          if (section) {
            showDialog(() => <InvitationAcceptedDialog section={section} />)
          }
        },
      })
    }

    void acceptInvitation()
  }, [user, rootStore, router, showDialog, repository, t, cubit, popDialog])

  return (
    <div className="relative box-border flex h-full items-center justify-center overflow-auto rounded-3xl bg-core-tertiary">
      <Spinner />
    </div>
  )
}

const VerifyInvitationDialog = ({
  checkInviteResult,
  logout,
  reject,
  accept,
}: {
  checkInviteResult: Awaited<
    ReturnType<FirebaseRepository['checkIsValidInvitation']>
  >
  logout: () => void
  reject: () => void
  accept: () => void
}) => {
  const { tt } = useTranslationTyped()
  const user = useBreakoutUser()

  // when the dialog is unmounted, fire reject to cancel the promise
  useEffect(() => () => reject(), [reject])

  return (
    <Dialog
      size="xl"
      testId="assign-class-dialog"
      className={'flex h-[700px] w-[1000px] items-center justify-center'}
      innerClassName="flex flex-col h-full items-center justify-center gap-9 !min-h-0 w-[450px] !px-0"
    >
      <DialogCloseButton />
      {!checkInviteResult.isValid && (
        <>
          <div className="flex flex-col items-center gap-3">
            {checkInviteResult &&
              'invitationType' in checkInviteResult &&
              checkInviteResult.invitationType === 'section' && (
                <div className="flex max-w-min flex-row items-center gap-1 rounded-full bg-core-secondary px-4 py-1">
                  <CircleAlert className="stroke-core-error" size={12.5} />
                  <p className="text-nowrap text-label-medium text-core-on-secondary">
                    {tt.invitation.verify_invitation.student_invitation()}
                  </p>
                </div>
              )}
            <h2 className="text-headline-large">
              {tt.invitation.verify_invitation.invalid_invitation()}
            </h2>
            <p className="text-body-large">
              {tt.invitation.verify_invitation.invalid_invitation_sub()}
            </p>
          </div>
          <BreakoutButton kind="accent" onClick={reject}>
            {tt.invitation.verify_invitation.back_to_home()}
          </BreakoutButton>
        </>
      )}
      {checkInviteResult.isValid && (
        <>
          <div className="flex w-full flex-col items-center gap-3">
            <div className="flex max-w-min flex-row items-center gap-1 rounded-full bg-core-secondary px-4 py-1">
              <div className="aspect-square h-2 min-h-2 w-2 min-w-2 rounded-full bg-fixed-orange" />
              <p className="text-nowrap text-label-medium text-core-on-secondary">
                {tt.invitation.verify_invitation.student_invitation()}
              </p>
            </div>
            <h2 className="text-headline-large">
              {tt.invitation.verify_invitation.new_class_invitation()}
            </h2>
            <p className="text-body-large">
              {tt.invitation.verify_invitation.new_class_invitation_sub()}
            </p>
          </div>
          {checkInviteResult.invitationType === 'section' && (
            <div className="flex w-full flex-col gap-3 px-8">
              <LabeledSectionDetail
                label={tt.invitation.verify_invitation.class_name()}
              >
                {checkInviteResult.section.data.className}
              </LabeledSectionDetail>
              <LabeledSectionDetail
                label={tt.invitation.verify_invitation.section_name()}
              >
                {checkInviteResult.section.data.sectionName}
              </LabeledSectionDetail>
              <LabeledSectionDetail
                label={tt.invitation.verify_invitation.instructor()}
              >
                <div className="flex flex-row items-center gap-1">
                  <BreakoutUserAvatar
                    user={checkInviteResult.instructor}
                    radius={10}
                  />
                  <span>{checkInviteResult.instructor.fullName}</span>
                </div>
              </LabeledSectionDetail>
            </div>
          )}
          <div className="flex w-full flex-col gap-3 px-8">
            <LabeledSectionDetail
              label={tt.invitation.verify_invitation.your_name()}
            >
              {user.publicUser.fullName}
            </LabeledSectionDetail>
            <LabeledSectionDetail
              label={tt.invitation.verify_invitation.your_email()}
            >
              {user.profile.data.emailAddress}
            </LabeledSectionDetail>
          </div>
          <div className="px-8 text-center text-body-medium">
            <Trans
              i18nKey="invitation.verify_invitation.institutional_email_warning"
              components={{
                button: (
                  <button className="font-bold underline" onClick={logout} />
                ),
                br: <br />,
              }}
            />
          </div>
          <div className="flex w-full flex-row justify-between px-8">
            <BreakoutButton kind="secondary" onClick={reject}>
              {tt.invitation.verify_invitation.reject()}
            </BreakoutButton>
            <BreakoutButton kind="accent" onClick={accept}>
              {tt.invitation.verify_invitation.accept()}
            </BreakoutButton>
          </div>
        </>
      )}
    </Dialog>
  )
}

const LabeledSectionDetail = ({
  label,
  children,
}: {
  label: string
  children: React.ReactNode
}) => {
  return (
    <div className="flex w-full flex-row justify-between gap-2 text-body-large">
      <span className="text-nowrap">{label}</span>
      <div className="truncate text-nowrap font-bold">{children}</div>
    </div>
  )
}
