import type { useDialogs } from 'hooks/dialogs'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import type { useTranslationTyped } from 'i18n/i18n'
import type { DatePickerDialog } from 'components/design-system/BreakoutDateTimeInput'
import { toast } from 'react-hot-toast'
import { getRoomStateScheduledAtSchema } from '../roomStateScheduledAtSchema'
import { DateTime } from 'luxon'
import { captureException } from '@sentry/react'

export const showScheduleRoomDatePickerDialog = ({
  showDatePickerDialog,
  popDialog,
  t,
  tt,
  cubit,
}: {
  popDialog: ReturnType<typeof useDialogs>['popDialog']
  showDatePickerDialog: (
    props: React.ComponentProps<typeof DatePickerDialog>
  ) => void
  t: ReturnType<typeof useTranslationTyped>['t']
  tt: ReturnType<typeof useTranslationTyped>['tt']
  cubit: StudentAssignmentCubit
}) => {
  const props = getScheduleRoomStateDatePickerDialogProps({
    useInlineDialog: false,
    scheduledAtDate: cubit.roomState.scheduledAtDate,
    roomStateName: cubit.roomState.roomStateName,
    isOpen: false, // no op for as this is never inline
    minDate: DateTime.now().startOf('minute'),
    maxDate: cubit.assignment.expiresAtDate,
    cubit,
    schema: getRoomStateScheduledAtSchema({
      t,
      expiresAt: cubit.assignment.expiresAtDate,
    }),
    tt,
    popDialog,
    setIsOpen: () => {},
    setIsLoading: () => {},
  })
  return showDatePickerDialog(props)
}

export function getScheduleRoomStateDatePickerDialogProps({
  useInlineDialog,
  scheduledAtDate,
  roomStateName,
  isOpen,
  minDate,
  maxDate,
  cubit,
  schema,
  tt,
  popDialog,
  setIsOpen,
  setIsLoading,
}: {
  useInlineDialog: boolean
  roomStateName: string
  minDate: DateTime
  maxDate: DateTime | undefined
  scheduledAtDate: DateTime | undefined
  isOpen: boolean
  cubit: StudentAssignmentCubit
  schema: ReturnType<typeof getRoomStateScheduledAtSchema>
  tt: ReturnType<typeof useTranslationTyped>['tt']
  popDialog: () => void
  setIsOpen: (open: boolean) => void
  setIsLoading: (loading: boolean) => void
}): React.ComponentProps<typeof DatePickerDialog> {
  return {
    useInlineDialog,
    transitionDuration: 0,
    value: scheduledAtDate ?? null,
    content: roomStateName,
    error: undefined,
    show: isOpen,
    onCancel: () => {
      if (useInlineDialog) return setIsOpen(false)
      popDialog()
    },
    min: minDate,
    max: maxDate,
    onChange: async (dateTime) => {
      const { error, data } = schema.safeParse(dateTime)
      if (error) {
        const msg = error?.errors?.length
          ? error.errors[0].message
          : tt.student_assignment.unexpected_error_scheduling_room()
        return toast.error(msg)
      }
      try {
        useInlineDialog ? setIsOpen(false) : popDialog()
        setIsLoading(true)
        await cubit.updateRoomScheduledTime(data)
      } catch (e) {
        toast.error(tt.student_assignment.unexpected_error_scheduling_room())
        captureException(e)
      } finally {
        setIsLoading(false)
      }
    },
  }
}
