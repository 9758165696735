import type { BreakoutUser } from '@breakoutlearning/firebase-repository/models/BreakoutUser'
import { useEffect } from 'react'
import * as Sentry from '@sentry/react'

function addDebugPayloadToWindow(props: {
  userId: string
  language?: string
  timezone?: string
}) {
  window._breakoutUserId = props.userId
  window._breakoutDebug = props
}

export function useUserEffects({ user }: { user: BreakoutUser | null }) {
  useEffect(() => {
    if (!user) return
    if (!user.uid) return

    addDebugPayloadToWindow({ userId: user.uid })

    if (user.profile.data) {
      const userLanguage = navigator.language // BCP 47
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone // IANA

      const languageChanged =
        userLanguage && userLanguage !== user.profile.data.language
      const timezoneChanged =
        userTimezone && userTimezone !== user.profile.data.timezone

      addDebugPayloadToWindow({
        userId: user.uid,
        language: userLanguage,
        timezone: userTimezone,
      })

      if (languageChanged || timezoneChanged) {
        user
          .updateLanguageAndOrTimezone(
            userLanguage || undefined,
            userTimezone || undefined
          )
          .catch((error) => {
            Sentry.captureException(error)
            console.error('Failed to update language and timezone:', error)
          })
      }
    }
  }, [user, user?.uid])
}
