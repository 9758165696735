import {
  BreakoutAsyncButton,
  BreakoutButton,
} from 'components/design-system/BreakoutButton'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { CircleAlert } from 'components/icons/CircleAlert'
import { useDialogs } from 'hooks/dialogs'
import { useTranslation } from 'react-i18next'

export const LeaveRoomDialog = ({
  leaveRoom,
}: {
  leaveRoom: () => Promise<void>
}) => {
  const { popDialog } = useDialogs()
  const { t } = useTranslation()

  return (
    <Dialog
      testId="rooms-dialog"
      size="xl"
      className="h-[700px] w-[1200px]"
      innerClassName="flex h-full max-w-[1000px] mx-auto"
    >
      <DialogCloseButton />
      <div className="flex h-full w-full justify-center">
        <div className="flex h-full w-full max-w-[450px] flex-col items-center justify-center gap-5 text-center">
          <CircleAlert size={44} className="stroke-core-error" />
          <h1 className="max-w-[300px] text-headline-large">
            {t('student_assignment.leave_group_confirmation')}
          </h1>
          <strong className="text-body-large text-on-surface-var">
            {t('student_assignment.leave_group_confirmation_description')}
          </strong>
          <div className="flex w-full flex-row justify-center gap-3">
            <BreakoutButton
              size="medium"
              onClick={() => popDialog()}
              kind="secondary"
            >
              {t('student_assignment.cancel')}
            </BreakoutButton>
            <BreakoutAsyncButton
              size="medium"
              kind="error"
              onClick={async () => {
                try {
                  await leaveRoom()
                } finally {
                  popDialog()
                }
              }}
            >
              {t('student_assignment.leave_group')}
            </BreakoutAsyncButton>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
