import { ValidLibraryObjectActionState } from '@breakoutlearning/firebase-repository/stores/ValidLibraryObject'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { RoomStateForm } from './dialogs/RoomStateFormDialog'
import { RoomsDialog } from './dialogs/RoomsDialog'
import { QuizModal } from './QuizModal'
import { StartQuizModal } from './StudentAssignmentStep2'
import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { useWalkthroughDialog } from './dialogs/WalkthroughDialog.hooks'
import { MeetingResultsDialog } from './dialogs/MeetingResultsDialog'
import { SummaryDialog } from './dialogs/SummaryDialog'
import { RoomStateStatus } from '@breakoutlearning/firebase-repository/models/RoomState'
import { StudentAssignmentChatDialog } from './dialogs/StudentAssignmentChatDialog'
import { useTranslationTyped } from 'i18n/i18n'
import { showScheduleRoomDatePickerDialog } from './dialogs/ScheduleRoomStateDialogUtil'
import { DatePickerDialog } from 'components/design-system/BreakoutDateTimeInput'

/**
 * To be used on the StudentAssignmentPage
 * this component will only perform any logic on the initial render
 * and will not render anything
 *
 * This component is responsible for handling various actions defined
 * in LibraryObject as as ValidLibraryObjectActionState.
 *
 * We see if there is an action defined in the 'action' query
 * parameter, and if it is a valid action (can be parsed to the enum),
 * and if the assignment/room is an appropriate state we perform that action on behalf of the user,
 *
 * i.e joinRoom => popGroup dialog, startSession -> popStartSession dialog
 *
 */
export const StudentAssignmentLibraryActionParser = observer(
  function StudentAssignmentLibraryActionParser() {
    const { showDialog, popDialog } = useDialogs()
    const store = useRootStore()
    const cubit = useStudentAssignmentCubit()
    const { t, tt } = useTranslationTyped()

    const { showWalkthroughDialogIfNecessary } = useWalkthroughDialog({
      roomId: cubit.roomStateId,
      roomStateStatus: cubit.libraryObject.roomStateStatus,
    })

    const { showNewScheduling } = cubit.repository.featureFlags.data

    const runAction = useCallback(
      (action: ValidLibraryObjectActionState | 'chat') => {
        // special action
        if (action === 'chat') {
          // scheduling must be enabled
          // user must be in room or corre
          // room state must be set
          if (
            !showNewScheduling ||
            !(cubit.userIsInCurrentRoom || cubit.user.isCorre) ||
            !cubit.roomStateId
          ) {
            return
          }

          return showDialog(() => <StudentAssignmentChatDialog cubit={cubit} />)
        }

        switch (action) {
          case ValidLibraryObjectActionState.joinGroup: {
            if (
              cubit.roomState.isEmpty &&
              !cubit.libraryObject.requiresPayment
            ) {
              return showDialog(() => <RoomsDialog cubit={cubit} />)
            }
            return
          }
          case ValidLibraryObjectActionState.scheduleSession: {
            if (cubit.roomState.isEmpty || !cubit.currentUserCanScheduleRoom)
              return

            if (showNewScheduling)
              return showScheduleRoomDatePickerDialog({
                cubit,
                showDatePickerDialog: (p) =>
                  showDialog(() => <DatePickerDialog {...p} />),
                popDialog,
                t,
                tt,
              })

            return showDialog(() => (
              <RoomStateForm
                onSave={() => {
                  popDialog()
                }}
                cubit={cubit}
              />
            ))
          }
          case ValidLibraryObjectActionState.completeQuiz: {
            if (cubit.allPreWorkQuestionsAnswered) return
            return showDialog(({ remove: removeStartQuizModal }) => {
              return (
                <StartQuizModal
                  onClick={() => {
                    showDialog(({ remove: removeQuizModal }) => {
                      return (
                        <QuizModal
                          cubit={cubit}
                          closeModal={() => {
                            removeQuizModal()
                          }}
                        />
                      )
                    })
                    removeStartQuizModal()
                  }}
                />
              )
            })
          }
          case ValidLibraryObjectActionState.startSession: {
            const {
              libraryObject: { libraryObjectState },
            } = cubit

            const canStart =
              (libraryObjectState === LibraryObjectState.readyToStart ||
                (libraryObjectState === LibraryObjectState.live &&
                  !cubit.didRoomStart)) &&
              cubit.allPreWorkQuestionsAnswered &&
              cubit.roomState.isScheduled &&
              cubit.roomState.isNotEmpty

            if (!canStart) return
            return showWalkthroughDialogIfNecessary(() => {
              cubit.logEvent('room_join')
              cubit.setJoiningSession()
            })
          }
          case ValidLibraryObjectActionState.viewResults: {
            const isCompleted =
              cubit.roomState.getRoomStateStatus(
                cubit.assignment.expiresAt,
                cubit.section.data.sectionState,
                cubit.slideDeck.data.slideDeckSlideCount,
                cubit.assignment.data.assignmentState,
                cubit.roomGraded.value
              ) === RoomStateStatus.completed

            const hasRoomStartedAt =
              cubit.roomState.data.roomStartedAt !== undefined
            const newMeetingResultsEnabled =
              cubit.repository.featureFlags.data.sessionResultsUseNew
            const canShowNewResults =
              isCompleted &&
              hasRoomStartedAt &&
              newMeetingResultsEnabled &&
              Boolean(cubit.roomStateId)
            if (cubit.roomStateSummary.hasData || canShowNewResults) {
              showDialog(() => {
                if (canShowNewResults)
                  return <MeetingResultsDialog roomId={cubit.roomStateId!} />
                return (
                  <SummaryDialog
                    summary={cubit.roomStateSummary.data}
                    roomStateName={cubit.roomState.roomStateName}
                    roomStateActiveSlideChangedAt={
                      cubit.roomState.data.activeSlideChangedAt || null
                    }
                  />
                )
              })
            }
            return
          }
          case ValidLibraryObjectActionState.loading: // no op
          case ValidLibraryObjectActionState.enroll: // pops from reaction
          case ValidLibraryObjectActionState.joinSession: // pops from reaction
          case ValidLibraryObjectActionState.suspended: // same reaction as joinSession
          case ValidLibraryObjectActionState.availableOn: // no op
          case ValidLibraryObjectActionState.sessionScheduled: // no op
          case ValidLibraryObjectActionState.pending: // no op
          case ValidLibraryObjectActionState.experienceExpired: // nop
          case ValidLibraryObjectActionState.canceled: // no op
            return
          default: {
            const _exhaustiveCheck: never = action // for compile time switch case exhaustiveness
            throw new Error(`Unhandled action: ${_exhaustiveCheck}`)
          }
        }
      },
      [
        cubit,
        popDialog,
        showDialog,
        showNewScheduling,
        showWalkthroughDialogIfNecessary,
        t,
        tt,
      ]
    )

    useEffect(() => {
      const params = store.router.params || {}

      // Detect a situation where the params have changed and they
      // do not match the cubit - that means the student assignment page
      // has changed
      if (params.sectionId?.toString() !== cubit.sectionId) return
      if (params.assignmentId?.toString() !== cubit.assignmentId) return
      // ensure both have same empty state for comparison
      const paramsRoomStateId = params.roomStateId?.toString() || undefined
      const cubitRoomStateId = cubit.roomStateId
      if (paramsRoomStateId !== cubitRoomStateId) return

      const isReady =
        cubit.isReady &&
        cubit.repository.featureFlags.isLoaded &&
        cubit.roomStates.isLoaded &&
        (!cubit.roomStateId ||
          (cubit.roomState.isLoaded &&
            cubit.hasQuizData &&
            cubit.slideDeck.isLoaded &&
            cubit.assignment.isLoaded &&
            cubit.roomGraded.isLoaded))
      if (!isReady) return
      const actionStr = (store.router.queryParams ?? {})['action']

      // once we've attempted to grab remove the param from the url
      runInAction(() => {
        if (store.router.queryParams && 'action' in store.router.queryParams) {
          // Ok, we need to do some manual work, because we want to do a replaceState
          const queryParams = new URLSearchParams(window.location.search)
          const stringifiedQueryParams = queryParams.toString()
          const newRelativePathQuery =
            window.location.pathname +
            (stringifiedQueryParams !== '' ? `?${stringifiedQueryParams}` : '')
          window.history.replaceState(null, '', newRelativePathQuery)
          store.updateQueryParams({ action: undefined })
        }
      })

      if (typeof actionStr !== 'string' || !actionStr) return

      if (actionStr === 'chat') runAction(actionStr)

      const action = ValidLibraryObjectActionState[
        actionStr as keyof typeof ValidLibraryObjectActionState
      ] as ValidLibraryObjectActionState | undefined
      if (!action) return
      runAction(action)

      // action is defined at this point, attempt to perform the action
    }, [
      store,
      runAction,
      cubit.roomStateId,
      cubit.sectionId,
      cubit.assignmentId,
      cubit.isReady,
      store.router.params,
      store.router.queryParams,
      cubit.repository.featureFlags.isLoaded,
      cubit.roomState.isLoaded,
      cubit.hasQuizData,
      cubit.roomStates.isLoaded,
      cubit.slideDeck,
      cubit.assignment,
      cubit.roomGraded,
    ])

    return null
  }
)
