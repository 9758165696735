import { TriangleAlertIcon } from 'components/icons/TriangleAlert'
import { FullPageSpinner } from 'components/Spinner'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { useTranslationTyped } from 'i18n/i18n'
import { observer } from 'mobx-react-lite'
import { Trans } from 'react-i18next'

export const InvalidExpirationPage = observer(function InvalidExpirationPage() {
  const assignmentCubit = useInstructorAssignmentCubit()
  const { t } = useTranslationTyped()

  if (!assignmentCubit.assignment) {
    return <FullPageSpinner />
  }

  return (
    <div className="lti-configuration min-h-screen w-screen">
      <div className="">
        <img
          src="/assets/images/logo.png"
          width={64}
          className="absolute right-8 top-8"
          alt={t('breakout_learning')}
        />
      </div>
      <div className="lti-door flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[550px]">
          <div className="mb-4">
            <TriangleAlertIcon className="stroke-core-error" size={48} />
          </div>

          <div className="mb-5 flex flex-row">
            <div className="max-w-[400px] pr-5">
              <h1 className="text-headline-large">
                {t('lti.configuration.assignment_missing_dates')}
              </h1>
              <div className="text-body-large">
                <Trans
                  i18nKey="lti.configuration.assignment_missing_dates_description"
                  components={{
                    p: <p className="my-2 text-body-large" />,
                    b: <strong />,
                    ul: <ul />,
                    ol: <ol className="ml-4 list-decimal" />,
                    li: <li />,
                  }}
                />
              </div>
            </div>
            <div>
              <img
                src="/assets/images/lti/canvas-dates-example.jpg"
                alt={t('breakout_learning')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
