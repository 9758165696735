import { FullPageSpinner } from 'components/Spinner'
import { signInWithCustomToken } from 'firebase/auth'
import { useRootStore } from 'hooks/rootStore'
import { reaction } from 'mobx'
import { useEffect, useMemo, useState } from 'react'
import { useRepository } from '../../hooks/auth'
import { useCanvasMode } from 'hooks/use-canvas-mode'
import { useTranslation } from 'react-i18next'
import { BreakoutButtonLink } from 'components/design-system/BreakoutButton'

function areWeInIframe() {
  return (
    window !== window.parent ||
    window !== window.top ||
    window.self !== window.top
  )
}

function TokenLogin() {
  // const [loggingIn, setLoggingIn] = useState(false)
  const rootStore = useRootStore()
  const repository = useRepository()

  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get('token')
  const deepLinkJwt = queryParams.get('deepLinkJwt')
  const redirectTo = queryParams.get('redirectTo')
  const landingPage = queryParams.get('landingPage')
  const canvasMode = queryParams.get('canvasMode')
  const impersonateUserId = queryParams.get('impersonateUserId')
  const sectionId = queryParams.get('sectionId')
  const organizationId = queryParams.get('organizationId')
  const { setCanvasMode } = useCanvasMode()

  const canvasOptions = useMemo(() => {
    const options: Record<string, string> = {}
    if (sectionId) options.sectionId = sectionId
    if (organizationId) options.organizationId = organizationId

    return options
  }, [sectionId, organizationId])

  useEffect(() => {
    return reaction(
      () => repository.breakoutUser?.isLoaded,
      (isLoaded) => {
        if (isLoaded) {
          if (impersonateUserId) {
            rootStore.impersonateUser({
              userId: impersonateUserId,
            })
          }

          if (canvasMode) {
            setCanvasMode(
              true,
              Object.keys(canvasOptions).length > 0 ? canvasOptions : undefined
            )
          }
          rootStore.navigateTo('instructorLibrary')
        }
      }
    )
  }, [
    repository,
    rootStore,
    canvasMode,
    setCanvasMode,
    sectionId,
    organizationId,
    impersonateUserId,
    canvasOptions,
  ])

  useEffect(() => {
    if (!token) {
      alert('bad token')
      return
    }

    if (deepLinkJwt) {
      localStorage.setItem('deepLinkJwt', deepLinkJwt)
    }

    if (canvasMode) {
      setCanvasMode(
        true,
        Object.keys(canvasOptions).length > 0 ? canvasOptions : undefined
      )
    }

    signInWithCustomToken(repository.auth, token).then(() => {
      if (impersonateUserId) {
        rootStore.impersonateUser({
          userId: impersonateUserId,
        })
      }

      if (landingPage) {
        window.location.href = landingPage
        return
      }

      rootStore.navigateTo(
        'instructorLibrary',
        undefined,
        redirectTo
          ? {
              redirectTo: redirectTo,
            }
          : undefined
      )
    })
  }, [
    deepLinkJwt,
    token,
    rootStore,
    repository.auth,
    redirectTo,
    landingPage,
    canvasMode,
    sectionId,
    setCanvasMode,
    canvasOptions,
    impersonateUserId,
  ])

  return <FullPageSpinner />
}

function IframeProtector({ children }: { children: React.ReactNode }) {
  const queryParams = new URLSearchParams(window.location.search)
  const ejectFromIframe = queryParams.get('ejectFromIframe') === 'true'
  const { t } = useTranslation()
  const startTime = useMemo(() => Date.now(), [])
  const [isOutdated, setIsOutdated] = useState(false)

  useEffect(() => {
    const outdatedTime = 1000 * 60 * 15 // 15 minutes
    setInterval(() => {
      if (Date.now() - outdatedTime > startTime) {
        setIsOutdated(true)
      }
    }, 1000)
  }, [startTime])

  if (ejectFromIframe && areWeInIframe()) {
    return (
      <div className="lti-configuration min-h-screen w-screen md:-m-8">
        <div className="">
          <img
            src="/assets/images/logo.png"
            width={64}
            className="absolute right-8 top-8"
            alt={t('breakout_learning')}
          />
        </div>
        <div className="lti-rocket flex min-h-screen w-full flex-col items-start justify-center">
          <div className="ml-[5vw] max-w-[400px]">
            <div className="mb-5">
              <h2 className="text-headline-small">
                {t('lti.configuration.eject_headline_small')}
              </h2>
              <h1 className="text-headline-large">
                {t('lti.configuration.eject_headline_large')}
              </h1>
              <div className="text-body-large">
                {t('lti.configuration.eject_headline_description')}
              </div>
            </div>
            <div>
              <div>
                {isOutdated && (
                  <div className="flex h-fit min-h-[2.5rem] w-fit min-w-[9.5rem] select-none items-center justify-center rounded-xl bg-core-primary px-5 py-1 text-xs font-semibold leading-[normal] text-core-on-primary">
                    {t('login.reload_application')}
                  </div>
                )}
                {!isOutdated && (
                  <BreakoutButtonLink
                    className="flex items-center justify-center"
                    kind="accent"
                    href={window.location.href.replace(
                      'ejectFromIframe=true',
                      ''
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('login.open_application_in_new_tab')}
                  </BreakoutButtonLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <>{children}</>
}
export function LoginWithToken() {
  return (
    <IframeProtector>
      <div className="relative box-border flex h-full w-full items-center justify-center overflow-auto rounded-3xl bg-core-tertiary">
        <TokenLogin />
      </div>
    </IframeProtector>
  )
}
