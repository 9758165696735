import { TwoColumnSubRouter } from 'components/core/TwoColumnSubRouter'
import type { QueryParams, Store } from 'mobx-router'
import { Route } from 'mobx-router'
import {
  AdminCatalogsLoader,
  AdminDashboardLoader,
  AdminLibraryLoader,
  AdminTeachingPlanLoader,
  AdminUsersLoader,
  AdminDemosLoader,
  AdminInstitutionsLoader,
  AdminInspectorLoader,
} from 'pages/admin/AdminLoader'
import { AvTestEntrypoint } from 'pages/av-test/AvTestEntrypoint'
import { HomePage } from 'pages/home/HomePage'
import { InvitationTokenPage } from 'pages/invitation/InvitationTokenPage'
import { LoginWithPassword } from 'pages/login/LoginWithPassword'
import { LogoutPage } from 'pages/login/LogoutPage'
import { MeetingEntrypoint } from 'pages/meeting/MeetingEntrypoint'
import { OnboardingPage } from 'pages/onboarding/OnboardingPage'
import { ProfilePage } from 'pages/profile/ProfilePage'
import { PodcastsPage } from 'pages/student/podcasts/PodcastsPage'
import { Suspense, lazy } from 'react'
import { Environment, environment } from './environment'
import { WelcomeRedirect } from 'pages/home/WelcomeRedirect'
import { RoleGuard } from './RoleGuard'
import {
  SuperAdminPrivilege,
  UserProfileRole,
} from '@breakoutlearning/firebase-repository/types'
import { DemoInvitationPage } from 'pages/demo/invitation/DemoInvitationPage'
import { DemoWaitingRoomPage } from 'pages/demo/meeting/DemoWaitingRoomPage'
import { DemoWelcomePage } from 'pages/demo/welcome/DemoWelcomePage'
import { LoginWithToken } from 'pages/login/LoginWithToken'
import { LtiConfigurationPage } from 'pages/lti/ConfigurationPage'
import { StudentAssignmentPendingPage } from 'pages/student/assignment/StudentAssignmentPendingPage'
import { SuperAdminRoleGuard } from './SuperAdminRoleGuard'
import { InstructorLoader } from 'pages/instructor/InstructorLoader'
import { OrganizationsLoader } from 'pages/organizations/OrganizationsLoader'
import { AlreadyImportedPage } from 'pages/lti/AlreadyImportedPage'

export type RouteType = Route<
  Store,
  Record<string, string | number | boolean | undefined> | undefined,
  QueryParams
>

// Public routes are accessible without authentication
export class PublicRoute extends Route<
  Store,
  Record<string, string | number | boolean | undefined> | undefined,
  QueryParams
> {}

const twoColumn = <TwoColumnSubRouter />
export const routes: Record<string, RouteType> = {}
const subRoutes: Record<string, JSX.Element> = {}

routes.loginWithPassword = new PublicRoute({
  path: '/login-with-password',
  component: <LoginWithPassword />,
  title: 'loginWithPassword',
})
routes.loginWithToken = new PublicRoute({
  path: '/login/token',
  component: <LoginWithToken />,
  title: 'loginWithToken',
})

routes.demoInvitation = new PublicRoute({
  path: '/demo/invitation/:invitationId',
  component: <DemoInvitationPage />,
  title: 'demoInvitation',
})

routes.avTest = new Route({
  path: '/av-test',
  component: <AvTestEntrypoint />,
  title: 'avTest',
})

routes.demoWaitingRoom = new Route({
  path: '/demo/meeting/:roomId',
  component: <DemoWaitingRoomPage />,
  title: 'demoWaitingRoom',
})

routes.demoWelcome = new Route({
  path: '/demo/welcome',
  component: <DemoWelcomePage />,
  title: 'demoWelcome',
})

routes.invitationToken = new Route({
  path: '/invitation/:token',
  component: <InvitationTokenPage />,
  title: 'invitationToken',
})

routes.podcasts = new Route({
  path: '/podcast',
  component: <PodcastsPage />,
  title: 'podcasts',
})

routes.logout = new Route({
  path: '/logout',
  component: <LogoutPage />,
  title: 'logout',
})

routes.meeting = new Route({
  path: '/meeting/:roomId',
  component: <MeetingEntrypoint />,
  title: 'meeting',
})

routes.onboarding = new Route({
  path: '/onboarding',
  component: <OnboardingPage />,
  title: 'onboarding',
})

routes.ltiConfiguration = new Route({
  path: '/lti/configuration/:sectionId/:assignmentId',
  component: <LtiConfigurationPage />,
  title: 'ltiConfiguration',
})

routes.ltiAlreadyImported = new PublicRoute({
  path: '/lti/already-imported',
  component: <AlreadyImportedPage />,
  title: 'ltiAlreadyImported',
})

// Welcome is a dart deprecated route, but are adding it as a redirect to home.
routes.welcome = new Route({
  path: '/welcome',
  component: <WelcomeRedirect />,
  title: 'welcome',
})

function addTwoColumnRoute(name: string, path: string, component: JSX.Element) {
  const route = new Route({
    path: path,
    component: twoColumn,
    title: name,
  })
  routes[name] = route
  subRoutes[name] = component
}

// The HomePage controller handles home pages, but it also needs to handle
// the student experience - we need this because we need to keep a single component
// for transitions - if you switch between HomePage and StudentLibrary, the
// mount point changes and the library gets disposed and re-created, which is messing
// with cubits and transitions.
const homePageOrStudentLibrary = <HomePage />
addTwoColumnRoute('home', '/', homePageOrStudentLibrary)

addTwoColumnRoute(
  'assignmentPending',
  '/assignment-pending/:sectionId/:assignmentId',
  <StudentAssignmentPendingPage />
)

addTwoColumnRoute(
  'assignmentWithRoom',
  '/assignment/:sectionId/:assignmentId/:roomStateId',
  homePageOrStudentLibrary
)

addTwoColumnRoute(
  'assignment',
  '/assignment/:sectionId/:assignmentId',
  homePageOrStudentLibrary
)

//todo(alex): should authors/editors get here?
addTwoColumnRoute(
  'instructorLibrary',
  '/instructor/library',
  <RoleGuard
    roles={[
      UserProfileRole.instructor,
      UserProfileRole.ta,
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.editor,
      UserProfileRole.author,
    ]}
  >
    <InstructorLoader page="instructorLibrary" />
  </RoleGuard>
)

//todo(alex): should authors/editors get here?
addTwoColumnRoute(
  'instructorSlideDeck', // instructor, ta
  '/instructor/slide-deck/:slideDeckId',
  <RoleGuard
    roles={[
      UserProfileRole.instructor,
      UserProfileRole.ta,
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.editor,
      UserProfileRole.author,
    ]}
  >
    <InstructorLoader page="instructorSlideDeck" />
  </RoleGuard>
)

addTwoColumnRoute(
  'instructorStudentView',
  '/instructor/home',
  <RoleGuard
    roles={[
      UserProfileRole.instructor,
      UserProfileRole.ta,
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.editor,
      UserProfileRole.author,
    ]}
  >
    {homePageOrStudentLibrary}
  </RoleGuard>
)
addTwoColumnRoute(
  'instructorClass', // instructor, ta
  '/instructor/classes/:id',
  <RoleGuard
    roles={[
      UserProfileRole.instructor,
      UserProfileRole.ta,
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.author,
      UserProfileRole.editor,
    ]}
  >
    <InstructorLoader page="instructorClass" />
  </RoleGuard>
)
addTwoColumnRoute(
  'instructorClassAssignment', // instructor, ta
  '/instructor/classes/:id/:assignmentId',
  <RoleGuard
    roles={[
      UserProfileRole.instructor,
      UserProfileRole.ta,
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.author,
      UserProfileRole.editor,
    ]}
  >
    <InstructorLoader page="instructorClassAssignment" />
  </RoleGuard>
)
addTwoColumnRoute(
  'instructorClasses',
  '/instructor/classes',
  <RoleGuard
    roles={[
      UserProfileRole.instructor,
      UserProfileRole.ta,
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.author,
      UserProfileRole.editor,
    ]}
  >
    <InstructorLoader page="instructorClasses" />
  </RoleGuard>
)
addTwoColumnRoute(
  'instructorAssistants',
  '/instructor/assistants',
  <RoleGuard
    roles={[
      UserProfileRole.instructor,
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.author,
      UserProfileRole.editor,
    ]}
  >
    <InstructorLoader page="instructorAssistants" />
  </RoleGuard>
)
addTwoColumnRoute(
  'instructorTeachingPlan',
  '/instructor/teaching_plan/:catalogId/:teachingPlanId',
  <RoleGuard
    roles={[
      UserProfileRole.instructor,
      UserProfileRole.ta,
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.author,
      UserProfileRole.editor,
    ]}
  >
    <InstructorLoader page="instructorTeachingPlan" />
  </RoleGuard>
)

addTwoColumnRoute(
  'instructorOrgPicker',
  '/instructor/org-select/:institutionId',
  <RoleGuard
    roles={[
      UserProfileRole.instructor,
      UserProfileRole.ta,
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.author,
      UserProfileRole.editor,
    ]}
  >
    <InstructorLoader page="instructorOrgPicker" />
  </RoleGuard>
)

addTwoColumnRoute(
  'adminDashboard',
  '/admin/dashboard',
  <RoleGuard roles={[UserProfileRole.admin, UserProfileRole.corre]}>
    <AdminDashboardLoader />
  </RoleGuard>
)

addTwoColumnRoute(
  'adminTeachingPlan',
  '/admin/catalogs/:catalogId/teaching-plans/:teachingPlanId',
  <RoleGuard
    roles={[
      UserProfileRole.admin,
      UserProfileRole.editor,
      UserProfileRole.corre,
    ]}
  >
    <AdminTeachingPlanLoader />
  </RoleGuard>
)

addTwoColumnRoute(
  'adminLibrary',
  '/admin/library',
  <RoleGuard
    roles={[
      UserProfileRole.admin,
      UserProfileRole.author,
      UserProfileRole.editor,
    ]}
  >
    <AdminLibraryLoader />
  </RoleGuard>
)
addTwoColumnRoute(
  'adminCatalogs',
  '/admin/catalogs',
  <RoleGuard
    roles={[
      UserProfileRole.admin,
      UserProfileRole.editor,
      UserProfileRole.corre,
    ]}
  >
    <AdminCatalogsLoader />
  </RoleGuard>
)
addTwoColumnRoute(
  'adminCatalog',
  '/admin/catalogs/:catalogId',
  <RoleGuard
    roles={[
      UserProfileRole.admin,
      UserProfileRole.editor,
      UserProfileRole.corre,
    ]}
  >
    <AdminCatalogsLoader />
  </RoleGuard>
)

addTwoColumnRoute(
  'adminSlideDeck',
  '/admin/slide-deck/:slideDeckId',
  <RoleGuard
    roles={[
      UserProfileRole.admin,
      UserProfileRole.author,
      UserProfileRole.editor,
    ]}
  >
    <AdminLibraryLoader />
  </RoleGuard>
)

addTwoColumnRoute(
  'adminUsers',
  '/admin/users',
  <RoleGuard roles={[UserProfileRole.admin, UserProfileRole.corre]}>
    <AdminUsersLoader />
  </RoleGuard>
)

addTwoColumnRoute(
  'adminDemos',
  '/admin/demos',
  <RoleGuard roles={[UserProfileRole.admin, UserProfileRole.corre]}>
    <AdminDemosLoader />
  </RoleGuard>
)

addTwoColumnRoute(
  'adminInstitutions',
  '/admin/institutions',
  <RoleGuard roles={[UserProfileRole.admin, UserProfileRole.corre]}>
    <AdminInstitutionsLoader />
  </RoleGuard>
)

addTwoColumnRoute(
  'adminInspector',
  '/admin/inspector',
  <SuperAdminRoleGuard privilege={SuperAdminPrivilege.roomInspector}>
    <AdminInspectorLoader />
  </SuperAdminRoleGuard>
)

addTwoColumnRoute(
  'adminInspectorRoom',
  '/admin/inspector/room/:roomId',
  <SuperAdminRoleGuard privilege={SuperAdminPrivilege.roomInspector}>
    <AdminInspectorLoader />
  </SuperAdminRoleGuard>
)

addTwoColumnRoute(
  'organizations',
  '/organizations',
  <RoleGuard
    roles={[
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.instructor,
    ]}
  >
    <OrganizationsLoader />
  </RoleGuard>
)

addTwoColumnRoute(
  'organization',
  '/organizations/:organizationId',
  <RoleGuard
    roles={[
      UserProfileRole.admin,
      UserProfileRole.corre,
      UserProfileRole.instructor,
    ]}
  >
    <OrganizationsLoader />
  </RoleGuard>
)

addTwoColumnRoute('profile', '/profile', <ProfilePage />)

if (environment() !== Environment.production) {
  const DesignSystemEntrypoint = lazy(
    () => import('pages/design-system/DesignSystemPage')
  )
  const designSystemMount = (
    <Suspense fallback={<div>Loading...</div>}>
      <DesignSystemEntrypoint />
    </Suspense>
  )

  addTwoColumnRoute('designSystem', '/design-system', designSystemMount)
}

export function getRouteName(route: RouteType | undefined) {
  if (!route) return 'home'
  return route.title
}

export function getSubRouteComponent(routeName: string | undefined) {
  return subRoutes[routeName || 'home']
}

export function buildUrl(
  routeTitle: string,
  params: QueryParams,
  queryParams: QueryParams
) {
  const route = routes[routeTitle]

  if (!route) {
    throw new Error(`Route ${routeTitle} not found`)
  }

  let path = route.path
  for (const key in params) {
    const value = params[key]
    if (value !== undefined) {
      path = path.replace(`:${key}`, value.toString())
    }
  }

  const actualQueryParams: Record<string, string> = {}
  for (const key in queryParams) {
    const value = queryParams[key]
    if (value !== undefined) {
      actualQueryParams[key] = value.toString()
    }
  }

  const query = new URLSearchParams(actualQueryParams)

  const queryStr = query.toString()

  if (queryStr) {
    path += '?' + queryStr
  }

  return path
}
