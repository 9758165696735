export const BellIcon = ({
  color = 'currentColor',
  size = 24,
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M9.87522 12.375C9.87522 12.8723 9.67768 13.3492 9.32605 13.7008C8.97442 14.0525 8.4975 14.25 8.00022 14.25C7.50294 14.25 7.02603 14.0525 6.6744 13.7008C6.32277 13.3492 6.12522 12.8723 6.12522 12.375M8.45085 3.62689L7.5346 3.62501C5.4446 3.62001 3.63022 5.31814 3.61585 7.37501V9.74376C3.61585 10.2375 3.55335 10.7194 3.28397 11.13L3.1046 11.4038C2.83147 11.8188 3.12522 12.375 3.61585 12.375H12.3846C12.8752 12.375 13.1683 11.8188 12.8958 11.4038L12.7165 11.13C12.4477 10.7194 12.3846 10.2369 12.3846 9.74314V7.37564C12.3596 5.31814 10.5408 3.63189 8.45085 3.62689Z" />
      <path d="M8 1.75C8.33152 1.75 8.64946 1.8817 8.88388 2.11612C9.1183 2.35054 9.25 2.66848 9.25 3V3.625H6.75V3C6.75 2.66848 6.8817 2.35054 7.11612 2.11612C7.35054 1.8817 7.66848 1.75 8 1.75Z" />
    </svg>
  )
}
