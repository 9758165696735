import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { FullPageSpinner } from 'components/Spinner'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { PromotionSelectionWidget } from 'pages/instructor/classes/PromotionSelectionWidget'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const SectionActivationScreen = observer(
  function SectionActivationScreen() {
    const sectionCubit = useInstructorSectionCubit()

    useEffect(() => {
      sectionCubit.markAsNotStarted()
    }, [sectionCubit])

    return <FullPageSpinner />
  }
)

export const SectionPromotionConfiguration = observer(
  function SectionConfiguration({
    setWantsInvoicing,
  }: {
    setWantsInvoicing: (value: boolean) => void
  }) {
    const sectionCubit = useInstructorSectionCubit()
    const section = sectionCubit.section
    const user = useBreakoutUser()
    const { t } = useTranslation()

    const userPromotions = user.userPromotions.models
    const [selectedUserPromotionIds, setSelectedUserPromotionIds] = useState<
      string[]
    >([])

    const onSubmit = useCallback(
      async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const promotions = userPromotions?.filter((p) =>
          selectedUserPromotionIds.includes(p.id)
        )

        if (promotions && promotions.length > 0) {
          await sectionCubit.redeemPromotions(section.id, promotions)
          // when promotions are redeemed, we know we can skip invoicing
          setWantsInvoicing(false)
        }

        await sectionCubit.markAsNotStarted()
      },
      [
        selectedUserPromotionIds,
        userPromotions,
        sectionCubit,
        setWantsInvoicing,
        section.id,
      ]
    )

    return (
      <div className="section flex min-h-screen w-full flex-col items-start justify-center">
        <div className="ml-[5vw] max-w-[400px]">
          <div className="mb-5">
            <h1 className="text-headline-large">
              {t('lti.configuration.apply_promotion')}
            </h1>
            <div className="text-body-large">
              {t('lti.configuration.apply_promotion_description')}
            </div>
          </div>
          <form onSubmit={onSubmit} className="flex flex-col gap-5">
            {user.userPromotions.isLoaded && (
              <PromotionSelectionWidget
                onChange={setSelectedUserPromotionIds}
                kind="tertiary"
              />
            )}
            <BreakoutButton
              kind="accent"
              type="submit"
              size="medium"
              className="bg-fixed-accent-color"
            >
              {t('lti.configuration.continue')}
            </BreakoutButton>
          </form>
        </div>
      </div>
    )
  }
)
