import type { LazyExoticComponent, ReactNode } from 'react'
import { lazy, Suspense } from 'react'

type EmptyFunction = () => null

let NewVersionBanner:
  | LazyExoticComponent<() => ReactNode | null>
  | EmptyFunction = () => null

if (process.env.DEBUG_MODE !== 'true') {
  NewVersionBanner = lazy(() => import('./NewVersionBanner'))
}

export function NewVersionBannerEntry() {
  return (
    <Suspense>
      <NewVersionBanner />
    </Suspense>
  )
}
