import { type RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { memo } from 'react'
import { type useTranslation } from 'react-i18next'

export const ScheduledDayOfWeekAtTime = memo(function ScheduledDayOfWeekAtTime({
  scheduledAt,
  t,
  placeholder,
}: {
  scheduledAt: RoomState['data']['scheduledAt'] | null
  t: ReturnType<typeof useTranslation>['t']
  placeholder?: string
}) {
  if (!scheduledAt) return <>{placeholder ?? ''}</>

  // undefined to use the default browser locale
  const dayFormatter = new Intl.DateTimeFormat(undefined, { weekday: 'long' })
  const timeFormatter = new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  const dayOfWeek = dayFormatter.format(scheduledAt)
  const timeOfDay = timeFormatter.format(scheduledAt)

  return (
    <>
      {t('student_assignment.join_dialog_v2.day_at_time', {
        timeOfDay,
        dayOfWeek,
      })}
    </>
  )
})

export const ScheduledDayOfMonthOnYear = memo(
  function ScheduledDayOfMonthOnYear({
    scheduledAt,
    placeholder,
  }: {
    scheduledAt: RoomState['data']['scheduledAt'] | null
    placeholder?: string
  }) {
    if (!scheduledAt) return <>{placeholder ?? ''}</>

    // undefined to use the default browser locale
    const dateFormatter = new Intl.DateTimeFormat(undefined, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
    const fullDate = dateFormatter.format(scheduledAt)

    return <>{fullDate}</>
  }
)
