import { InfoIcon } from 'components/icons/Info'
import { Spinner } from 'components/Spinner'
import { useMediaPermissions } from 'hooks/av-test'
import { PermissionsErrorBanner } from 'pages/av-test/PermissionErrorBanner'
import { lazy, useState } from 'react'
import { useTranslationTyped } from 'i18n/i18n'

const NewAvTest = lazy(() => import('pages/av-test/NewAvTest'))

export const ProfileAvSection = () => {
  const { tt } = useTranslationTyped()

  return (
    <div className="flex h-full min-w-[20rem] flex-col pb-6">
      <h1 className="mb-1 text-headline-large">{tt.profile.av()}</h1>
      <div className="mb-3 text-body-large text-core-on-tertiary">
        {tt.profile.av_description()}
      </div>
      <AvPermissionsCheck>
        <div className="mx-auto mt-2 flex-1">
          <NewAvTest permissionState="granted" />
        </div>
      </AvPermissionsCheck>
    </div>
  )
}

const AvPermissionsCheck = ({ children }: { children: React.ReactNode }) => {
  const { tt } = useTranslationTyped()
  const [showPermissionsBanner, setShowPermissionsBanner] = useState(false)
  const [permissionState] = useMediaPermissions({
    onDenied: () => setShowPermissionsBanner(true),
  })

  if (permissionState === 'waiting') {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Spinner size={3} />
      </div>
    )
  }

  return (
    <>
      {showPermissionsBanner && (
        <PermissionsErrorBanner
          onDismiss={() => setShowPermissionsBanner(false)}
        />
      )}
      {permissionState !== 'granted' && (
        <div className="mt-6 flex flex-1 flex-col items-center justify-center rounded-3xl bg-surface px-6">
          <InfoIcon className="fill-fixed-accent-color" size={32} />
          <div className="mt-1 text-headline-small text-core-on-tertiary">
            {tt.profile.denied_permissions()}
          </div>
          <div className="mt-2 text-body-large text-core-on-tertiary">
            {tt.profile.denied_permissions_description()}
          </div>
        </div>
      )}
      {permissionState === 'granted' && children}
    </>
  )
}
