import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { type StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import {
  BreakoutUserAvatar,
  BreakoutUserAvatarStack,
} from 'components/breakout/BreakoutUserAvatar'
import { useBreakoutUser } from 'hooks/profile'
import { memo, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ScheduleRoomStateButton } from './ScheduleRoomStateButton'
import { CalendarIcon } from 'components/icons/Calendar'
import {
  type ChatMessage,
  type SystemMessage,
} from '@breakoutlearning/firebase-repository/types'
import classNames from 'classnames'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { SendIcon } from 'components/icons/Send'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { Shield } from 'components/icons/Shield'
import { CheckIcon } from 'components/icons/Check'
import { DoubleCheckIcon } from 'components/icons/DoubleCheck'
import { type PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import { RoomStateStatus } from '@breakoutlearning/firebase-repository/models/RoomState'
import { InfoIcon } from 'components/icons/Info'
import { TriangleAlertIcon } from 'components/icons/TriangleAlert'
import { HyperlinkedText } from 'components/breakout/HyperlinkedText'
import {
  ScheduledDayOfMonthOnYear,
  ScheduledDayOfWeekAtTime,
} from '../ScheduledTimeParts'

export const StudentAssignmentChatDialog = observer(
  function StudentAssignmentChatDialog({
    cubit,
  }: {
    cubit: StudentAssignmentCubit
  }) {
    const { roomState } = cubit
    const currentUser = useBreakoutUser()
    const { t } = useTranslation()

    const isStudentInRoom = roomState.users.some(
      (u) => u.id === currentUser.uid
    )

    const canManageAssignment = useMemo(() => {
      return (
        currentUser.isCorre ||
        cubit.currentUserIsInstructor ||
        currentUser.isTaToInstructor(cubit.section.data.instructorUserId)
      )
    }, [
      currentUser,
      cubit.currentUserIsInstructor,
      cubit.section.data.instructorUserId,
    ])

    const canSchedule =
      canManageAssignment ||
      (isStudentInRoom &&
        (roomState.groupLeaderUserIds.length === 0 ||
          roomState.groupLeaderUserIds.includes(currentUser.uid)))

    const chatBoxScrollRef = useRef<boolean>(false)
    const textInputRef = useRef<HTMLInputElement>(null)

    const showLargeScheduleButton = canSchedule && !roomState.scheduledAtDate

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const text = textInputRef.current?.value
      if (!text) return

      cubit.chat.sendMessage(text)
      textInputRef.current.value = ''
    }

    const chatDisabled: boolean = useMemo(() => {
      switch (cubit.roomStateStatus) {
        case RoomStateStatus.live:
        case RoomStateStatus.scheduled:
        case RoomStateStatus.mustSchedule:
        case RoomStateStatus.suspended:
          return false
        case RoomStateStatus.completed:
        case RoomStateStatus.abandoned:
        case RoomStateStatus.canceled:
        case RoomStateStatus.expired:
          return true
      }
    }, [cubit.roomStateStatus])

    return (
      <Dialog
        testId="rooms-dialog"
        size="xl"
        className="h-[700px] w-[1200px]"
        innerClassName="flex h-full max-w-[1000px] mx-auto"
      >
        <DialogCloseButton />
        <div className="flex h-full w-full flex-col gap-4 px-9">
          <div className="flex flex-row items-center justify-between px-7">
            {/* room name & user avatars */}
            <div className="flex flex-col items-start gap-1">
              <h2 className="text-title-large">{roomState.roomStateName}</h2>
              <BreakoutUserAvatarStack
                users={roomState.users}
                radius={12}
                spacing={-2}
              />
            </div>
            {/* scheduled time and schedule button */}
            <div className="flex flex-row gap-2">
              <div className="flex flex-col">
                <strong className="text-title-large">
                  <ScheduledDayOfWeekAtTime
                    scheduledAt={cubit.roomState.data.scheduledAt}
                    t={t}
                  />
                </strong>
                <strong className="text-body-medium text-on-surface-var">
                  <ScheduledDayOfMonthOnYear
                    scheduledAt={cubit.roomState.data.scheduledAt}
                  />
                </strong>
              </div>

              <BreakoutTooltip
                enabled={!canSchedule}
                content={
                  <div className="flex flex-col items-center justify-center gap-1.5 rounded-lg bg-on-surface-var px-4 py-2 text-body-medium text-core-on-primary">
                    <span>{t('student_assignment.group_owner_schedule')}</span>
                    {cubit.roomState.groupLeaderUserIds.length > 0 &&
                      cubit.roomState.groupLeader.isNotEmpty && (
                        <div className="flex flex-row items-center justify-center gap-1">
                          <BreakoutUserAvatar
                            radius={10}
                            user={cubit.roomState.groupLeader}
                          />
                          <span>{cubit.roomState.groupLeader.fullName}</span>
                          <Shield
                            size={15}
                            className="stroke-fixed-accent-color"
                          />
                        </div>
                      )}
                  </div>
                }
              >
                <div>
                  {!showLargeScheduleButton && (
                    <ScheduleRoomStateButton
                      useInlineDialog={true}
                      kind="secondary"
                      icon={<CalendarIcon size={15} />}
                      cubit={cubit}
                      size="small"
                      disabled={!canSchedule}
                    />
                  )}
                  {showLargeScheduleButton && (
                    <ScheduleRoomStateButton
                      useInlineDialog={true}
                      kind="accent"
                      size="small"
                      cubit={cubit}
                      loadingText={t('student_assignment.set_meeting_time')}
                      icon={<TriangleAlertIcon size={15} />}
                      disabled={!canSchedule}
                    >
                      {t('student_assignment.set_meeting_time')}
                    </ScheduleRoomStateButton>
                  )}
                </div>
              </BreakoutTooltip>
            </div>
          </div>
          {/* need background and border color for various browsers */}
          <hr className="border-fixed-accent-color bg-fixed-accent-color" />

          {/*below div fills remaining space to force chat messages bottom
               negative margin is present to make gap appear normal, not 2x tall (extra div)*/}
          <div className="-my-2 flex-grow" />
          <div className="overflow-y-auto px-7">
            {cubit.chat.messages.map((message, index) => {
              if (message.isSystemMessage && message.systemMessagePayload) {
                const user =
                  'userId' in message.systemMessagePayload
                    ? cubit.repository.userStore.getUser(
                        message.systemMessagePayload.userId
                      )
                    : undefined

                return (
                  <SystemMessage
                    key={index}
                    systemMessage={message.systemMessagePayload}
                    chatMessage={message}
                    cubit={cubit}
                    user={user?.fullName ? user : undefined}
                    mountedRef={chatBoxScrollRef}
                    isLast={index === cubit.chat.messages.length - 1}
                  />
                )
              }

              return (
                <ChatMessageBox
                  key={index}
                  index={index}
                  message={message}
                  isLast={index === cubit.chat.messages.length - 1}
                  mountedRef={chatBoxScrollRef}
                  cubit={cubit}
                  nextMessageAuthorId={
                    index + 1 < cubit.chat.messages.length
                      ? cubit.chat.messages[index + 1].authorId
                      : null
                  }
                />
              )
            })}
          </div>
          {!chatDisabled && (
            <form
              className="flex w-full flex-row gap-3"
              onSubmit={onFormSubmit}
            >
              <BreakoutTextInput
                kind="secondary"
                ref={textInputRef}
                placeholder={t('student_assignment.type_messages_to_group')}
                className="w-full"
                name="chat-message"
              />
              <BreakoutButton
                size="medium"
                className="aspect-square"
                icon={<SendIcon size={20} />}
                type="submit"
                kind="accent"
              />
            </form>
          )}
          {chatDisabled && (
            <div className="flex w-full flex-row items-center justify-center gap-1 pt-4 text-core-error">
              <InfoIcon size={15} />
              <strong className="text-body-medium">
                {t('student_assignment.join_dialog_v2.chat_disabled')}
              </strong>
            </div>
          )}
        </div>
      </Dialog>
    )
  }
)

const SystemMessage = memo(function SystemMessage({
  systemMessage,
  chatMessage,
  user,
  cubit,
  mountedRef,
  isLast,
}: {
  systemMessage: SystemMessage
  chatMessage: ChatMessage
  cubit: StudentAssignmentCubit
  mountedRef: React.MutableRefObject<boolean>
  isLast: boolean
  user?: PublicUser
}) {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const currentUser = useBreakoutUser()
  const notifications = currentUser.notifications

  // won't render until user loaded (if we need it)
  const canScroll =
    (['join', 'leave'].includes(systemMessage.type) && user?.fullName) ||
    systemMessage.type === 'reschedule'

  useEffect(() => {
    // Let the notification system know we've seen this message
    // so that it can hide the notification
    notifications.markChatMessageAsRead(chatMessage.id)

    if (!isLast || !canScroll) return

    ref.current?.scrollIntoView?.({
      // the first time we render, we want to jump to the bottom instantly
      // after that, we want to smoothly scroll to the bottom
      behavior: mountedRef.current.valueOf() ? 'smooth' : 'instant',
      block: 'end',
    })
    mountedRef.current = true
    cubit.chat.markAllMessagesRead()
    cubit.chat.markSeen(chatMessage)
  }, [
    isLast,
    mountedRef,
    cubit,
    cubit.chat,
    chatMessage,
    notifications,
    canScroll,
    systemMessage.type,
  ])

  if (systemMessage instanceof Error) return null

  switch (systemMessage.type) {
    case 'join':
    case 'leave': {
      if (!user || !user.fullName) return null
      return (
        <div
          ref={ref}
          className={classNames(
            'flex w-full flex-row items-center justify-center gap-1',
            {
              'mb-4': !isLast,
            }
          )}
        >
          <div className="h-0.5 flex-grow rounded-xl bg-surface-dim" />
          <div className="flex flex-row gap-1 px-4">
            <BreakoutUserAvatar user={user} radius={10} />
            <strong
              className={classNames('text-body-medium', {
                'text-core-error': systemMessage.type === 'leave',
              })}
            >
              {systemMessage.type === 'join'
                ? t('student_assignment.join_dialog_v2.system_messages.join', {
                    name: user.fullName,
                  })
                : t('student_assignment.join_dialog_v2.system_messages.leave', {
                    name: user.fullName,
                  })}
            </strong>
          </div>
          <div className="h-0.5 flex-grow rounded-xl bg-surface-dim" />
        </div>
      )
    }
    case 'reschedule': {
      return (
        <div
          ref={ref}
          className="flex w-full flex-row items-center justify-center gap-1"
        >
          <div className="h-0.5 flex-grow rounded-xl bg-surface-dim" />
          <div className="flex flex-col items-center justify-center gap-1 px-4 py-3 text-body-medium text-core-error">
            <strong>
              {t(
                'student_assignment.join_dialog_v2.system_messages.reschedule'
              )}
            </strong>
            <strong className="font-bold">
              {cubit.chat.formatDate(systemMessage.newScheduledAt)}
            </strong>
          </div>
          <div className="h-0.5 flex-grow rounded-xl bg-surface-dim" />
        </div>
      )
    }
  }
})

const ChatMessageBox = observer(function ChatMessageBox({
  index,
  message,
  isLast,
  mountedRef,
  cubit,
  nextMessageAuthorId,
}: {
  index: number
  message: ChatMessage
  isLast: boolean
  mountedRef: React.MutableRefObject<boolean>
  cubit: StudentAssignmentCubit
  nextMessageAuthorId: string | null
}) {
  const ref = useRef<HTMLDivElement>(null)
  const isCurrentUserMessage = message.isCurrentUserMessage
  const currentUser = useBreakoutUser()
  const notifications = currentUser.notifications

  enum ReadBy {
    all,
    some,
    none,
  }

  const formattedTime = message.timestamp.toLocaleTimeString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })

  const { t } = useTranslation()

  // ta's/corre will always show up as left room if we let them send messages
  // as we can't perform any role checks
  const authorIsInRoom = useMemo(() => {
    if (isCurrentUserMessage) return true
    const { hiddenUserIds, userIds } = cubit.roomState.data

    return [
      ...hiddenUserIds,
      ...userIds,
      cubit.section.data.instructorUserId,
    ].includes(message.authorId)
  }, [
    cubit.roomState.data,
    cubit.section.data.instructorUserId,
    message.authorId,
    isCurrentUserMessage,
  ])

  const readBy = useMemo(() => {
    if (!isCurrentUserMessage) return ReadBy.none
    const seenBy = message.seenBy || {}

    // remove the author from the list of users
    const roomUserIds = cubit.roomState.userIds.filter(
      (uid) => uid !== message.authorId
    )

    const userIdsSet = new Set(roomUserIds)

    for (const userId of roomUserIds) {
      if (!seenBy[userId]) continue
      userIdsSet.delete(userId)
    }

    if (!userIdsSet.size) return ReadBy.all
    if (userIdsSet.size === roomUserIds.length) return ReadBy.none
    return ReadBy.some
  }, [
    ReadBy.all,
    ReadBy.none,
    ReadBy.some,
    cubit.roomState.userIds,
    isCurrentUserMessage,
    message.authorId,
    message.seenBy,
  ])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // mark as seen when visible
          cubit.chat.markSeen(message)

          // Let the notification system know we've seen this message
          // so that it can hide the notification
          notifications.markChatMessageAsRead(message.id)

          // Stop observing once marked as
          observer.disconnect()
        }
      },
      // mostly visible in the viewport
      // note: this was originally 1.0 (fully visible)
      // but the last element sometimes returns a ratio of ~0.99
      { threshold: 0.95 }
    )

    if (ref.current) observer.observe(ref.current)

    return () => observer.disconnect()
  }, [cubit.chat, message, message.id, notifications])

  useEffect(() => {
    if (!isLast) return

    ref.current?.scrollIntoView?.({
      // the first time we render, we want to jump to the bottom instantly
      // after that, we want to smoothly scroll to the bottom
      behavior: mountedRef.current.valueOf() ? 'smooth' : 'instant',
      block: 'end',
    })
    mountedRef.current = true

    cubit.chat.markAllMessagesRead()
  }, [isLast, mountedRef, cubit, cubit.chat])

  return (
    <div
      ref={ref}
      key={index}
      className={classNames('flex w-full flex-col gap-1', {
        'items-end': isCurrentUserMessage,
        'items-start': !isCurrentUserMessage,
        'text-right': isCurrentUserMessage,
        'mb-4': !isLast,
      })}
    >
      {/* message box */}
      <div
        className={`${
          isCurrentUserMessage ? 'bg-fixed-accent-color' : 'bg-surface-dim'
        } w-full max-w-[80%] break-words rounded-xl px-4 py-3`}
      >
        {/* message */}
        <p className="text-body-medium">
          <HyperlinkedText message={message.text} />
        </p>
        <div
          className={classNames(
            'mt-1 flex w-full flex-row items-center gap-1',
            {
              'justify-end': isCurrentUserMessage,
              'justify-start': !isCurrentUserMessage,
            }
          )}
        >
          {/* timestamp and read checks */}
          <strong className="text-body-small">{formattedTime}</strong>
          {isCurrentUserMessage && readBy === ReadBy.none && (
            <CheckIcon size={14} />
          )}
          {isCurrentUserMessage && readBy === ReadBy.some && (
            <DoubleCheckIcon size={14} />
          )}
          {isCurrentUserMessage && readBy === ReadBy.all && (
            <DoubleCheckIcon size={14} className="stroke-core-success" />
          )}
        </div>
      </div>
      {/* author name/ avatar */}
      {nextMessageAuthorId !== message.authorId && (
        <div
          className={classNames('flex w-full flex-row items-center gap-1', {
            'justify-start': !isCurrentUserMessage,
            'justify-end': isCurrentUserMessage,
          })}
        >
          <BreakoutUserAvatar user={message.author} radius={10} />
          <strong className="text-label-medium">
            {message.author.fullName}
          </strong>
          {!authorIsInRoom && (
            <strong className="text-label-small text-core-error">
              {t('student_assignment.left_the_group')}
            </strong>
          )}
        </div>
      )}
    </div>
  )
})
