import classNames from 'classnames'
import { ClockIcon } from 'components/icons/Clock'
import { DateTime } from 'luxon'
import { useCallback, useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import 'styles/datetime-picker.css'
import { BreakoutButton } from './BreakoutButton'
import type {
  BreakoutDateTimeProps,
  DateTimeResolution,
} from './BreakoutDateTimeInput'
import { BreakoutSelect } from './BreakoutSelect'
import { useTranslationTyped } from 'i18n/i18n'

type Props = Omit<BreakoutDateTimeProps, 'onChange'> & {
  onChange: (value: DateTime) => void
  /**
   * Does not affect logic, only what the user sees at first.
   */
  initialView?: DateTime
  force12h?: boolean
  resolution: DateTimeResolution
}

export function BreakoutDateTimePicker({
  value,
  onChange,
  min,
  max,
  hideNowButton: _hideNowButton,
  initialView,
  force12HourMode = false,
  resolution,
}: Props) {
  const { tt } = useTranslationTyped()
  const initialValueRef = useRef(value)

  // Helper functions to determine behavior based on resolution
  const hideTime = resolution === '1min'

  const getResolutionMinutes = (resolution: DateTimeResolution) => {
    switch (resolution) {
      case '1min':
        return 1
      case '15min':
        return 15
      case '1day':
        return 15 // Value doesn't matter since time is hidden
    }
  }

  const resolutionMinutes = getResolutionMinutes(resolution)

  const localeUses12h =
    force12HourMode === true
      ? true
      : Boolean(
          DateTime.now()
            .toLocaleString(DateTime.TIME_SIMPLE)
            .match(/(am|pm|AM|PM)/)
        )

  const [view, setView] = useState<DateTime>(() => {
    const initialDateTime =
      initialValueRef.current ?? initialView ?? DateTime.now()
    return (
      nearestAllowedTime({
        dateTime: initialDateTime,
        min,
        max,
        resolution,
      }) ?? initialDateTime
    )
  })

  const [selected, setSelected] = useState<DateTime | undefined>(
    initialValueRef.current ?? undefined
  )

  // Chrome's implementation ignores hours for min/max settings.
  const minDate = min ? min.startOf('day') : undefined
  const maxDate = max ? max.endOf('day') : undefined

  let startWeek = view
    .startOf('month')
    .startOf('week', { useLocaleWeeks: true })
  let endWeek = view.endOf('month').endOf('week', { useLocaleWeeks: true })

  // hacktime!
  // useLocaleWeeks depends on new Intl.Locale(navigator.language).weekInfo
  // which is not supported in Firefox AT ALL. So we need to do this hack
  // to make US happy.
  const locale = startWeek.locale
  const supportsWeekInfo = Object.prototype.hasOwnProperty.call(
    Intl.Locale.prototype,
    'weekInfo'
  )
  if (!supportsWeekInfo && locale === 'en-US') {
    // Force US week format
    startWeek = view
      .startOf('month')
      .plus({ days: 1 })
      .startOf('week')
      .minus({ days: 1 })
    endWeek = view
      .endOf('month')
      .plus({ days: 1 })
      .endOf('week')
      .minus({ days: 1 })
  }

  const weeks = Array.from(
    { length: endWeek.diff(startWeek, 'weeks').weeks + 1 },
    (_, i) => startWeek.plus({ weeks: i })
  )

  const firstWeek = weeks[0]

  const { t } = useTranslation()
  const update = useCallback(
    (newValue: DateTime) => {
      const roundedDateTime = nearestAllowedTime({
        dateTime: newValue,
        min,
        max,
        resolution,
      })

      if (!roundedDateTime) return

      setSelected(roundedDateTime)
    },
    [min, max, resolution]
  )

  const handleSaveChange = (date: DateTime<boolean>) => {
    const cleanDate = date.set({
      second: 0,
      millisecond: 0,
    })

    onChange?.(cleanDate)
  }

  const timeOptions = useMemo(() => {
    const options: {
      key: string
      value: string
      label: string
      isUnchoosable?: boolean
    }[] = []

    const currentDate = view.startOf('day')
    const minTime =
      min && min.startOf('day').equals(currentDate) ? min : currentDate
    const maxTime =
      max && max.startOf('day').equals(currentDate)
        ? max
        : currentDate.endOf('day')

    Array.from({ length: 24 }).forEach((_, i) => {
      const hour = i
      const intervals = resolutionMinutes === 1 ? 60 : 4
      Array.from({ length: intervals }).forEach((_, j) => {
        const minute = resolutionMinutes === 1 ? j : j * 15
        const timeToCheck = currentDate.set({ hour, minute })

        if (timeToCheck >= minTime && timeToCheck <= maxTime) {
          options.push(buildTimePickerOption(hour, minute, localeUses12h))
        }
      })
    })

    // Add selected time if it doesn't match the resolution
    if (selected) {
      const selectedMinute = selected.minute
      if (selectedMinute % resolutionMinutes !== 0) {
        options.push({
          ...buildTimePickerOption(
            selected.hour,
            selectedMinute,
            localeUses12h
          ),
          isUnchoosable: true,
        })
      }
    }

    options.sort((a, b) => a.value.localeCompare(b.value))

    return options
  }, [localeUses12h, selected, min, max, view, resolutionMinutes])

  const initialScrollIndexOverride = (() => {
    const targetTime = initialValueRef.current ?? initialView ?? DateTime.now()

    const roundedTime = nearestAllowedTime({
      dateTime: targetTime,
      min,
      max,
      resolution,
    })

    if (!roundedTime) return undefined

    return timeOptions.findIndex(
      (option) =>
        !option.isUnchoosable &&
        decodeTimePickerValue(option.value).hour === roundedTime.hour &&
        decodeTimePickerValue(option.value).minute === roundedTime.minute
    )
  })()

  const isEmptyDatePicker = min && max && max < min
  const hideNowButton = _hideNowButton || isEmptyDatePicker

  const selectedAriaLabel = selected
    ? tt.inputs.selected_date({
        date: selected.toFormat('cccc, LLLL d, yyyy'),
      })
    : undefined

  return (
    <div className="w-full select-none bg-core-tertiary">
      <div className="flex h-[300px] min-w-[300px] flex-row justify-between gap-5 md:h-[260px]">
        <div className="calendar justify flex h-full w-full flex-1 flex-col">
          <div className="justify- flex w-full flex-row items-center justify-between">
            <div className="px-2 text-label-large">
              {view.toFormat('LLL yyyy')}
            </div>
            <div>
              <div
                aria-live="assertive"
                aria-label={view.toFormat('LLLL yyyy')}
              />
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setView(view.minus({ months: 1 }))
                }}
                aria-label={tt.inputs.previous_month({
                  month: view.minus({ months: 1 }).toFormat('LLLL yyyy'),
                })}
                className="border border-core-tertiary px-2 py-1"
              >
                {'<'}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setView(view.plus({ months: 1 }))
                }}
                aria-label={tt.inputs.next_month({
                  month: view.plus({ months: 1 }).toFormat('LLLL yyyy'),
                })}
                className="border border-core-tertiary px-2 py-1"
              >
                {'>'}
              </button>
            </div>
          </div>
          <div aria-live="assertive" aria-label={selectedAriaLabel} />
          <div className="h-full w-full">
            <div className="grid h-full w-full grid-cols-7 text-body-large">
              {Array.from({ length: 7 }).map((_, i) => (
                <div key={i} className="p-2 text-center text-title-medium">
                  {firstWeek.plus({ days: i }).toFormat('ccc')[0]}
                </div>
              ))}

              {weeks.map((week) =>
                Array.from({ length: 7 }).map((_, i) => {
                  const day = week.plus({ days: i })
                  const current = selected?.toISODate() === day.toISODate()
                  const key = day.toISODate()

                  if (minDate && day < minDate) {
                    return (
                      <div
                        data-testid={`datetime-${key}`}
                        key={key}
                        className="cursor-default px-2.5 py-2 text-center opacity-25"
                      >
                        {day.day}
                      </div>
                    )
                  }
                  if (maxDate && day >= maxDate) {
                    return (
                      <div
                        data-testid={`datetime-${key}`}
                        key={key}
                        className="cursor-default px-2.5 py-2 text-center opacity-25"
                      >
                        {day.day}
                      </div>
                    )
                  }

                  return (
                    <div
                      key={key}
                      className={classNames(
                        'text-center',
                        day.month !== view.month && 'text-gray-500'
                      )}
                    >
                      <button
                        id={`datetime-${key}`}
                        data-testid={`datetime-${key}`}
                        aria-label={
                          current
                            ? selectedAriaLabel
                            : day.toFormat('cccc, LLLL d, yyyy')
                        }
                        className={classNames(
                          'w-full cursor-pointer rounded-xl px-2.5 py-2',
                          current && 'bg-core-primary text-core-on-primary'
                        )}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault()
                            e.stopPropagation()
                            const newValue = view.set({
                              year: day.year,
                              month: day.month,
                              day: day.day,
                            })
                            setView(newValue)
                            update(newValue)

                            // wait 100ms to allow the next day to render
                            // and then focus on it - this is necessary when
                            // we cross months
                            setTimeout(() => {
                              const nextDayKey = newValue.toISODate()
                              const nextDayId = `datetime-${nextDayKey}`

                              const nextDayElement =
                                document.getElementById(nextDayId)

                              if (nextDayElement) {
                                nextDayElement.focus()
                              }
                            }, 100)
                          }

                          // Keyboard navigation for accessibility
                          let nextDay: DateTime | undefined
                          if (e.key === 'ArrowRight')
                            nextDay = day.plus({ days: 1 })
                          if (e.key === 'ArrowLeft')
                            nextDay = day.minus({ days: 1 })
                          if (e.key === 'ArrowDown')
                            nextDay = day.plus({ weeks: 1 })
                          if (e.key === 'ArrowUp')
                            nextDay = day.minus({ weeks: 1 })

                          if (nextDay) {
                            const nextDayKey = nextDay.toISODate()
                            const nextDayId = `datetime-${nextDayKey}`

                            const nextDayElement =
                              document.getElementById(nextDayId)

                            if (nextDayElement) {
                              nextDayElement.focus()
                            }
                          }
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          const newValue = view.set({
                            year: day.year,
                            month: day.month,
                            day: day.day,
                          })
                          setView(newValue)
                          update(newValue)
                        }}
                      >
                        {day.day}
                      </button>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
      </div>
      {resolution !== '1day' && !isEmptyDatePicker && (
        <div className="mt-3 text-left" aria-live="assertive">
          <BreakoutSelect
            aria-label={
              selected
                ? tt.inputs.selected_time({
                    time: selected.toLocaleString(DateTime.TIME_SIMPLE),
                  })
                : tt.inputs.select_time()
            }
            testId="datetime-picker-timeselect"
            placeholder={tt.inputs.select_time()}
            kind="secondary"
            options={timeOptions}
            icon={<ClockIcon size={16} />}
            maxHeight={220}
            value={
              selected
                ? formatTimePickerValue(selected.hour, selected.minute)
                : undefined
            }
            onChange={(value) => {
              const { hour, minute } = decodeTimePickerValue(value)
              const newTime = view.set({ hour, minute })
              update(newTime)
              setView(newTime)
            }}
            initialScrollIndexOverride={initialScrollIndexOverride}
          />
        </div>
      )}

      <div className="mt-3 flex items-center justify-between">
        {hideNowButton && <div />}
        {!hideNowButton && (
          <BreakoutButton
            data-testid="datetime-picker-now"
            size="small"
            kind="secondary"
            onClick={() => {
              const roundedNow = nearestAllowedTime({
                dateTime: DateTime.now(),
                min,
                max,
                resolution,
              })
              if (!roundedNow) return
              update(roundedNow)
              setView(roundedNow)
            }}
          >
            {t('design_system.now')}
          </BreakoutButton>
        )}
        {/* Here we break with the usual practice of using `.toLocaleString(DateTime.DATETIME_FULL)` 
            to show the date and time more cleanly. This is for readability because the user is 
            adjusting both values separately. */}
        {selected && (
          <div className="inline-flex flex-wrap items-center text-center text-body-small md:text-body-medium">
            <div className="max-w-[150px] break-words px-1">
              {selected.toLocaleString(DateTime.DATE_FULL)}
            </div>
            {!hideTime && (
              <>
                {' - '}
                <div className="max-w-[150px] break-words px-1">
                  {selected.toLocaleString(DateTime.TIME_SIMPLE)}
                </div>
              </>
            )}
          </div>
        )}
        <BreakoutButton
          data-testid="datetime-picker-ok"
          size="small"
          onClick={() => handleSaveChange(selected!)}
          disabled={!selected}
        >
          {t('design_system.ok')}
        </BreakoutButton>
      </div>
    </div>
  )
}

function nearestAllowedTime({
  dateTime,
  min,
  max,
  resolution,
}: {
  dateTime: DateTime
  min?: DateTime
  max?: DateTime
  resolution: DateTimeResolution
}) {
  if (min && max && max < min) return null

  if (resolution === '1day') {
    dateTime = dateTime.startOf('day')

    if (min && min.startOf('day').equals(dateTime)) return min

    return dateTime
  }

  const resolutionMinutes = resolution === '1min' ? 1 : 15

  const minutes = dateTime.minute
  const roundedMinutes =
    resolutionMinutes === 1 ? minutes : Math.floor(minutes / 15) * 15

  let result = dateTime.set({
    minute: roundedMinutes,
    second: 0,
    millisecond: 0,
  })

  // If result is before min, round up to next interval
  if (min && result < min) {
    const diffMinutes = min.diff(result, 'minutes').minutes
    const intervalsToAdd = Math.ceil(diffMinutes / resolutionMinutes)
    result = result.plus({ minutes: intervalsToAdd * resolutionMinutes })
  }

  // If result is after max, round down to previous interval
  if (max && result > max) {
    const diffMinutes = result.diff(max, 'minutes').minutes
    const intervalsToSubtract = Math.ceil(diffMinutes / resolutionMinutes)
    result = result.minus({ minutes: intervalsToSubtract * resolutionMinutes })
  }

  return result
}

function decodeTimePickerValue(value: string) {
  const [hour, minute] = value.split('-').map(Number)
  return { hour, minute }
}

function formatTimePickerValue(hour: number, minute: number) {
  return `${hour < 10 ? '0' : ''}${hour}-${minute < 10 ? '0' : ''}${minute}`
}

function buildTimePickerOption(
  hour: number,
  minute: number,
  localeUses12h: boolean
) {
  const value = formatTimePickerValue(hour, minute)
  const paddedMinute = minute < 10 ? `0${minute}` : minute
  let label = `${hour}:${paddedMinute}`
  if (localeUses12h) {
    const am = hour < 12
    const newHour = am ? hour : hour - 12
    if (newHour === 0) {
      label = `12:${paddedMinute} ${am ? 'AM' : 'PM'}`
    } else {
      label = `${newHour}:${paddedMinute} ${am ? 'AM' : 'PM'}`
    }
  }

  return {
    key: label,
    value: value,
    label: label,
  }
}
