import * as React from 'react'
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react'
import classNames from 'classnames'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import type { FormFieldDefaults } from './types'
import { FormLabel } from './form/FormLabel'
import { FormError } from './form/FormError'
import { fixedForwardRef } from 'util/refs'
import { useTranslation } from 'react-i18next'
import { TrashCanIcon } from 'components/icons/TrashCan'

export type SelectOption<T> = {
  label: string | React.ReactNode
  value: T
  selectedLabel?: string | React.ReactNode
  metadata?: Record<string, string | number | boolean>
  key?: string | number
  isUnchoosable?: boolean // Useful if the currently selected option is a legacy option
}

interface BreakoutSelectProps<T> extends FormFieldDefaults<'select'> {
  options: SelectOption<T>[]
  loading?: boolean
  // kind secondary is the default and represents the "primary" style in figma
  // tertiary is the secondary style in figma
  kind?: 'secondary' | 'tertiary'
  onChange?: (value: T) => void
  fullWidth?: boolean
  inputClassName?: string
  labelComponent?: React.ReactNode
  icon?: React.ReactNode
  showChevron?: boolean
  allowClear?: boolean
  placeholder?: string
  emptyMessage?: string | React.ReactNode
  maxHeight?: number
  initialScrollIndexOverride?: number
}
const MAX_SELECT_BOX_HEIGHT = 500

export const BreakoutSelect = fixedForwardRef(function BreakoutSelect<
  T = string,
>(
  {
    disabled,
    loading = false,
    kind = 'tertiary',
    showChevron = true,
    name,
    label,
    labelComponent,
    labelClass,
    testId,
    options,
    icon,
    error,
    errorClass,
    required,
    value,
    inputClassName,
    placeholder,
    allowClear,
    onChange,
    maxHeight,
    fullWidth,
    className,
    'aria-label': ariaLabel,
    emptyMessage,
    initialScrollIndexOverride,
    ...rest
  }: BreakoutSelectProps<T>,
  fwdRef: React.ForwardedRef<HTMLSelectElement>
) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null)

  const scrollPositionRef = React.useRef(0)
  const floatingRef = React.useRef<HTMLDivElement | null>(null)

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: (open) => {
      if (disabled) return

      if (!open) {
        scrollPositionRef.current = floatingRef.current?.scrollTop ?? 0
      }

      setIsOpen(open)

      if (open) {
        requestAnimationFrame(() => {
          if (floatingRef.current) {
            floatingRef.current.scrollTop = scrollPositionRef.current
          }
        })
      }
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.min(availableHeight, maxHeight || MAX_SELECT_BOX_HEIGHT)}px`,
            maxWidth: fullWidth ? undefined : `${rects.reference.width}px`,
            minWidth: `${rects.reference.width}px`,
          })
        },
      }),
    ],
  })

  const { selectedIndex, selectedLabel } = React.useMemo(() => {
    const index = options.findIndex((option) => option.value === value)

    return {
      selectedIndex: index,
      // If the selected option has a selectedLabel, use that, otherwise use the label
      selectedLabel: options[index]?.selectedLabel
        ? options[index].selectedLabel
        : options[index]?.label,
    }
  }, [value, options])

  const listRef = React.useRef<Array<HTMLElement | null>>([])
  const isTypingRef = React.useRef(false)

  const click = useClick(context, { event: 'mousedown' })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'select' })

  const listNav = useListNavigation(context, {
    listRef,
    activeIndex: hoveredIndex,
    selectedIndex,
    onNavigate: (index) => {
      if (index === null) return
      setHoveredIndex(index)
    },
    loop: false,
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav, click]
  )

  const handleSelect = React.useCallback(
    (value: T) => {
      onChange?.(value)
      setIsOpen(false)
      setHoveredIndex(null)
    },
    [onChange]
  )

  const dataTestId = testId || (name ? `${name}-select` : undefined)

  const { t } = useTranslation()

  const hasScrolledRef = React.useRef(false)
  React.useEffect(() => {
    if (
      isOpen &&
      initialScrollIndexOverride !== undefined &&
      !hasScrolledRef.current
    ) {
      hasScrolledRef.current = true
      requestAnimationFrame(() => {
        listRef.current[initialScrollIndexOverride]?.scrollIntoView({
          block: 'center',
        })
      })
    }

    if (!isOpen) {
      hasScrolledRef.current = false
    }
  }, [isOpen, initialScrollIndexOverride])

  return (
    <div
      className={classNames('flex flex-col items-start gap-1', className, {
        'flex-1': fullWidth,
      })}
    >
      {labelComponent === undefined && (
        <FormLabel
          label={label}
          name={name}
          labelClass={labelClass}
          required={required}
        />
      )}
      {labelComponent}
      {name && (
        <select
          className="hidden"
          {...rest}
          ref={fwdRef}
          name={name}
          value={value}
          onChange={() => {}}
        />
      )}
      <div
        data-testid={dataTestId}
        tabIndex={0}
        ref={refs.setReference}
        aria-autocomplete="none"
        className={classNames(
          inputClassName,
          'flex h-[3.25rem] w-full select-none flex-row items-center justify-between rounded-2xl px-4 py-1 text-label-medium',
          {
            'bg-core-secondary': kind === 'secondary' && !disabled && !loading,
            'bg-core-tertiary': kind === 'tertiary' && !disabled && !loading,
            'bg-surface-dim': disabled || loading,
            'text-on-surface-disabled': disabled || loading,
            'border border-core-error': !!error,
            'cursor-not-allowed': disabled || loading,
            'cursor-pointer': !disabled && !loading,
            'is-disabled': disabled || loading,
          }
        )}
        {...getReferenceProps()}
        // aria-label={ariaLabel || label}
        role="group"
        aria-label={`${ariaLabel || label} ${t('components.dropdown_cta')}`}
      >
        {icon !== undefined && <div className="mr-2">{icon}</div>}
        <div className="w-full overflow-hidden truncate">
          {loading ? (
            <div className="flex items-center">
              <div className="h-4 w-4 animate-spin rounded-full border-2 border-core-primary border-t-transparent" />
            </div>
          ) : selectedLabel ? (
            selectedLabel
          ) : (
            <span className="text-on-surface-disabled">
              {placeholder || t('components.default_select_placeholder')}
            </span>
          )}
        </div>
        {allowClear && value && !disabled && (
          <div className="relative px-2">
            <button
              onMouseDown={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onChange?.('' as T)
              }}
              className="absolute -translate-x-[50%] -translate-y-[50%] p-2 hover:text-core-error"
            >
              <TrashCanIcon size={14} />
            </button>
          </div>
        )}
        {showChevron && (!value || !allowClear) && (
          <span className={classNames(isOpen ? 'rotate-90' : 'rotate-270')}>
            <ChevronLeft size={14} />
          </span>
        )}
      </div>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={(node) => {
                refs.setFloating(node)
                floatingRef.current = node
              }}
              className={classNames(
                'z-[9999] min-w-24 space-y-1 overflow-y-auto overscroll-contain rounded-2xl p-4 text-label-medium shadow-xl',
                {
                  'bg-core-secondary': kind === 'secondary',
                  'bg-core-tertiary': kind === 'tertiary',
                }
              )}
              style={{
                ...floatingStyles,
              }}
              {...getFloatingProps()}
              aria-label={t('components.listbox_cta')}
            >
              {options.length > 0 ? (
                options.map((option, i) => {
                  const hovered = i === hoveredIndex
                  const selected = i === selectedIndex

                  if (option.isUnchoosable) return null

                  return (
                    <div
                      key={
                        option.key !== undefined
                          ? option.key
                          : (option.value as string | number)
                      }
                      ref={(node) => {
                        listRef.current[i] = node
                      }}
                      role="option"
                      tabIndex={hovered ? 0 : -1}
                      aria-selected={hovered}
                      className="cursor-pointer"
                      {...getItemProps({
                        // Handle pointer select.
                        onClick() {
                          handleSelect?.(option.value)
                        },
                        // Handle keyboard select.
                        onKeyDown(event) {
                          if (event.key === 'Enter') {
                            event.preventDefault()
                            handleSelect?.(option.value)
                          }

                          if (event.key === ' ' && !isTypingRef.current) {
                            event.preventDefault()
                            handleSelect?.(option.value)
                          }
                        },
                      })}
                    >
                      <div
                        data-testid={dataTestId + `-option-${option.value}`}
                        className={classNames(
                          'cursor-pointer rounded-lg border-transparent p-4 py-2 outline-none',
                          {
                            'bg-surface-dim': selected,
                            'bg-core-secondary':
                              (!kind || kind === 'tertiary') &&
                              hovered &&
                              !selected,
                            'bg-core-tertiary':
                              kind === 'secondary' && hovered && !selected,
                          }
                        )}
                      >
                        {option.label}
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="text-on-surface-disabled">{emptyMessage}</div>
              )}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
      <FormError error={error} errorClass={errorClass} />
    </div>
  )
})
