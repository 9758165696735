import { useRepository } from 'hooks/auth'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const RelativeTime = observer(function RelativeTime({
  timestamp,
}: {
  timestamp: number
}) {
  const repo = useRepository()
  const datetime = DateTime.fromMillis(timestamp)
  // use currentMinute so that the time changes every minute
  const relativeTime = repo.currentMinute.diff(datetime, [
    'minutes',
    'seconds',
    'hours',
    'days',
  ])

  const { t } = useTranslation()
  let label: string = JSON.stringify(relativeTime.toObject())
  // Over 10 days, show the full date
  if (relativeTime.days >= 10) {
    label = datetime.toFormat('LLL dd')
  } else if (relativeTime.days >= 1) {
    label = t('notifications.relative_time.days', { count: relativeTime.days })
  } else if (relativeTime.hours >= 1) {
    label = t('notifications.relative_time.hours', {
      count: relativeTime.hours,
    })
  } else if (relativeTime.minutes >= 1) {
    label = t('notifications.relative_time.minutes', {
      count: relativeTime.minutes,
    })
  } else if (relativeTime.minutes === 0) {
    label = t('notifications.relative_time.just_now')
  }

  return (
    <strong className="text-body-medium text-on-surface-var">{label}</strong>
  )
})
