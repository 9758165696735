import type { NotificationGroup } from '@breakoutlearning/firebase-repository/types'
import { UserProfileNotificationType } from '@breakoutlearning/firebase-repository/types'
import {
  BreakoutUserAvatar,
  BreakoutUserAvatarStack,
} from 'components/breakout/BreakoutUserAvatar'
import { useRepository } from 'hooks/auth'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { Trans, useTranslation } from 'react-i18next'
import { RelativeTime } from './shared'
import { CheckBoxIcon } from 'components/icons/CheckBox'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import classNames from 'classnames'
import { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'

export const GroupChatNotification = observer(function GroupChatNotification({
  notificationGroup,
  onDismiss,
}: {
  notificationGroup: NotificationGroup
  onDismiss: () => void
  isLast: boolean
}) {
  const { t } = useTranslation()
  const repository = useRepository()
  const rootStore = useRootStore()
  const breakoutUser = useBreakoutUser()
  const type = notificationGroup.type
  if (type !== UserProfileNotificationType.GroupChat) return null

  const notificationsCubit = breakoutUser.notifications
  const sections = notificationsCubit.sections
  const slideDecks = notificationsCubit.slideDecks

  const userId = notificationGroup.userId
  const user =
    userId === 'system'
      ? PublicUser.empty(repository)
      : repository.userStore.getUser(userId)

  const userIds = notificationGroup.userIds
    .filter((id) => id && id !== 'system')
    .slice(0, 2)
  const users = repository.userStore.getUsers(userIds)

  const context = notificationGroup.context
  const sectionId = context.sectionId
  const assignmentId = context.assignmentId
  const roomStateId = context.roomId
  const slideDeckId = context.slideDeckId

  const section = sections.get(sectionId)
  const slideDeck = slideDecks.get(slideDeckId)

  let onClick = undefined

  if (sectionId && assignmentId && roomStateId) {
    onClick = () => {
      notificationsCubit.markGroupAsRead(notificationGroup)
      const route = roomStateId ? 'assignmentWithRoom' : 'assignment'
      onDismiss()
      rootStore.navigateTo(
        route,
        {
          assignmentId: assignmentId,
          sectionId: sectionId,
          roomStateId: roomStateId,
        },
        {
          action: 'chat',
        }
      )
    }
  }

  const messageCount = notificationGroup.notifications.length

  return (
    <div
      className={classNames(
        'group relative border-b border-b-outline-variant pr-5 hover:bg-surface'
      )}
    >
      <div className="my-4 flex flex-row items-start justify-between gap-1">
        <div
          className="flex flex-1 cursor-pointer flex-row items-start justify-between gap-2 pl-7"
          onClick={onClick}
        >
          <div className="flex-0">
            {users.length > 1 ? (
              <BreakoutUserAvatarStack users={users} radius={10} spacing={-3} />
            ) : userId === 'system' ? (
              <div className="flex h-[30px] w-[30px] flex-row items-center justify-center rounded-full bg-fixed-accent-color">
                <img
                  src="/assets/images/logo.png"
                  tabIndex={0}
                  width={20}
                  className="inline"
                  alt={t('breakout_learning')}
                />
              </div>
            ) : (
              <BreakoutUserAvatar user={user} radius={15} />
            )}
          </div>
          <div className="flex-1 text-body-large">
            <span className="whitespace-nowrap">
              {messageCount < 10 ? (
                <Trans
                  i18nKey={'notifications.group_chat.label'}
                  values={{ count: messageCount }}
                  components={{ b: <strong /> }}
                />
              ) : (
                <Trans
                  i18nKey={'notifications.group_chat.label_string'}
                  values={{ number: generatePlusLabel(messageCount) }}
                  components={{ b: <strong /> }}
                />
              )}
            </span>
            {/* You have {messageCount} unread chat in */}
            <div className="mt-2 flex flex-row items-center gap-1">
              {slideDeck?.data.slideDeckImageURL && (
                <img
                  src={slideDeck.data.slideDeckImageURL}
                  alt={slideDeck.data.slideDeckName}
                  className="mr-1 h-3 w-3"
                />
              )}
              <div className="flex-1">
                {slideDeck && (
                  <strong className="text-body-medium">
                    {slideDeck.data.slideDeckName}
                  </strong>
                )}{' '}
                {slideDeck && section && <span>•</span>}{' '}
                {section && (
                  <strong className="text-body-medium">
                    {section.data.className}
                  </strong>
                )}
              </div>
            </div>
          </div>
          <div className="relative h-full min-w-8 whitespace-nowrap text-right text-body-medium text-on-surface-var">
            <div className="invisible absolute inset-0 -top-1 right-0 flex h-full min-h-8 w-full items-start justify-end bg-surface group-hover:visible">
              <BreakoutTooltip content={'Mark as read'}>
                <div
                  className="group/dismiss -mr-2 -mt-2 cursor-pointer stroke-surface p-2"
                  onClick={(e) => {
                    e.stopPropagation()
                    notificationsCubit.markGroupAsRead(notificationGroup)
                  }}
                >
                  <CheckBoxIcon
                    size={20}
                    className="stroke-on-surface-disabled group-hover/dismiss:stroke-on-surface"
                  />
                </div>
              </BreakoutTooltip>
            </div>

            <RelativeTime timestamp={notificationGroup.timestamp} />
          </div>
        </div>
      </div>
    </div>
  )
})

// Rules are:
// 1-9: 1-9
// 10-19: 10+
// 20-29: 20+
// 30-39: 30+
// etc.
// >99: 100+
// B:
// 1-10: 1-10
// 11-20: 10+
// 21-30: 20+
// 31-40: 30+
// etc.
// >99: 100+
function generatePlusLabel(number: number) {
  if (number < 10) return number.toString()
  if (number > 99) return '100+'

  const tens = Math.floor(number / 10)

  return `${tens}0+`
}
