import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Contents, MainPane, Header, Headline } from 'pages/layout/TwoColumn'
import { useBreakoutUser } from 'hooks/profile'
import { getShortAppCommitSha } from 'util/commitSha'
import { ProfileNavigation } from './ProfileNavigation'
import { useEffect, useState } from 'react'
import { ProfileInfoSection } from './ProfileInfoSection'
import { Spinner } from 'components/Spinner'
import { ProfileTransactions } from './ProfileTransactions'
import { ProfilePromotions } from './ProfilePromotions'
import { Person } from 'components/icons/Person'
import { UserProfileRole } from '@breakoutlearning/firebase-repository/types'
import { PodcastsComponent } from 'pages/student/podcasts/PodcastsPage'
import { ProfilePages } from '../../types/ProfilePages'
import { ProfileSettings } from './ProfileSettings'
import { ProfileAvSection } from './ProfileAvSection'
import { useEmailVerificationHandler } from './useEmailVerificationHandler'
import { useRootStore } from 'hooks/rootStore'
import { Button } from 'components/Button'
import { ProfileViewFeatureFlags } from 'pages/profile/ProfileViewFeatureFlags'
import { AdminOnlyRing } from 'components/AdminOnlyRing'

export const ProfilePage = observer(function ProfilePage() {
  const [selectedPage, setSelectedPage] = useState<ProfilePages>(
    ProfilePages.details
  )
  const breakoutUser = useBreakoutUser()
  const { t } = useTranslation()
  useEmailVerificationHandler()

  const commitSha = getShortAppCommitSha()
  const rootStore = useRootStore()

  let roleText = ''
  switch (breakoutUser.role) {
    case UserProfileRole.admin:
      roleText = t('profile.role_admin')
      break
    case UserProfileRole.instructor:
      roleText = t('profile.role_instructor')
      break
    case UserProfileRole.student:
      roleText = t('profile.role_student')
      break
    case UserProfileRole.ta:
      roleText = t('profile.role_assistant')
      break
    default:
      break
  }

  useEffect(() => {
    const params = rootStore.router.queryParams
    if (!params) return
    if (params.tab) setSelectedPage(params.tab as ProfilePages)
  }, [rootStore.router.queryParams])

  return (
    <MainPane>
      <Header>
        <Headline>{t('profile.headline')}</Headline>
        <div className="flex items-center">
          <AdminOnlyRing
            onlyShowIf={breakoutUser.isAdmin}
            label="Admin only"
            ringClassName="mr-4"
          >
            <p className="text-title-small text-on-surface-var">
              {t('profile.version')}:{' '}
              <span
                className="cursor-pointer underline"
                onClick={() => {
                  window.open(
                    `https://github.com/breakoutlearning/cases/commit/${commitSha}`,
                    '_blank'
                  )
                }}
              >
                {commitSha}
              </span>
            </p>
          </AdminOnlyRing>
          <div className="ml-4 flex h-[52px] items-center rounded-2xl bg-core-secondary px-4">
            <Person size={18} className="mr-1 stroke-fixed-accent-color" />
            <strong className="text-label-medium text-core-on-secondary">
              {roleText}
            </strong>
          </div>
        </div>
      </Header>
      <Contents className="h-full overflow-hidden p-1">
        <div className="block h-full min-h-full w-full gap-5 md:flex">
          <ProfileNavigation
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
          <div className="h-full w-full overflow-y-auto rounded-2xl border border-outline-variant bg-core-tertiary p-5 md:p-10">
            {breakoutUser.isLoading && (
              <div className="flex h-full w-full items-center justify-center">
                <Spinner size={3} />
              </div>
            )}

            {!breakoutUser.isLoading && (
              <>
                {selectedPage === ProfilePages.details && (
                  <ProfileInfoSection />
                )}
                {selectedPage === ProfilePages.transactions && (
                  <ProfileTransactions />
                )}
                {selectedPage === ProfilePages.avTest && <ProfileAvSection />}
                {selectedPage === ProfilePages.podcast && (
                  <div>
                    <PodcastsComponent
                      hideImage={true}
                      titleOverride={t('profile.podcasts')}
                      hideBackBtn={true}
                      contentClassName="md:!ml-0 md:!mt-0"
                      titleClassName="!mt-0 !mb-0 !text-headline-large"
                      subTextClassName="text-body-large text-core-on-tertiary mb-6 mt-1"
                    />
                    {rootStore.router.queryParams?.returnTo && (
                      <div className="mt-4 text-right">
                        <Button
                          onClick={() => {
                            const returnTo =
                              rootStore.router.queryParams?.returnTo
                            if (returnTo && typeof returnTo === 'string') {
                              window.location.href = returnTo
                            } else {
                              rootStore.navigateTo('home')
                            }
                          }}
                          className="mr-4 bg-core-primary text-core-on-primary"
                        >
                          Back
                        </Button>
                      </div>
                    )}
                  </div>
                )}

                {selectedPage === ProfilePages.promotions && (
                  <ProfilePromotions />
                )}
                {selectedPage === ProfilePages.settings && <ProfileSettings />}
                {selectedPage === ProfilePages.viewFeatureFlags && (
                  <ProfileViewFeatureFlags />
                )}
              </>
            )}
          </div>
        </div>
      </Contents>
    </MainPane>
  )
})
