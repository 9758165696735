import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { useMemo, useState, type ComponentProps } from 'react'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { DatePickerDialog } from 'components/design-system/BreakoutDateTimeInput'
import { getRoomStateScheduledAtSchema } from '../roomStateScheduledAtSchema'
import { DateTime } from 'luxon'
import { useDialogs } from 'hooks/dialogs'
import { useTranslationTyped } from 'i18n/i18n'
import { getScheduleRoomStateDatePickerDialogProps } from './ScheduleRoomStateDialogUtil'

export const ScheduleRoomStateButton = ({
  cubit,
  useInlineDialog,
  children,
  className,
  ...rest
}: Omit<ComponentProps<typeof BreakoutButton>, 'onClick' | 'loading'> & {
  useInlineDialog: boolean
  cubit: StudentAssignmentCubit
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { roomState } = cubit
  const { tt, t } = useTranslationTyped()
  const { showDialog, popDialog } = useDialogs()

  const minScheduleDate = DateTime.now().startOf('minute') // that allows you to use "now" when starting a meeting
  const maxScheduleDate = cubit.assignment.expiresAtDate

  const schema = useMemo(
    () =>
      getRoomStateScheduledAtSchema({
        t,
        expiresAt: maxScheduleDate,
      }),
    [maxScheduleDate, t]
  )

  const datePickerProps = getScheduleRoomStateDatePickerDialogProps({
    useInlineDialog,
    scheduledAtDate: roomState.scheduledAtDate,
    roomStateName: roomState.roomStateName,
    isOpen,
    minDate: minScheduleDate,
    maxDate: maxScheduleDate,
    cubit,
    schema,
    tt,
    popDialog,
    setIsOpen,
    setIsLoading,
  })

  return (
    <div className={className}>
      <BreakoutButton
        loading={isLoading}
        onClick={() => {
          if (useInlineDialog) return setIsOpen(true)
          showDialog(() => <DatePickerDialog {...datePickerProps} />)
        }}
        {...rest}
      >
        {children}
      </BreakoutButton>
      {useInlineDialog && <DatePickerDialog {...datePickerProps} />}
    </div>
  )
}
