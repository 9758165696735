import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { Button } from 'components/Button'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { Money } from 'components/icons/Money'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'
import { Spinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'

export const PurchaseDialogBody = observer(function PurchaseDialogBody({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const repo = useRepository()
  const { availableTokens } = useBreakoutUser()
  const { usesSectionPass } = cubit.section

  const tokensRequired = cubit.slideDeck.data.slideDeckPrice
  const totalPrice = (
    Math.max((tokensRequired - availableTokens) * 10, 0) / 100
  ).toFixed(2)
  const { popDialog } = useDialogs()
  const { t } = useTranslation()
  const [waitingForStripeURL, setWaitingForStripeURL] = useState(false)

  useEffect(() => {
    repo.logEvent('purchase_dialog_opened')
  }, [repo])

  if (waitingForStripeURL) {
    return (
      <div className="flex flex-grow items-center justify-center">
        <Spinner />
      </div>
    )
  }

  // todo: designs for section pass purchase
  if (usesSectionPass) {
    return (
      <>
        <DialogCloseButton />
        <h1 className="mb-4px text-center text-headline-large">
          Purchase Section Pass
        </h1>
        <p className="mb-1 text-center text-body-medium">
          Purchasing a section pass unlocks access to this assignment as well as
          all future assignments in the section.
        </p>
        <strong className="mb-8px mx-auto table text-display-medium">
          ${cubit.libraryObject.sectionPassPriceDollars}
        </strong>
        <PurchaseButton
          onPurchase={async () => {
            setWaitingForStripeURL(true)
            try {
              repo.logEvent('purchase_section_pass_button_clicked')
              await cubit.createPayment(1, cubit.section.id, popDialog)
            } catch (e) {
              repo.logEvent('purchase_section_pass_button_failed')
              toast.error(t('student_assignment.pay_with_stripe_failure'))
              // rethrow to bubble to global error handler (sentry)
              popDialog()
              throw e
            }
          }}
        />
      </>
    )
  }

  return (
    <>
      <DialogCloseButton />
      <h1 className="mb-4px text-center text-headline-large">
        {t('student_assignment.purchase_tokens')}
      </h1>
      <p className="mb-1 text-center text-body-medium">
        {t('student_assignment.purchase_tokens_description')}
      </p>
      <div className="mb-1 w-[8rem] text-body-medium">
        <div className="flex flex-row justify-between">
          <span>{t('student_assignment.tokens_required_colon')}</span>
          <span className="ml-auto">{tokensRequired}</span>
        </div>
      </div>
      <div className="mb-3 w-[8rem] text-body-medium">
        <div className="flex flex-row justify-between">
          <span>{t('student_assignment.tokens_available_colon')}</span>
          <span className="text-right">{availableTokens}</span>
        </div>
      </div>
      <div className="bg-lightGray mb-5 flex h-[200px] w-[200px] flex-col items-center justify-center rounded-[20px] p-[20px] shadow-2xl">
        <strong className="mb-8px mx-auto table text-body-medium">
          {t('student_assignment.tokens_to_purchase')}
        </strong>
        <strong className="mb-8px mx-auto table text-display-medium">
          {Math.max(tokensRequired - availableTokens, 0)}
        </strong>
        <strong className="mb-8px mx-auto table text-headline-medium">
          {t('student_assignment.tokens')}
        </strong>
        <strong className="mb-8px mx-auto table text-label-medium">
          {t('student_assignment.total_price_colon')} ${totalPrice}
        </strong>
      </div>
      <PurchaseButton
        onPurchase={async () => {
          setWaitingForStripeURL(true)
          repo.logEvent('purchase_tokens_button_clicked')
          try {
            await cubit.createPayment(
              tokensRequired - availableTokens,
              undefined,
              popDialog
            )
          } catch (e) {
            repo.logEvent('purchase_tokens_button_failed')
            toast.error(t('student_assignment.pay_with_stripe_failure'))
            // rethrow to bubble to global error handler (sentry)
            popDialog()
            throw e
          }
        }}
      />
    </>
  )
})

const PurchaseButton = function ({ onPurchase }: { onPurchase: () => void }) {
  const { t } = useTranslation()
  return (
    <Button
      defaultWidth={false}
      onClick={onPurchase}
      overrideClassName="text-label-large bg-core-primary text-core-on-primary h-[54px] w-[200px] rounded-2xl font-semibold"
    >
      <Money
        size={14}
        className="mr-1 inline-block font-bold text-core-on-primary"
      />
      {t('student_assignment.pay_with_stripe')}
    </Button>
  )
}
