import type { ButtonKind } from 'components/design-system/BreakoutButton'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Dialog, InlineDialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useDialogs } from 'hooks/dialogs'
import { useTranslationTyped } from 'i18n/i18n'
import { useState } from 'react'

export const ConfirmationDialog = ({
  text,
  textClassName,
  subtitle,
  buttonText,
  buttonKind = 'error',
  inline = false,
  open,
  topIcon,
  btnTestId,
  onConfirm,
  dismiss,
  secondaryButtonText,
  secondaryButtonKind = 'secondary',
  overrideSecondaryClick,
}: {
  text: string
  textClassName?: string
  subtitle?: string | React.ReactNode
  buttonText?: string
  buttonKind?: ButtonKind
  inline?: boolean
  open?: boolean
  topIcon?: React.ReactNode
  btnTestId?: string
  onConfirm: () => Promise<void>
  dismiss?: () => void
  secondaryButtonText?: string
  secondaryButtonKind?: ButtonKind
  overrideSecondaryClick?: () => Promise<void>
}) => {
  const [confirming, setConfirming] = useState(false)
  const [secondaryLoading, setSecondaryLoading] = useState(false)
  const { popDialog } = useDialogs()
  const { tt } = useTranslationTyped()
  const DialogToUse = inline ? InlineDialog : Dialog
  const dismissDialog = dismiss ?? popDialog

  return (
    <DialogToUse
      size="xs"
      innerClassName="flex"
      open={open}
      onDismiss={dismiss}
      ignoreBackdropClick={confirming}
    >
      <DialogCloseButton
        onClick={(e) => {
          e.preventDefault()
          dismissDialog()
          return false
        }}
      />
      <div className="flex w-full flex-col justify-between text-center">
        <div className="flex flex-grow flex-col justify-center gap-3">
          {topIcon && <div className="mx-auto">{topIcon}</div>}
          <h3 className={textClassName ?? 'text-headline-medium'}>{text}</h3>
          {subtitle && typeof subtitle === 'string' ? (
            <p className="whitespace-pre-line text-body-medium">{subtitle}</p>
          ) : (
            subtitle
          )}
        </div>
        <div className="flex gap-3">
          {secondaryButtonText && (
            <BreakoutButton
              disabled={confirming || secondaryLoading}
              loading={secondaryLoading}
              size="large"
              kind={secondaryButtonKind}
              className="!min-w-0 basis-full"
              onClick={async () => {
                if (overrideSecondaryClick) {
                  setSecondaryLoading(true)
                  try {
                    await overrideSecondaryClick()
                  } finally {
                    setSecondaryLoading(false)
                    dismissDialog()
                  }
                } else {
                  dismissDialog()
                }
              }}
            >
              {secondaryButtonText}
            </BreakoutButton>
          )}
          <BreakoutButton
            disabled={confirming || secondaryLoading}
            loading={confirming}
            size="large"
            kind={buttonKind}
            className="!min-w-0 basis-full"
            data-testid={btnTestId}
            onClick={() => {
              setConfirming(true)
              onConfirm().finally(() => {
                setConfirming(false)
                dismissDialog()
              })
            }}
          >
            {buttonText || tt.admin.confirm()}
          </BreakoutButton>
        </div>
      </div>
    </DialogToUse>
  )
}
